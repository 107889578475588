import axiosInstance from "./axiosInstance";

import userModule from "../user";
import newApplicationModule from '../newApplication'
import field from '../field'
import leasingApplication from '../leasingApplication'
import administrationApplication from '../administration'
import scoring from '../scoring'



export default {
    user  : userModule(axiosInstance),
    fields: newApplicationModule(axiosInstance),
    field : field(axiosInstance),
    leasingApplication : leasingApplication(axiosInstance),
    administration : administrationApplication(axiosInstance),
    scoring : scoring(axiosInstance)

}
