<template>
  <div class="affiliation">
    <h2>Принадлежность </h2>
    <el-radio-group v-model="selectedPartner" style="margin-bottom: 30px;">

      <el-radio-button v-for="partner in data" :key="partner.id" :label="partner.id">{{ partner.name }}
      </el-radio-button>
    </el-radio-group>
    <hr>

    <div class="wrapper">
          <el-tabs :tab-position="tabPosition" >

      <el-tab-pane v-for="(tradingPost,index) in computedTradingPost" :label="tradingPost.name" :key="index">
        <div class="employer" v-for="(employer, index) in tradingPost['users']" :key="index">
          <div><span>{{index+1}}.</span> {{employer['first_name']}} {{employer['last_name']}} {{employer['patronym']}}</div>
          <div>{{employer['role']['description'].replace('Торговая точка -','')}}</div>

        </div>
        <div class="employer no-employer" v-if="!tradingPost['users'].length" > Сотрудников нет</div>
      </el-tab-pane>

    </el-tabs>
    </div>

  </div>


</template>

<script>
export default {
  name: "affiliation",
  data() {
    return {
      data: [],
      selectedPartner: null,
      tabPosition: 'left'
    }
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.administration.getAffiliationPartner().then((data) => {
        this.data = data
      })
    }
  },
  computed: {
    computedTradingPost() {
      if (this.selectedPartner) {
        let currentPartner = this.data.filter((item) => item.id === this.selectedPartner)
        return currentPartner[0]['trading_posts']
      }

    }
  }
}

</script>

<style lang="scss" scoped>
.affiliation{
  max-width: 1103px;
  .wrapper{
    margin-top: 30px;
    .employer{
      display: flex;
      padding: 5px;
      font-size: 10px;
      font-weight: 500;
      justify-content: space-between;
      width: 100%;
      &:nth-child(even){
        background: #d5d5d5;
      }
      div{
        &:first-child{
          span{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
::v-deep {
  .el-radio-button__inner {
    margin: 5px;
    border-radius: 5px;
    font-size: 10px;
    padding: 7px;

  }
  .el-tabs{
    width: 100%;
  }
  .el-tabs__item.is-left{
     font-size: 10px;
    padding-left: 0;
    min-width: 350px;
  }
  .el-tabs__nav-wrap{
    height: 500px !important;
    overflow-y: auto;
  }
  .el-tab-pane{
    position: relative;
  }


}


</style>