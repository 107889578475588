<template>
  <div class="soato-block">
      <my-select-soato propsType="name"/>

       <my-select-soato propsType="code"/>
  </div>

</template>

<script>
import MySelectSoato from "@/components/UI/MySelectSoato2.vue";

export default {
  name: "soato-block",
  components: {MySelectSoato},
  props:{
  },
  data(){
    return{
      state: null,
    }
  },
  watch:{
  },
  computed:{


  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.soato-block{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;
}

</style>