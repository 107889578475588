<template>
  <div class="input group" :class="{warning: isWarning}">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p :class="{required: !!field.required}">
      <slot></slot>
    </p>
    <el-input
        :name="name"
        size="medium"
        @input="changeValue"
        :value="$store.state.newApplication[field.name]"
        v-mask="'+375 (##) ###-##-##'"
        :placeholder="'+375 (##) ###-##-##'"
        :disabled="isDisabled"
        masked="true"
        clearable
    />
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-input-phone",
  components: {EditField},
  directives: {mask},
  props:{
    name:{
      type:String
    },
    field:{}
  },
  data() {
    return {
      state: "",
      serviceNameMask: {
        mask: "A.##",
        tokens: {
          "#": {pattern: /[0-9]/},
          // A: {pattern: /[a-dA-D]/, transform: v => v.toLocaleUpperCase()}
        }
      },
      isWarning: {}
    };
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  watch:{
    state(){
      this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
    }
  },
  computed: {
    isDisabled() {
        return this.$store.getters['newApplication/is_disabled'];
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    }
  },
  methods: {
    setDefaultState(){
      if(!this.$store.state.newApplication[this.field.name]) {
        this.state = ''
      }else {
        this.state = this.$store.state.newApplication[this.field.name]
      }
    },
    changeValue(value){
      this.$store.commit(`newApplication/set_${this.field.name}`, value)
      this.isWarningObject()
    },
    isWarningObject() {
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !this.$store.getters[`newApplication/${this.field.name}`]
      )
    },
  }

};
</script>

<style lang="scss" scoped>
.input{
  max-width: 315px;
  margin-bottom: 15px;
  @media(max-width: 850px){
    max-width: unset;
  }
  p{
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    span{
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }
  ::v-deep{
    .el-input{
      input{
        font-size: 12px;
        height: 40px;
      }
    }
  }
  &.warning {
    ::v-deep {
      input{
        background: #fff2c5 !important;
      }
    }
  }
}
</style>
