<template>
  <div class="input group" :class="{warning: isWarning}">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p :class="{required: !!field.required}">
      <slot></slot>
    </p>
    <el-input
        :class="this.field.name==='leasing_subject_all_price'|| this.field.name==='first_payment'?'disabled':''"
        :name="field.name"
        placeholder="Введите данные"
        :value="$store.getters[`newApplication/${field.name}`]"
        @input="changeValue"
        :disabled="isDisabled"
    ></el-input>
  </div>

</template>

<script>
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-input-text",
  components: {EditField},
  props: {
    field: {},
  },
  data() {
    return {
      state: '',
      isWarning: {}
    }
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  watch: {},
  computed: {

    isDisabled() {
      if (
          (this.field.name === 'area_registration' || this.field.name==='district_registration' || this.field.name==='village_council_registration'
              || this.field.name==='locality_name_registration')
          && this.$store.getters['newApplication/soato_registration_types']) {
        return true
      }
      if (
          (this.field.name === 'area_residential' || this.field.name==='district_residential' || this.field.name==='village_council_residential'
              || this.field.name==='locality_name_residential')
          && this.$store.getters['newApplication/soato_residential_types']) {
        return true
      }

      return this.$store.getters['newApplication/is_disabled']

      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
      //     || this.field.name === 'leasing_subject_all_price'
      //     || this.field.name === 'first_payment'
    }
  },
  methods: {
    setDefaultState() {
      if (!this.$store.getters[`newApplication/${this.field.name}`]) {
        this.state = ''
      } else {
        this.state = this.$store.getters[`newApplication/${this.field.name}`]
      }
    },
    changeValue(value) {
      this.$store.commit(`newApplication/set_${this.field.name}`, value)
      this.isWarningObject()
    },
    isWarningObject() {
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !this.$store.getters[`newApplication/${this.field.name}`]
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
  display: inline-block;
}

.input {
  width: 100%;
  margin-bottom: 15px;

  @media(max-width: 850px) {
    max-width: unset;
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .disabled {
      pointer-events: none;
      background: inherit;

      input {
        background: #fff;

        &:disabled {
          font-weight: bold !important;
          color: #1A2122;
        }
      }
    }

    .el-input.is-disabled .el-input__inner {
      color: #000;
      font-weight: bold;
    }

    .el-input {
      input {
        background: #fff;
        font-size: 12px;
      }
    }

  }

  &.warning {
    ::v-deep {
      .el-input {
        input {
          background: #fff2c5 !important;
        }
      }
    }
  }
}

</style>