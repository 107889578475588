var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"page-menu"},[_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.chartFormation
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.chartFormation)}}},[_c('span'),_vm._v(" Формирование графика ")]),_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.customerAnalysis
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.customerAnalysis)}}},[_c('span'),_vm._v(" Анализ клиента ")]),_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.profileInformation
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.profileInformation)}}},[_c('span'),_vm._v(" Анкетные данные ")]),_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.documentPrinting
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.documentPrinting)}}},[_c('span'),_vm._v(" Печать документов ")]),(_vm.$store.getters.user.role.name === 'admin' && _vm.$store.getters["newApplication/leasing_application_id"])?_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.scoring
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.scoring)}}},[_c('span'),_vm._v(" Скоринг ")]):_vm._e(),(_vm.$store.getters.user.role.name === 'admin' && _vm.$store.getters["newApplication/leasing_application_id"])?_c('button',{class:_vm.propsPageState===_vm.$constants.navigations.newApplication.sub.applicationLogs
        ?'active'
        :'',on:{"click":function($event){return _vm.selectPageMenu(_vm.$constants.navigations.newApplication.sub.applicationLogs)}}},[_c('span'),_vm._v(" История ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }