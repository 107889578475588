<template>
  <aside class="page-menu">
    <button
        @click="selectPageMenu($constants.navigations.newApplication.sub.chartFormation)"
        :class="propsPageState===$constants.navigations.newApplication.sub.chartFormation
        ?'active'
        :''"
    >
      <span></span>
      Формирование графика
    </button>
    <button
        @click="selectPageMenu($constants.navigations.newApplication.sub.customerAnalysis)"
        :class="propsPageState===$constants.navigations.newApplication.sub.customerAnalysis
        ?'active'
        :''"
    >
      <span></span>
      Анализ клиента
    </button>
    <button
        @click="selectPageMenu($constants.navigations.newApplication.sub.profileInformation)"
        :class="propsPageState===$constants.navigations.newApplication.sub.profileInformation
        ?'active'
        :''"
    >
      <span></span>
      Анкетные данные
    </button>
    <button
        @click="selectPageMenu($constants.navigations.newApplication.sub.documentPrinting)"
        :class="propsPageState===$constants.navigations.newApplication.sub.documentPrinting
        ?'active'
        :''"
    >
      <span></span>
      Печать документов
    </button>
    <button
        v-if="$store.getters.user.role.name === 'admin' && $store.getters[`newApplication/leasing_application_id`]"
        @click="selectPageMenu($constants.navigations.newApplication.sub.scoring)"
        :class="propsPageState===$constants.navigations.newApplication.sub.scoring
        ?'active'
        :''"

    >
<!--      :disabled="!$store.getters['newApplication/leasing_application_id']" && $store.getters.user.role.name === 'admin'-->
      <span></span>
      Скоринг
    </button>
    <button
        v-if="$store.getters.user.role.name === 'admin' && $store.getters[`newApplication/leasing_application_id`]"
        @click="selectPageMenu($constants.navigations.newApplication.sub.applicationLogs)"
        :class="propsPageState===$constants.navigations.newApplication.sub.applicationLogs
        ?'active'
        :''"
    >
      <span></span>
      История
    </button>
  </aside>
</template>

<script>
export default {
  name: "menu-page",
  props: {
    propsPageState: {
      type: Number,
      required: true
    },
    propsChangeMenuState: {
      type: Function
    },
  },
  methods: {
    selectPageMenu(id) {
      this.propsChangeMenuState(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-menu {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0px #00000040;
  display: grid;
  overflow: hidden;

  button {
    cursor: pointer;
    padding: 20px 15px;
    border: none;
    background: #fff;
    border-bottom: solid 1px #bababa;
    transition: .3s;
    position: relative;
    span{
      background: #fff;
      width: 5%;
      height: 100%;
      position: absolute;
      left: 0;

      top: 0;
    }

    @media(max-width: 1024px) {
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    &.active {
      position: relative;
      span{
        background: #ef3123;
        width: 5%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      //background: #ef3123;
      //color: #fff;
    }
  }
}
</style>