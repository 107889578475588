<template>
<!--    <el-dialog-->
<!--        width="70%"-->
<!--        :visible.sync="open"-->
<!--    >-->
    <div :style="{display: show ? 'block' : 'none'}">
        <div
            class="chart-payment"
            style="width:210mm"
        >
            <div v-if="Object.keys(data).length !== 0">
                <div style="
                  display: grid;
                  grid-template-columns: 1fr 2fr 2fr 2fr;
                  padding-bottom: 5px;
                  margin-top: 5px;
                  text-align: center;
                  align-items: start;
                  border-bottom: 1px solid #ddd;
                  font-size: 12px;
                ">
                    <div style="font-size: 14px; font-weight: bold">№ платежа</div>
<!--                    <div>-->
<!--                        <div style="padding: 5px 0;">Дата</div>-->
<!--                        <div>платежа</div>-->
<!--                        <div>(не позднее)</div>-->
<!--                    </div>-->

                        <div style="font-size: 14px; font-weight: bold">Основной долг</div>

<!--                    <div>-->
<!--                        <div>В т.ч. НДС по ставке 20% от</div>-->
<!--                        <div>Контрактной стоимости</div>-->
<!--                        <div>предмета(ов) лизинга</div>-->
<!--                        <div>(BYN)</div>-->
<!--                    </div>-->

                        <div style="font-size: 14px; font-weight: bold">Вознаграждение</div>

<!--                    <div>-->
<!--                        <div>В т.ч. сумма НДС на вознаграждение Лизингодателя</div>-->
<!--                        <div>по ставке без НДС (BYN)</div>-->
<!--                    </div>-->

                        <div style="font-size: 14px; font-weight: bold">Итого платеж</div>
                </div>
                <div>
                    <div style="
                        display: grid;
                        grid-template-columns: 1fr 2fr 2fr 2fr;
                        text-align: center;
                        background: #f9f9f9;
                        box-sizing: border-box;
                    ">
                        <div style="border-right: 1px solid #ddd; padding: 5px 0 0 0;">Аванс</div>
<!--                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{ data.general.app_updated_at }}</div>-->
                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">
                            {{
                                data.general.first_payment
                                    ? (data.general.first_payment).toFixed(2)
                                    :(0).toFixed(2)
                            }}
                        </div>
<!--                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">-->
<!--                            {{-->
<!--                                data.general.full_contact_payment_first-->
<!--                                    ? (data.general.full_contact_payment_first).toFixed(2)-->
<!--                                    : (0).toFixed(2)-->
<!--                            }}-->
<!--                        </div>-->
                        <div style="border-right: 1px solid #ddd; padding: 5px 0;"></div>
                        <div style="border: none; padding: 5px 0;"></div>
                    </div>
                    <div
                        style="
                      display: grid;
                      grid-template-columns: 1fr 2fr 2fr 2fr;
                      text-align: center;
                "
                        :style="index%2 ? 'background: #f9f9f9' : ''"
                        v-for="(datum,index) in data.by_month"
                    >
                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{ index + 1 }}</div>
<!--                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{ datum.payment_date }}</div>-->
                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">
                            {{ (datum.principal_payment).toFixed(2) }}
                        </div>
<!--                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{-->
<!--                                (datum.contact_payment).toFixed(2)-->
<!--                            }}-->
<!--                        </div>-->
                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{
                                (datum.leasing_compensation).toFixed(2)
                            }}
                        </div>
<!--                        <div style="border-right: 1px solid #ddd; padding: 5px 0;">{{ (datum.vat_payment).toFixed(2) }}</div>-->
                        <div style="padding: 5px 0;">{{ (datum.leasing_payment_with_vat).toFixed(2) }}</div>
                    </div>
                </div>
                <div style="
                  display: grid;
                  grid-template-columns: 1fr 2fr 2fr 2fr;
                  text-align: center;
                  align-items: center;
                  border-top: 1px solid #ddd;
                  background: #ffacac;
                  font-weight: bold;
               "
                >
                    <div style="padding: 10px 0 10px 0;">ИТОГО:</div>
<!--                    <div style="padding: 15px 0 15px 0;"></div>-->
                    <div style="border-right: 1px solid #ddd; padding: 15px 0 15px 0;">
                        {{ (data.general.full_principal_payment).toFixed(2) }}
                    </div>
                    <div style="border-right: 1px solid #ddd; padding: 15px 0 15px 0;">
<!--                        {{ (data.general.full_contact_payment).toFixed(2) }}-->
                    </div>
                    <div style="border: none; padding: 15px 0 15px 0;">
<!--                        {{ (data.general.full_leasing_compensation).toFixed(2) }}-->
                    </div>

<!--                    <div style="padding: 15px 0 15px 0;"></div>-->
<!--                    <div style=" padding: 15px 0 15px 0;">-->
<!--&lt;!&ndash;                        {{ (data.general.full_leasing_payment_with_vat).toFixed(2) }}&ndash;&gt;-->
<!--                    </div>-->
                </div>
                <div style="margin-top: 10px; margin-bottom: 40px;">Размер выкупной стоимости составляет
                    {{ (data.general.full_ransom_payment).toFixed(2) }} BYN
                </div>
            </div>
        </div>
    </div>
<!--    </el-dialog>-->
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "chart-payment-by-month",
    props: {
        data: {},
        show: {
            Type: Boolean,
            default: false
        }
    },
    data() {
        return {
            leasing_application_id: null,
            updated_at: null,
        }
    },
    mounted() {
        this.leasing_application_id = this.$store.getters["newApplication/leasing_application_id"]
        // this.leasing_application_id = this.$store.getters["newApplication/leasing_application_id"]
        // if(this.leasing_application_id) {
        //     this.getPaymentSchedule()
        // }
    },
    // watch: {
    //     '$store.state.newApplication.updated_at': function() {
    //         if(this.$store.state.newApplication.updated_at !== this.updated_at) {
    //             this.updated_at = this.$store.state.newApplication.updated_at;
    //             this.getPaymentSchedule()
    //             this.open = true;
    //         }
    //     },
    // },


    methods: {
        ...mapActions({saveData: 'newApplication/saveData'}),
        openChart() {
            // this.getPaymentSchedule();
            // this.saveData(this.getPaymentSchedule);

        },
        // getPaymentSchedule() {
        //     this.chartLoading = true;
        //     this.chartDialogIsOpen = true;
        //     this.$api.leasingApplication.getPaymentSchedule({
        //         leasing_application_id: this.$store.getters["newApplication/leasing_application_id"]
        //     })
        //         .then(data => {
        //             this.data = data;
        //             console.log(data)
        //         })
        //         .finally(() => {
        //             this.chartLoading = false;
        //         })
        // },
        forPrint() {
            let resultDocument = this.$refs.data.innerHTML

            var doc = window.open('/template');

            doc.onload = () => {
                doc.document.write(resultDocument)
                doc.document.body.style.width = '210mm'
                doc.document.body.style.margin = '0 auto'
                doc.document.body.style.color = '#000 !important'
            }
            doc.focus();
        },

    }
}
</script>

<style scoped>

</style>