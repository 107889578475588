import requestSender from "./base/requestSender";

export default function(axiosInstance){
    const BASE_URI  = 'leasing-application/'

    return {
        getAll(payload) {
            return requestSender('get', BASE_URI + 'get-all', payload);
        },
        getAllStat(payload) {
            return requestSender('get', BASE_URI + 'get-all-stat', payload);
        },
        getAllOldClientApps(payload){
            return requestSender('get', BASE_URI + 'get-all-old-client-apps', payload);
        },
        getAllAppsDocuments(payload){
            return requestSender('get', BASE_URI + 'get-all-apps-documents', payload);
        },
        getDocumentStatuses(payload){
            return requestSender('get', BASE_URI + 'get-document-statuses', payload);
        },
        getDocumentsLogsByLeasingApplicationId(payload){
            return requestSender('get', BASE_URI + 'get-documents-logs-by-leasing-application-id', payload);
        },
        saveAppDocumentStatus(payload){
            return requestSender('POST', BASE_URI + 'save-app-document-status', payload);
        },
        getOne(payload){
            return requestSender('get', BASE_URI + 'get-one', payload);
        },
        save(payload){
            return requestSender('post', BASE_URI + 'save', payload);
        },
        setStatus(payload) {
            return requestSender('post', BASE_URI + 'set-status', payload);
        },
        forRevision(payload) {
            return requestSender('post', BASE_URI + 'for-revision', payload);
        },
        forAppApproved(payload) {
            return requestSender('post', BASE_URI + 'for-app-approved', payload);
        },
        getPaymentSchedule(payload, options) {
            return requestSender('post', BASE_URI + 'get-payment-schedule', payload, options);
        },
        renderExcel(payload, options) {
            return requestSender('post', BASE_URI + 'render-excel', payload, options);
        },
        renderAppsDocumentsExcel(payload, options) {
            return requestSender('post', BASE_URI + 'render-apps-documents-excel', payload, options);
        },
        getFreePaymentSchedule(payload, options) {
            return requestSender('post', BASE_URI + 'get-free-payment-schedule', payload, options);
        },
        uploadFiles(payload) {
            return requestSender('post', BASE_URI + 'upload-files', payload);
        },
        setFileToVerified(payload) {
            return requestSender('post', BASE_URI + 'set-file-to-verified', payload);
        },
        deleteFile(payload) {
            return requestSender('post', BASE_URI + 'delete-file', payload);
        },
        deleteLeasingSubject(payload) {
            return requestSender('post', BASE_URI + 'delete-leasing-subject', payload);
        },
        getStatuses(payload) {
            return requestSender('get', BASE_URI + 'get-statuses', payload);
        },
        getStatusesForAppsDocumentsStatuses(payload) {
            return requestSender('get', BASE_URI + 'get-statuses-for-apps-documents-statuses', payload);
        },
        getLeasingOffersByUser(payload) {
            return requestSender('get', BASE_URI + 'get-leasing-offers-by-user', payload);
        },





        sendDataForFirstStageSmsCode(payload, options) {
            return requestSender('get', BASE_URI + 'send-data-for-first-stage-sms-code', payload, options);
        },
        sendFirstStageSmsCodeToCheck(payload, options) {
            return requestSender('get', BASE_URI + 'send-first-stage-sms-code-to-check', payload, options);
        },
        sendSecondStageSmsCodeToCheck(payload, options) {
            return requestSender('get', BASE_URI + 'send-second-stage-sms-code-to-check', payload, options);
        },


        prepareDocumentsForClient(payload, options) {
            return requestSender('post', BASE_URI + 'prepare-documents-for-client', payload, options);
        },
        checkSmsCode(payload, options) {
            return requestSender('get', BASE_URI + 'check-sms-code', payload, options);
        },

        renderDocs(payload, options) {
            return requestSender('post', BASE_URI + 'render-docs', payload, options);
        },


        setAssentDate(payload) {
            return requestSender('post', BASE_URI + 'set-assent-date', payload);
        },
        setDelayedLoadingDate(payload) {
            return requestSender('post', BASE_URI + 'set-delayed-loading-date', payload);
        },

        getPhoneNumberMatches(payload){
            return requestSender('post', BASE_URI + 'get-previous-unvalid-apps', payload);
        },

    }
}