<template>
  <div>
    <el-skeleton animated v-if="variant==='analisys'">
      <template slot="template">
        <div class="analisys">
          <el-skeleton-item class="my-h3" variant="h1" />
          <div class="checkbox-block">
            <el-skeleton-item  variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <el-skeleton-item variant="text" class="my-checkbox"/>
            <div class="my-checkbox"></div>
          </div>
          <div class="inputs-block">
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>

            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>

            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="inputs-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
          </div>
          <div>
            <div class="btn-block">
              <div class="btn-block__wrapper">
                <el-skeleton-item class="my-button" variant="button" />
                <div>
                  <el-skeleton-item class="my-p" variant="text"/>
                  <el-skeleton-item class="my-button btn-accept" variant="button"/>
                </div>
              </div>
              <el-skeleton-item class="my-button next " variant="button"/>
            </div>
          </div>
        </div>
      </template>
    </el-skeleton>
    <el-skeleton animated v-if="variant==='profile'">
      <template slot="template">
        <div class="profile-block" >
          <el-skeleton-item class="my-h3" variant="h1" />
          <div class="address-block" >
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item second-address">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="address-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
          </div>
          <div class="btn-block">
            <el-skeleton-item class="my-button next " variant="button"/>
          </div>
        </div>
      </template>
    </el-skeleton >
    <el-skeleton animated v-if="variant==='chart'" >
      <template slot="template">
        <div class="chart">
          <div class="price-block">
            <el-skeleton-item variant="h3" class="my-input"/>
          </div>
          <div class="prepay-block">
            <div class="prepay-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="prepay-block__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
          </div>
          <div class="calculation-option one">
            <el-skeleton-item variant="h3" class="my-input"/>
          </div>
          <div class="client-info">
            <div class="client-info__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
            <div class="client-info__item">
              <el-skeleton-item variant="h3" class="my-input"/>
              <el-skeleton-item variant="h3" class="my-input"/>
            </div>
          </div>
          <div class="btn-block">
            <div>
              <el-skeleton-item class="my-button" variant="button" />
              <el-skeleton-item class="my-button" variant="button" />
            </div>
          </div>
        </div>
      </template>
    </el-skeleton >
  </div>
</template>


<script>
export default {
  name: "my-skeleton",
  props:{
    variant:{
      type:String
    }
  }
}
</script>

<style lang="scss" scoped>
.analisys {
  .my-h3 {
    max-width: 260px;
    margin-bottom: 20px;
    height: 50px;
  }

  .checkbox-block {
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    @media (max-width: 1370px) {
      grid-template-columns: 1fr;
    }
    .my-checkbox{
      margin-bottom: 5px;
      max-width: 700px;
      height: 19px;

    }

  }

  .inputs-block {
    margin-bottom: 40px;

    &__item {
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      @media(max-width: 1024px) {
        gap: 10px;
      }
      @media(max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
      .my-input{
        margin-bottom: 20px;
        height: 40px;
      }
    }
  }

  .btn-block {
    display: grid;

    button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
    &__wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 20px;
      @media(max-width: 850px) {
        display: block;
        width: 100%;
      }
      div {
        display: grid;
      }

      .my-button {
        min-width: 240px;
        @media(max-width: 850px) {
          width: 100% !important;
          margin-bottom: 20px;
        }

        &.btn-accept {

          @media(max-width: 850px) {
            width: 100%;
            order: -1;
            margin-bottom: 5px;
          }

        }
      }

      .my-p {
       margin-bottom: 10px;
        @media(max-width: 850px) {
          max-width: unset;
          width: 100%;

        }
      }
    }

    .next {
      margin-left: auto;
    }

  }
}
.profile-block {
  .my-h3 {
    max-width: 260px;
    margin-bottom: 20px;
    height: 50px;
  }

  .address-block {
    margin-bottom: 40px;

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media (max-width: 1230px) {
        gap: 10px;
      }
      @media (max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
      .my-input{
        margin-bottom: 20px;
        height: 40px;
      }
    }
  }

  .btn-block {
    display: flex;
    justify-content: flex-end;

    .my-button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

}
.chart{
  .price-block{
    margin-bottom: 15px;
    .my-input{
      height: 40px;
      max-width: 315px;
      @media(max-width:850px){
        max-width: unset !important;
      }
    }

  }
  .calculation-option.one{
    margin-bottom: 20px;
    .my-input{
      height: 40px;
      max-width: 315px;
      @media(max-width:850px){
        max-width: unset !important;
      }
    }

  }
  .prepay-block{
    margin-bottom: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    @media(max-width: 670px){
      grid-template-columns: 1fr;
      gap: 0;
    }
    .my-input{
      height: 40px;
    }

  }

  .client-info{
    margin-bottom: 15px;
    &__item{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media(max-width: 850px){
        gap: 0px;
        grid-template-columns: 1fr;
      }
      .my-input{
        height: 40px;
        margin-bottom: 10px;
      }
      &.one{
        .my-input{
          max-width: 315px;
          height: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .btn-block{
    div{
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      @media(max-width: 850px){
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}

</style>