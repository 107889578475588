<template>
  <div class="edit-for-role">
    <span>
    <i
        class="el-icon-edit-outline"
        @click="editFieldForm"
    >
    </i>
    </span>
    <el-dialog
        title="Редактирование элемента формы"
        :visible.sync="dialogVisible"
        width="80%"
    >
      <el-form :data="field">
        <el-form-item label="Редактирование названия">
          <el-input v-model="field.name_for_web"></el-input>
        </el-form-item>
        <el-form-item label="Обязательно к заполнению">
          <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="field.required"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Этап согласования" v-if="field.required">
          <el-select v-model="field.approval_step">
            <el-option
                v-for="i in 2"
                :key="i"
                :value="i"
                :label="i"
            ></el-option>

          </el-select>
        </el-form-item>
          <div v-if="field.type === 'select'">
              <el-form-item label="Множественный выбор">
                  <el-switch
                          active-value="1"
                          inactive-value="0"
                          v-model="field.multiple"
                  ></el-switch>
              </el-form-item>
          </div>
        <div v-if="field.type === 'checkbox' || field.type === 'select' || field.type === 'radio'">
          <div class="checkbox-items">
            <div class="checkbox-items__label">
              <div>
                Редактирование пунктов чекбокса(Изменить и нажать на пустое место формы)
              </div>
              <div class="add">
                <span @click="addNewFieldItemElement"><i class="el-icon-circle-plus-outline"></i>Добавить элемент</span>
              </div>

            </div>
            <div class="checkbox-items__item" v-for="(checkboxItem, index) in field.data">
                <div class="edit-table">
                  <div class="edit-table__header">
                    <div>
                      Наименование
                    </div>
                    <div>
                      Код
                    </div>
                    <div v-if="typeof field.data[index].document_type_attribute !== 'undefined'">
                      Признак вида док-та
                    </div>


                    <div></div>
                  </div>
                  <div class="edit-table__body">
                    <div>
                      <el-input v-model="field.data[index].name"></el-input>
                    </div>
                    <div>
                      <el-input v-model="field.data[index].code"></el-input>
                    </div>
                    <div v-if="typeof field.data[index].document_type_attribute !== 'undefined'">
                      <el-input v-model="field.data[index].document_type_attribute"></el-input>
                    </div>
                    <div class="buttons">
                      <el-button :type="field.data[index].id ? '' : 'primary'" @click="editFieldItem(field.data[index], index, field.id)">
                        {{ field.data[index].id ? 'Сохранить' : 'Добавить' }}
                      </el-button>
                      <el-button @click="deleteFieldItem(field.data[index])">Удалить</el-button>
                    </div>
                  </div>
                </div>
                <div>
                </div>
            </div>

          </div>


        </div>

        <div v-if="field.type === 'button'"></div>
        <div class="buttons">
          <el-button @click="dialogVisible = false">Отмена</el-button>
          <el-button type="primary" @click="editField">Сохранить</el-button>
        </div>

      </el-form>

    </el-dialog>
  </div>

</template>

<script>
import requestSender from "@/api/base/requestSender";

export default {
  name: "edit-field",
  props: {
    field: {}
  },
  data() {
    return {
      search: '',
      dialogVisible: false,
    }
  },
  mounted() {
  },
  methods: {
    editFieldForm() {
      this.dialogVisible = true;
    },
    editField() {

      this.$api.field.editFieldName({
        id: this.field.id,
        name: this.field.name_for_web,
        required: this.field.required,
        approval_step: this.field.approval_step
      })
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }
            this.dialogVisible = false;
          })


    },
    deleteFieldItem(fieldItem) {
      this.$api.field.deleteFieldData({
        field_id: this.field.id,
        data_id: fieldItem.id
      })
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });

              this.field.data = this.field.data.filter(item => item.id !== fieldItem.id)
            }
          })
    },

    editFieldItem(fieldItem, index, field_id) {
      if (!fieldItem.id) {
        this.saveNewFieldItem(fieldItem, index)
      } else {
        this.$api.field.editFieldData({
          field_id: field_id,
          data_id: fieldItem.id,
          data_name: fieldItem.name,
          data_code: fieldItem.code,
          document_type_attribute: fieldItem.document_type_attribute,
        })
            .then(data => {
              if (!data.errors) {
                this.$notify.success({
                  duration: this.$constants.global.notifyDurationError,
                  title: 'Успешно',
                  message: 'Данные сохранены',
                });
              }
            })
      }
    },
    addNewFieldItemElement(item) {
      this.field.data.push({
        id: null,
        name: '',
        default_selected: 0
      })
    },
    saveNewFieldItem(fieldItem, index) {
      this.$api.field.addFieldData({
        field_id: this.field.id,
        data_name: fieldItem.name
      })
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });

              this.field.data[index].id = data.field.id
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-for-role {
  position: absolute;
  right: -5px;
  cursor: pointer;

  ::v-deep .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }

  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 5px;
      display: flex;
      width: 100%;

      > label {
        text-align: left;
        width: 30%;
        font-weight: bold;
        color: #494949;
      }

      > div {
        width: 100%;
      }
    }

    .checkbox-items {
      margin-left: 40px;

      &__label {
        margin: 5px 0;
        color: #494949;
        font-size: 12px;
        font-weight: bold;
      }

      .el-input {
        margin-bottom: 5px;
      }

      //&__item {
      //  > div > div {
      //    display: grid;
      //    grid-template-columns: 3fr 1fr;
      //    gap: 20px;
      //    align-items: center;
      //     .el-form {
      //       &-item {
      //         width: 100%!important;
      //         margin: 0!important;
      //         > div {
      //           margin: 0!important;
      //           width: 100%!important;
      //         }
      //       }
      //    }
      //    .buttons {
      //      margin-top: -5px;
      //      .el-button {
      //        width: 100%;
      //        font-size: 12px;
      //        line-height: 1;
      //        height: 30px;
      //        margin-top: 5px;
      //        +.el-button  {
      //          margin-left: 0;
      //        }
      //      }
      //    }
      //  }
      //
      //
      //
      //}

      .add {
        span {
          display: inline-block;
          padding: 5px 10px;
          border: 1px solid #ef3123;
          color: #ef3123;
          margin: 5px 0;
          border-radius: 4px;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .2s linear;

          i {
            padding-right: 5px;
          }

          &:hover {
            background-color: #fac1bd;
          }
        }

      }
    }

    .edit-table {
      margin-bottom: 10px;
      padding: 5px;
      > div {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 2fr;
        column-gap: 20px;
        &:first-child {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .el-button {
        line-height: 1;
        font-size: 12.5px;
      }
    }
  }

}
</style>