<template>
  <div class="input group">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p>
      <slot></slot>
    </p>

    <div v-model="fullPrice"></div>
    <div class="inputs" v-for="(subject, index) in actualSubjects" :key="index">
      <div>
        <p>Предмет лизинга</p>
        <el-input
            maxlength="1000"
            :name="field.name"
            placeholder="Введите данные"
            v-model="subject.name"
            @change="changeValue"
            :disabled="isDisabled"
        ></el-input>

      </div>

      <div>
        <p>Стоимость, BYN</p>
<!--        <el-input-number-->
<!--            :name="field.name"-->
<!--            :precision="2"-->
<!--            :controls="false"-->
<!--            @change="changeValue"-->
<!--            v-model="subject.price"-->
<!--            :min="index === 0 ? 100 : 0"-->
<!--            :max="30000"-->
<!--            :disabled="isDisabled"-->
<!--        ></el-input-number>-->
        <input
            :name="field.name"
            class="newInputNumber"
            type="text"
            :value="subject.price?subject.price:'0.00'"
            :disabled="isDisabled"
            @change="(e)=>{
              subject.price=newChangeValue(e,index === 0 ? '100' : 0, maxPrice)
              let x  = newChangeValue(e,index === 0 ? '100' : 0, maxPrice)
              myAlert(x,subject.price,actualSubjects)

              changeValue()
            }"

        />
      </div>
      <div class="icons" v-if="showControlButtons">
        <el-button
            class="remove"
            icon="el-icon-remove"
            circle
            :disabled="$store.getters[`newApplication/${field.name}`].length < 2"
            @click="remove(subject, index)"
        />
        <el-button
            class="add"
            :class="{hide: fullPrice >= maxPrice || subjects.length > 9}"
            icon="el-icon-circle-plus"
            circle
            @click="add"
        />
      </div>
    </div>


  </div>
</template>

<script>
import EditField from "@/components/general/EditField.vue";
import store from "@/store";

export default {
  name: "leasing-subject-input-group",
  components: {EditField},
  props: {
    field: {},
    maxPrice: {
      Type: Number
    },
  },
  data() {
    return {
      testData:null,
      fullPrice: 0,
      subjects: [
        {
          id: '',
          name: '',
          price: 100
        }
      ]
    }
  },
  mounted() {
    this.setDefaultState()
    this.initFullPrice()
  },
  watch: {
    subjects() {
      this.$store.commit('newApplication/set_leasing_subjects', this.subjects)
    }

  },
  computed: {
    isDisabled() {
      if(this.$store.getters[`newApplication/leasing_application_status_id`] === 12 && this.$store.getters.user.delayed_loading === 1) {
        return false;
      }
      return this.$store.getters['newApplication/is_disabled']


      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    },
    actualSubjects() {
      this.subjects = this.$store.getters["newApplication/leasing_subjects"]
      return this.subjects;
    },
    showControlButtons() {
      return !this.$store.state.newApplication.leasing_application_status_id ||
          [
            this.$constants.statuses.appCreated,
            this.$constants.statuses.appForRevision
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) ||
          ([
            this.$constants.statuses.appApproved,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) && this.$store.getters.user.delayed_loading)
    }
  },

  methods: {
    myAlert(x,y,z){
      console.log(x)
      console.log(y)
      console.log(z)
    },
    add() {
      if (this.subjects.length > 9) {
        this.$notify.error({
          duration: 4500,
          title: 'Ошибка',
          message: 'Максимальное кол-во предметов лизнга - 10',
        });
        return;
      }
      this.subjects.push({
        id: '',
        name: '',
        price: 0
      })
      this.initFullPrice()
    },
    initFullPrice() {
      this.fullPrice = this.subjects.reduce((accumulator, value) => {
        return accumulator + +value.price;
      }, 0);

      if (this.fullPrice > this.maxPrice) {

        this.$notify.error({
          duration: 4500,
          title: 'Ошибка',
          message: 'Сумма предметов лизинга не должна превышать ' + this.maxPrice + ' BYN. Сумма предметов лизинга откоректирована. Проверьте Стоимость последнего предмета лизинга',
        });

        let tmp = this.subjects.filter((item, idx) => idx !== this.subjects.length - 1)
        this.fullPrice = tmp.reduce((accumulator, value) => {
          return accumulator + +value.price;
        }, 0);
        this.subjects[this.subjects.length - 1].price = this.maxPrice - this.fullPrice
        this.initFullPrice()
      }

      this.$store.commit('newApplication/set_leasing_subject_all_price', this.fullPrice)
    },
    remove(subject, index) {

      if (subject.id) {
        this.$api.leasingApplication.deleteLeasingSubject({id: subject.id})
            .then(data => {
              if (data.errors || !data) {
                this.$notify.error({
                  duration: 4500,
                  title: 'Ошибка',
                  message: 'Что-то пошло не так',
                });
                return;
              } else {
                this.subjects = this.subjects.filter((item, idx) => idx !== index)
                this.initFullPrice()
              }
            })
      } else {
        this.subjects = this.subjects.filter((item, idx) => idx !== index)
        this.initFullPrice()
      }


    },
    setDefaultState() {
      if (this.$store.getters[`newApplication/${this.field.name}`].length) {
        this.subjects = this.$store.getters[`newApplication/${this.field.name}`]
      }
    },
    changeValue(value) {
      this.initFullPrice()
    },
    // newChangeValue(event, min = 0, max = 99999999999999999) {
    newChangeValue(event, min = 0, max = this.maxPrice) {

      let value = event.target.value;
      value = value.replace(/[^0-9.,]/g, '');
      value = value.replace(/,/g, '.');
      const parts = value.split('.');
      if (parts.length > 1) {
        // Ограничиваем количество знаков после точки до 2
        parts[1] = parts[1].slice(0, 2);
        value = parts[0] + '.' + parts[1];
      }
        // Преобразуем строковое значение в число для проверки ограничений
      let numericValue = parseFloat(value);

        // Установка минимального и максимального значения
      const minValue = Number(min); // Ваше минимальное значение
      const maxValue = Number(max); // Ваше максимальное значение

      if (!isNaN(numericValue)) {
        if (numericValue < minValue) {
          numericValue = minValue;
        } else if (numericValue > maxValue) {
          numericValue = maxValue;
        }
        value = numericValue.toFixed(2); // Ограничиваем число до 2 знаков после запятой
      }
      event.target.value = value;
      return  value

    }
  }
}
</script>

<style lang="scss" scoped>
.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 150px 57px;
  gap: 30px;
  margin-bottom: 20px;
  >div{
    &:first-child{
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #ddd;
    color: #1A2122;
    font-weight: bold;
  }

  ::v-deep .el-input {
    border-radius: 8px;
    border: 0 solid #DCDFE6;
    background: #000;

    &-number {
      width: 100%;
    }

    &__inner {

    }
  }

  > div {
    p {
      color: #1A2122;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .add, .remove {
      cursor: pointer;
      display: inline-block;
      background: transparent;
      border: none;
      padding: 0;
      font-size: 24px;

      &:hover {
        opacity: .8;
        scale: 1.1;
      }
    }

    .add {
      display: none;
    }

    .remove {
      color: #000;

      &:disabled {
        color: #ddd;
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 30px;
    grid-column-start: 4;

  }

  &:last-child {
    > div {
      .add {
        color: #ef3123;
        display: inline-block;

        &.hide {
          display: none;
        }
      }

      .remove {
        //display: none;
      }
    }

  }

  &:first-child {
    > div {
      .remove {
        display: none;
      }
    }

  }
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
