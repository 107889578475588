<template>
  <div class="container" v-loading="loading">
    <!--   test   -->
    <!--   test   -->
    <div class="content">
      <div
          v-if="$store.state.user !== 'undefined' && $store.state.user.role.name === 'admin'"
          style="margin-bottom: 10px;"
      >
<!--        <my-button @click="renderExcel">Сформировать EXCEL</my-button>-->
      </div>
      <div class="header">
        <div class="search">
          <span v-loading="renderLoading" element-loading-spinner="el-icon-loading">
            <img
                v-if="$store.state.user !== 'undefined' &&
                      ($store.state.user.role.name === 'admin' || $store.state.user.role.name === 'head_of_rp' || $store.state.user.role.name === 'general_router')"
                @click="exportSetting = !exportSetting"
                src="../assets/img/icons/icon-excel.svg"
                alt=""
            >
          </span>
          <div @keyup.enter="getAll">
            <el-input
                placeholder="ФИО или номер заявки"
                v-model="search"
            >
                <my-button slot="append" icon="el-icon-search" @click="getAll">Поиск</my-button>
            </el-input>
          </div>

        </div>
        <div class="filter">
          <i
              @click="filterDrawer = !filterDrawer"
              class="el-icon-s-operation"
              :class="{active: isActiveFilter}"
          ></i>
        </div>
      </div>




      <el-table
          :data="apps"
          height="calc(91vh - 170px)"
          style="overflow: auto;"
          :class="['admin'].includes($store.state.user.role.name) ? 'admin' : 'client'"
          :row-class-name="tableRowClassName"
          @row-click="openApp"
          @row-contextmenu="openContextMenu"
      >
        <el-table-column
            prop="app_number"
            label="№"
            width="110"
        >
        </el-table-column>
        <el-table-column
            label="Предметы лизинга"
            width="250"
        >
          <template slot-scope="scope">
            {{ scope.row.leasing_subjects.length ? scope.row.leasing_subjects[0].name : 'Не определено' }}
          </template>

        </el-table-column>
        <el-table-column
            label="Итоговая стоимость"
            width="90"
        >
          <template slot-scope="scope">
                        <span style="font-weight: 600"> {{
                            scope.row.leasing_subjects.length ? getFullSubjectsPrice(scope.row.leasing_subjects) : 'Не определено'
                          }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Заявитель"
            width="130"
        >
          <template slot-scope="scope">
            <div style="font-weight: 600; font-size: 10px;">{{ getFullName(scope.row.client_chart_informations[0]) }}</div>
            <div style="font-size: 10px;">
              {{ getClientPhone(scope.row)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Дата создания"
            width="70"
            align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.date_to_front.date }}</div>
            <div>{{ scope.row.date_to_front.time }}</div>
          </template>
        </el-table-column>
        <el-table-column
            v-if="$store.state.user !== 'undefined' && $store.state.user.role.name === 'admin'"
            label="В работе"
            width="80"
        >
          <template slot-scope="scope">
            <div style="text-align: center">
              <el-checkbox v-model="!!scope.row.who_took_the_job"></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            v-if="$store.state.user !== 'undefined' && $store.state.user.role.name === 'admin'"
            label="Контроль документов"
            width="85"
            align="center"
        >
          <template slot-scope="scope">
            <div style="text-align: center">
              <el-checkbox v-model="!!scope.row.who_took_documents"></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            v-if="$store.state.user !== 'undefined' &&
            ($store.state.user.role.name === 'admin' || $store.state.user.role.name === 'head_of_rp' || $store.state.user.role.name === 'general_rp')"
            label="Дата договора"
            width="80"
            align="center"
        >
          <template slot-scope="scope">
            {{scope.row.app_date_to_front.date }}
          </template>

        </el-table-column>
        <el-table-column
            v-if="$store.state.user !== 'undefined' &&
            ($store.state.user.role.name === 'admin' || $store.state.user.role.name === 'head_of_rp' || $store.state.user.role.name === 'general_rp')"
            label="Дата отгрузки"
            width="80"
            align="center"
        >
          <template slot-scope="scope">
            {{ convertDate(scope.row.delayed_loading_date).date }}
          </template>

        </el-table-column>
        <el-table-column
            label="Партнер"
            width="150"
        >
          <template slot-scope="scope">
            <div v-html="getPartner(scope.row)"></div>
          </template>
        </el-table-column>
        <el-table-column
            label="Оформитель"
            width="150"
        >
          <template slot-scope="scope">
            <div style="font-weight: 600; font-size: 10px;">{{ geManagerFullName(scope.row) }}</div>
            <div style="font-size: 10px;">
              {{ getManagerPhone(scope.row)}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Статус"
            width="140"
        >
          <template slot-scope="scope">
            <div class="status" :style="{backgroundColor: scope.row.status.color}">
              {{ scope.row.status.name }}

            </div>
          </template>
        </el-table-column>

      </el-table>
      <paginator
          v-if="totalCount"
          :paginator.sync="paginator"
          @page-changed="getAll"
      ></paginator>
      <general-info
          v-loading="statLoading"
          v-if="apps"
          :count-apps="totalCount"
          :count-leasing-subjects="leasingSubjectsInfo.count"
          :full-price="leasingSubjectsInfo.full_price"
      />
    </div>


    <el-drawer :visible.sync="filterDrawer" size="50%">
      <el-form>
        <el-form-item label="Статус">
          <el-select v-model="filters.statuses" multiple filterable>
            <el-option
                v-for="status in statuses"
                :key="status.id"
                :label="status.name"
                :value="status.id"
                :selected="filters.statuses.includes(status.id)"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Дата договора">
          <el-date-picker
              ref="datePicker"
              class="date-picker"
              v-model="filters.app_dates"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Дата создания">
          <el-date-picker
              ref="datePicker"
              class="date-picker"
              v-model="filters.dates"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Дата отгрузки">
          <el-date-picker
              ref="datePicker"
              class="date-picker"
              v-model="filters.delayed_loading_dates"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Партнер" v-if="$store.getters.user.org === 'LLS'">
          <el-select @change="changePartners" multiple filterable :value="filters.partners">
            <el-option
                v-for="partner in partners"
                :key="partner.id"
                :label="partner.name"
                :value="partner.id"
                :selected="filters.partners.includes(partner.id)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Торговая точка" v-if="$store.getters.user.org === 'LLS'">
          <el-select v-model="filters.tradingPosts" multiple filterable>
            <el-option
                v-for="tradingPost in computedTradingPosts"
                :key="tradingPost.id"
                :label="tradingPost.name"
                :value="tradingPost.id"
                :selected="filters.tradingPosts.includes(tradingPost.id)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Оформитель">
          <el-select v-model="filters.employees" multiple filterable>
            <el-option
                v-for="employee in employees"
                :key="employee.id"
                :label="employee.last_name + ' ' + employee.first_name"
                :value="employee.id"
                :selected="filters.employees.includes(employee.id)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Продукт">
          <el-select v-model="filters.leasingOffers" multiple filterable>
            <el-option
                v-for="offer in leasingOffers"
                :key="offer.id"
                :label="offer.name"
                :value="offer.id"
                :selected="filters.leasingOffers.includes(offer.id)"
            ></el-option>
          </el-select>
        </el-form-item>

        <div class="buttons">
          <my-button @click="filterDrawer = false">Отменить</my-button>
          <my-button @click="resetFilters">Сбросить</my-button>
          <my-button :props-variant="'fill'" @click="getAll(-1)">Применить</my-button>
        </div>
      </el-form>
    </el-drawer>

    <el-dialog
        title="Настройки экспорта"
        :visible.sync="exportSetting"
        width="60%"
    >
      <div>
        <el-checkbox @change="setSelectAllExportSettingData">Выбрать все</el-checkbox>
      </div>

      <div class="export-settings">


        <div v-for="data in exportSettingData" :key="data.name">
          <span>{{data.label}}</span>
          <el-switch
              v-model="data.active"
              :inactive-value="0"
              :active-value="1"
              active-color="#ef3123"
              inactive-color="#a7a7a7">
          </el-switch>
        </div>
      </div>
      <div class="buttons">
        <my-button @click="exportSetting = false">Отмена</my-button>
        <my-button :props-variant="'fill'" @click="renderExcel">Экспортировать</my-button>
      </div>


    </el-dialog>

    <div
        class="context-menu"
        ref="context-menu"
        @click="closeContextMenu"
    >
      <div
          class="context-menu--view"
          ref="context-menu--view"
      >
        <div>
          <span @click="openInNewTab">Open link in new tab</span>
        </div>
        <div>
          <span  @click="openInNewWindow">Open link in new window</span>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/UI/Paginator.vue";
import MyButton from "@/components/UI/MyButton.vue";
import GeneralInfo from "@/components/listOfApplication/GeneralInfo.vue";

export default {
  name: "ListOfApplicationsView",
  components: {MyButton, Paginator, GeneralInfo},
  mixins: [paginator],
  data() {
    return {
      rightClickApp: null,
      filterDrawer: false,
      renderLoading: false,
      exportSetting: false,
      exportSettingData: [
        {label: 'Номер', name: 'app_number', active: 1},
        {label: 'Статус', name: 'status_id', active: 1},
        {label: 'Дата создания', name: 'created_at', active: 1},
        {label: 'Дата договора', name: 'app_date', active: 1},
        {label: 'Предметы лизинга', name: 'leasing_subjects', active: 0},
        {label: 'Итоговая стоимость', name: 'full_price', active: 1},
        {label: 'ФИО заявителя', name: 'client_full_name', active: 1},
        {label: 'Телефон заявителя', name: 'client_phone_number', active: 0},
        {label: 'Личный номер', name: 'personal_number', active: 0},
        {label: 'Дата рождения', name: 'client_date_of_birth', active: 0},
        {label: 'Партнер', name: 'partner', active: 0},
        {label: 'ФИО оформителя', name: 'manager_full_name', active: 0},
        {label: 'Телефон оформителя', name: 'manager_phone_number', active: 0},
        {label: 'Продукт', name: 'offer', active: 0},
        {label: 'Срок лизинга', name: 'leasing_term', active: 1},
        {label: 'Варианты расчета', name: 'calculate_option', active: 0},
        {label: 'Аванс', name: 'down_payment_price', active: 0},
        {label: 'Вид документа', name: 'document_types', active: 0},
        {label: 'Серия и номер', name: 'series_and_number', active: 0},
        {label: 'Кем выдан документ', name: 'who_issued_types', active: 0},
        {label: 'Дата выдачи', name: 'date_of_issue', active: 0},
        {label: 'Дата окончания', name: 'expiration_date', active: 0},
        {label: 'Пол', name: 'gender_types', active: 0},
        {label: 'Гражданство', name: 'nationality_types', active: 0},
        {label: 'Основной доход', name: 'basic_income_types', active: 0},
        {label: 'Основной доход, размер', name: 'basic_income_size', active: 0},
        {label: 'Дополнительный доход', name: 'additional_income_types', active: 0},
        {label: 'Дополнительный доход, размер', name: 'additional_income_size', active: 0},
        {label: 'Наличие кредитов', name: 'used_loans_types', active: 0},
        {label: 'Платеж по кредитам', name: 'monthly_payment', active: 0},
        {label: 'Стаж на последнем месте работы', name: 'experience_on_last_place_types', active: 0},
        {label: 'Уголовная ответственность', name: 'criminal_liability', active: 0},
        {label: 'Количество детей до 18 лет', name: 'number_of_children_under_18', active: 0},
        {label: 'Взыскание задолженности', name: 'debt_collection', active: 0},
        {label: 'Работодатель', name: 'employer', active: 0},
        {label: 'Должность', name: 'position_types', active: 0},
        {label: 'Общий стаж', name: 'experience_types', active: 0},
        {label: 'Семейное положение', name: 'family_status_types', active: 0},
        {label: 'Количество работающих членов семьи', name: 'working_family_members', active: 0},
        {label: 'Наличие банковских счетов', name: 'banking_accounts_types', active: 0},
        {label: 'Условия проживания', name: 'living_condition_types', active: 0},
        {label: 'Недвижимость в собственности', name: 'estate_property_types', active: 0},
        {label: 'Образование', name: 'education_types', active: 0},
        {label: 'Дополнительный телефон', name: 'client_other_phone_number', active: 0},
        {label: 'ПДЛ', name: 'is_public_official_types', active: 0},
        {label: 'Номер ТН', name: 'tn_number', active: 0},
        {label: 'Дата ТН', name: 'tn_date', active: 0},
        {label: 'Логист', name: 'general_router', active: 0},
        {label: 'Региональный представитель', name: 'general_rp', active: 0},
        {label: 'Причина отказа', name: 'reason_rejection', active: 0},
        {label: 'Адрес регистрации', name: 'address_registration', active: 0},
        {label: 'Адрес проживания', name: 'address_residential', active: 0},
        {label: 'Подпись по СМС', name: 'sms_signature', active: 0},
        {label: 'Дата отгрузки', name: 'delayed_loading_date', active: 0},
        {label: 'Дата сделки', name: 'app_in_deal_date', active: 0},
        {label: 'Андерайтер', name: 'underwriter', active: 0},
        {label: 'Контроль документов', name: 'who_took_documents', active: 0},
      ],
      filters: {
        statuses: [],
        partners: [],
        tradingPosts: [],
        employees: [],
        leasingOffers: [],
        dates: [],
        app_dates: [],
        delayed_loading_dates: [],
      },
      statuses: [],
      partners: [],
      tradingPosts: [],
      employees: [],
      leasingOffers: [],
      loading: false,
      apps: null,
      search: '',
      totalCount: 0,
      leasingSubjectsInfo: {
        full_price: 0,
        count: 0,
      },
      statLoading: false,
      pickerOptions: {

        firstDayOfWeek: 1,

        shortcuts: [
          {
            text: 'Сегодня',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Вчера',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Позавчера',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Позапозавчера',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Текущая неделя',
            onClick(picker) {
              const day = new Date().getDay()
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1));
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - day));
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Прошлая неделя',
            onClick(picker) {
              const day = new Date().getDay()
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1 + 7));
              end.setTime(end.getTime() + 3600 * 1000 * 24 * (-day));
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Текущий месяц',
            onClick(picker) {
              const oneDay = 24 * 3600 * 1000
              const month = new Date().getMonth();
              const start = new Date(new Date().setDate(1));
              const nextMonth = month + 1;
              const nextMonthFirstDay = new Date(new Date().getFullYear(), nextMonth, 1);
              const end = new Date(nextMonthFirstDay - oneDay);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Прошлый месяц',
            onClick(picker) {
              const oneDay = 24 * 3600 * 1000
              const month = new Date().getMonth();
              const prevMonth = month - 1;
              const start = new Date(new Date().getFullYear(), prevMonth, 1);
              const end = new Date(new Date().setDate(1) - oneDay);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Текущий квартал',
            onClick(picker) {
              const oneDay = 24 * 3600 * 1000
              const quarters = {
                first: [0, 1, 2],
                second: [3, 4, 5],
                third: [6, 7, 8],
                fourth: [9, 10, 11],
              }
              const month = new Date().getMonth();
              const year = new Date().getFullYear()
              let nextMonthFirstDay = null;
              let start = null;
              let end = null;

              switch (true) {
                case quarters.first.includes(month):
                  start = new Date(year, quarters.first[0], 1)
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.second[0], 1);
                  break;
                case  quarters.second.includes(month):
                  start = new Date(year, quarters.second[0], 1)
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.third[0], 1);
                  break;
                case quarters.third.includes(month):
                  start = new Date(year, quarters.third[0], 1)
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.fourth[0], 1);

                  break;
                case quarters.fourth.includes(month):
                  start = new Date(year, quarters.fourth[0], 1)
                  nextMonthFirstDay = new Date(new Date().getFullYear() + 1, quarters.first[0], 1);
                  break;
              }

              end = new Date(nextMonthFirstDay - oneDay);

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Прошлый квартал',
            onClick(picker) {
              const oneDay = 24 * 3600 * 1000
              const quarters = {
                first: [0, 1, 2],
                second: [3, 4, 5],
                third: [6, 7, 8],
                fourth: [9, 10, 11],
              }
              const month = new Date().getMonth();
              const year = new Date().getFullYear()
              let nextMonthFirstDay = null;
              let start = null;
              let end = null;

              switch (true) {
                case quarters.first.includes(month):
                  start = new Date(new Date().getFullYear() - 1, quarters.fourth[0], 1);
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.first[0], 1);
                  break;
                case  quarters.second.includes(month):
                  start = new Date(new Date().getFullYear(), quarters.first[0], 1);
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.second[0], 1);
                  break;
                case quarters.third.includes(month):
                  start = new Date(new Date().getFullYear(), quarters.second[0], 1);
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.third[0], 1);

                  break;
                case quarters.fourth.includes(month):
                  start = new Date(new Date().getFullYear(), quarters.third[0], 1);
                  nextMonthFirstDay = new Date(new Date().getFullYear(), quarters.fourth[0], 1);
                  break;
              }

              end = new Date(nextMonthFirstDay - oneDay);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.paginator.page = 1
    this.paginator.perPage = 100;
    this.getFiltersAndSearch()
    this.getAll()
    this.getStatuses()
    this.getPartners()
    this.getTradingPosts()
    this.getLeasingOffers()
    this.getEmployees()

  },
  computed: {
    isActiveFilter() {
      if (this.filters.app_dates && this.filters.app_dates.length) {
        return true;
      }
      if (this.filters.delayed_loading_dates && this.filters.delayed_loading_dates.length) {
        return true;
      }
      if (this.filters.dates && this.filters.dates.length) {
        return true;
      }
      if (this.filters.employees && this.filters.employees.length) {
        return true;
      }
      if (this.filters.statuses && this.filters.statuses.length) {
        return true;
      }
      if (this.filters.partners && this.filters.partners.length) {
        return true;
      }
      if (this.filters.tradingPosts && this.filters.tradingPosts.length) {
        return true;
      }
      if (this.filters.partners && this.filters.partners.length) {
        return true;
      }
      if (this.filters.leasingOffers && this.filters.leasingOffers.length) {
        return true;
      }
      return false;
    },
    computedTradingPosts: {
      cache: false,
      get() {

        if(this.filters.partners.length) {

          let computed = [];

          this.tradingPosts.forEach(item => {
            this.filters.partners.forEach( partnerId => {
              if(item.partner_id === partnerId) {
                computed.push(item);
              }
            })
          })

          return computed;
        }
        return this.tradingPosts
      }

    }
  },

  methods: {
    setSelectAllExportSettingData(){
      this.exportSettingData.forEach(item => item.active = 1)
    },
    tableRowClassName({row, rowIndex}) {
      if ( this.$store.getters.user.role.name === 'admin') {

        let included = [
          this.$constants.statuses.appApproved,
          this.$constants.statuses.documentControl,
          this.$constants.statuses.appInDeal,
          this.$constants.statuses.paidUp,
        ].includes(row.status_id)


        let createdAt = row.created_at ? row.created_at.split(' ')[0]  : null
        let appDate = row.app_date ? row.app_date.split(' ')[0]  : null
        if(createdAt !== appDate && included) {
          return 'warning-row';
        }

      }
      return '';
    },
    renderExcel() {

      let isActiveColumns = this.exportSettingData.filter(item => !!item.active)
      let isActiveColumnsNames = isActiveColumns.map(item => item.name)

      console.log(isActiveColumnsNames)

      this.renderLoading = true
      this.$api.leasingApplication.renderExcel({
        search: this.search,
        filters: this.filters,
        columns: isActiveColumnsNames
      },
          {responseType: 'blob'}
      )
          .then(data => {

            const date = new Date();

            const url = URL.createObjectURL(new Blob([data.data], {
              type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'applications-' + date.getDate()+ '-'+ (+date.getMonth() + 1) +'-' + date.getFullYear())
            document.body.appendChild(link)
            link.click()
          })
          .finally(() => {
            this.renderLoading = false
          })
    },
    getAllStat() {
      this.statLoading = true;
      this.$api.leasingApplication.getAllStat({
        search: this.search,
        filters: this.filters,
        archive: 0,
      })
          .then(data => {
            this.leasingSubjectsInfo = data
            this.leasingSubjectsInfo.full_price = new Intl.NumberFormat('ru-RU').format(this.leasingSubjectsInfo.full_price)
            this.statLoading = false;
          })
    },
    getAll(page) {
      if(page === -1) {
        this.paginator.page = 1
        this.paginator.perPage = 100;
      }

      this.loading = true
      let localStoragefilters = this.filters
      localStorage.setItem('filters', JSON.stringify(localStoragefilters));
      localStorage.setItem('search', this.search);

      this.$api.leasingApplication.getAll({
        search: this.search,
        filters: this.filters,
        archive: 0,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator}) => {

            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount
            this.apps = data.apps
            if (this.apps) {
              this.apps = this.apps.map(item => {
                item.date_to_front = this.convertDate(item.created_at)
                item.app_date_to_front = item.app_date ? this.convertDate(item.app_date) : {date: ''}
                return item;
              })
            }

           this.getAllStat()

            this.loading = false
            this.filterDrawer = false;
          })
    },
    changePartners(val) {
      this.filters.partners = val;
      this.filters.tradingPosts = [];
    },
    getFiltersAndSearch() {
      let localStorageFilters = localStorage.getItem('filters')
      if (localStorageFilters) {
        let fix = JSON.parse(localStorageFilters);
        if(typeof fix.tradingPosts === 'undefined') {
          fix.tradingPosts = []
        }
        if(typeof fix.app_dates === 'undefined') {
          fix.app_dates = []
        }
        if(typeof fix.delayed_loading_dates === 'undefined') {
          fix.delayed_loading_dates = []
        }
        this.filters = fix

      }

      if (localStorage.getItem('search')) {
        this.search = localStorage.getItem('search')
      }
    },
    changeDatePicker() {
      this.$refs.datePicker.unmountPicker();
      this.$refs.datePicker.mountPicker();
    },
    setFilterDate(option) {

      this.filters.option = option;
      // 0:"2023-06-08"
      // 1:"2023-06-13"

      switch (option) {
        case 'yesterday':
          this.filters.dates[0] = '2023-06-01'
          this.filters.dates[1] = '2023-06-02'

          break
        case 'day_before_yesterday':
          this.filters.dates[0] = '2023-06-03'
          this.filters.dates[1] = '2023-06-04'
          break
        case 'two_day_before_yesterday':
          this.filters.dates[0] = '2023-06-05'
          this.filters.dates[1] = '2023-06-06'
          break
        case 'current_week':
          break
        case 'last_week':
          break
        case 'current_month':
          break
        case 'last_month':
          break
        case 'current_quarter':
          break
        case 'last_quarter':
          break
      }

      this.$refs.datePicker.handleChange()

      this.$refs.datePicker.unmountPicker();

      this.$refs.datePicker.mountPicker();
      this.$refs.datePicker.value = this.filters.dates

    },
    searchData(val) {
      if (val.length >= 2 || val.length === 0) {
        this.getAll()
      }
    },
    getStatuses() {
      this.$api.leasingApplication.getStatuses()
          .then(data => {
            this.statuses = data;
          })
    },
    getPartners() {
      this.$api.administration.getPartners()
          .then((data) => {
            this.partners = data;
          })
    },
    getTradingPosts() {
      this.$api.administration.getTradingPosts()
          .then((data) => {
            this.tradingPosts = data;
          })
    },
    getLeasingOffers() {
      this.$api.administration.getLeasingOffers()
          .then((data) => {
            this.leasingOffers = data;
          })
    },
    getEmployees() {
      this.$api.administration.getEmployees()
          .then((data) => {
            this.employees = data;
          })
    },
    openContextMenu(app, column, event) {

      event.preventDefault()

      this.$refs['context-menu'].style.display = 'block'
      this.$refs['context-menu--view'].style.display = 'block'
      this.$refs['context-menu--view'].style.top = event.pageY + 'px'
      this.$refs['context-menu--view'].style.left = event.pageX + 'px'

      this.rightClickApp = app;

    },
    closeContextMenu() {
      this.$refs['context-menu'].style.display = 'none'
      this.$refs['context-menu--view'].style.display = 'none'

      this.rightClickApp = null;
    },
    openInNewTab() {
      const routeData = this.$router.resolve({name :'NewApplicationsView'})
      localStorage.removeItem('rightClickApp');
      localStorage.setItem('rightClickApp', JSON.stringify(this.rightClickApp));
      this.closeContextMenu()
      window.open(routeData.href, '_blank');
    },
    openInNewWindow() {
      const routeData = this.$router.resolve({name :'NewApplicationsView'})
      localStorage.removeItem('rightClickApp');
      localStorage.setItem('rightClickApp', JSON.stringify(this.rightClickApp));
      this.closeContextMenu()
      window.open(routeData.href, "_blank", "width="+screen.availWidth+",height="+screen.availHeight)
    },
    openApp(app, column, event) {
      this.$store.commit('newApplication/updateState', app)
      this.$router.push('new-applications')
    },
    getFullSubjectsPrice(subjects) {
      let price = 0;
      subjects.forEach(item => {
        price += +item.price
      })
      return (price).toFixed(2);
    },
    getFullName(data) {
      if (data) {
        return data.last_name + ' ' + data.first_name;
      }
      return '-';
    },
    geManagerFullName(data) {
        if (data.integration_who_created) {
          return data.integration_who_created
        }
        if(data.who_created) {
          return data.who_created.last_name + ' ' + data.who_created.first_name;
        }
        return '-';

    },
    changePhoneByMask(phone) {
      let cleaned = ('' + phone).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);

      if (!match)  return null;

      return ['+', match[1], ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
    },
    getManagerPhone(data) {
      if (data.integration_who_created_phone) {
        return  this.changePhoneByMask(data.integration_who_created_phone)
      }
      if(data.who_created) {
        return this.changePhoneByMask(data.who_created.phone);
      }
      return '-';

    },
    getClientPhone(data) {
      if(data.client_chart_informations.length && data.client_chart_informations[0].phone_number) {
        return this.changePhoneByMask(data.client_chart_informations[0].phone_number);
      }
      return '-';

    },
    getPartner(data) {
      if (!data.trading_post && !data.who_created) {
        return `<div> ${data.name} </div>` +
            `<div>Не определено</div>`
        //Неопределенный код партнера
      }
      if (data.trading_post) {
        return `<div> ${data.trading_post.partner.name} </div>` +
            `<div>${data.trading_post.name}</div>`
      }

      if (data.who_created) {
        return `<div> ${data.who_created.trading_post.partner.name} </div>` +
            `<div>${data.who_created.trading_post.name}</div>`
      }
    },
    convertDate(data) {
      if(!data) {
        return {
          date: null,
          time: null
        };
      }
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
    resetFilters() {
      this.filters = {
        statuses: [],
        partners: [],
        tradingPosts: [],
        employees: [],
        leasingOffers: [],
        dates: [],
        app_dates: [],
        delayed_loading_dates: [],
      }
      localStorage.setItem('filters', this.filters)
      this.getAll()
    }

  }
}
</script>

<style lang="scss" scoped>
.context-menu {
  display: none;
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 100;
  &--view {
    background: #fff;
    display: none;
    width: 240px;
    position:absolute;
    border: 1px solid #ddd;
    font-size: 13px;
    padding: 10px 0;
    z-index: 101;
     span  {
       padding: 3px 0 3px 30px;
       display: inline-block;
       width: 100%;
       &:hover {
         background: #eee;
       }
       cursor: pointer;
     }
  }
}

.container {
  max-width: 1500px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;
  @media(max-width: 1024px) {
    padding: 10px 15px;
  }

  .export-settings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 30px;
     > div {
       display: flex;
       justify-content: space-between;
       column-gap: 10px;
       > span {
         max-width: 67%;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
       > .el-switch {
         width: 30%;
       }
     }
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 120px;
    box-shadow: none;

    .search {
      display: grid;
      grid-template-columns: 50px 1fr;
      margin-bottom: 1rem;
      .el-input-group__append {
        background-color: transparent;
        color: transparent;
        //padding: 0 50px;
      }
      ::v-deep .el-input {
        margin-top: 5px;
        &-group {
          &__append {
            background-color: transparent!important;
            border: none;
            padding: 0 5px;
          }
        }
      }
      > span {
        > img {
          cursor: pointer;
        }
      }


      ::v-deep .el-button {
        background: #ef3123;
        padding: 10px 50px;

        i.el-icon-search {
          color: #fff;
        }

        &:hover {
          opacity: .7;
        }
      }

      @media(max-width: 450px) {
        display: block;
        text-align: center;
        margin-bottom: 0.5rem;
      }
    }
  }


  .filter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    transform: translateY(-5px);
    .el-icon-s-operation {
      cursor: pointer;
      font-size: 32px;
      margin-bottom: .8rem;
      transition: color .15s linear;

      &:hover,
      &.active {
        color: #ef3123;
      }
    }
    span {
      img {
        cursor: pointer;
        margin-bottom: 10px;
      }
    }

  }


  ::v-deep .el-table {
    &.admin {
      width: 100%;
    }
    &.client {
      margin-left : 51px;
      width: calc(100% - 51px)
    }
    &__header {
      thead {
        th {
          background: #eeeeee;
          color: #555;
          font-weight: 600;
        }
      }
      &-wrapper {

      }
    }
    .warning-row {
      background-color: #ffe4e1;
    }
    &__cell {
      padding: 5px 0;
      font-size: 10px;

      .status {
        border-radius: 5px;
        text-align: center;
        font-size: 10px;
      }
    }
  }

  ::v-deep.el-pagination {
    margin-top: .5rem;
    text-align: center;
    padding-left: 20rem;
  }

  ::v-deep.el-drawer {
    &__body {

      .el-form {
        max-width: 70%;
        margin: 0 auto;

        &-item {
          &__label {
            width: 100%;
            text-align: left;
          }

          .el-select,
          .el-date-editor {
            width: 100%;

            .el-tag {
              background: #ef3123;
              color: #fff;

              &__close,
              {
                color: #ef3123;
                background: #fff;
              }
            }
          }

          .el-input-number {
            width: 45%;

            &__prefix {
              padding: 0 .32rem;
            }

            &__increase,
            &__decrease {
              i {
                position: relative;
                top: 1px;
              }
            }

            &__increase {
              right: 2px;
              height: calc(100% - 3px);
            }

            &__decrease {
              height: calc(100% - 3px);
            }
          }
        }
      }
    }

    .buttons {
      text-align: right;

      button {
        &:first-child,
        &:nth-child(2) {
          margin-right: 1rem;
        }
      }
    }


  }

  .buttons {
    text-align: right;

    button {
      &:first-child,
      &:nth-child(2) {
        margin-right: 1rem;
      }
    }
  }
}

.content {
  width: 100%;
  height: calc(100vh - 103px);
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0 #00000040;
}
::v-deep .general-info {
  top: -2.1rem;
  .el-loading-spinner {
    margin-top: -15px;
    .circular {
      height: 20px;
      width: 20px;
    }
  }
}


</style>
