<template>
  <div :class="'select'+' ' + propsType" class="group">
    <p>
      {{ propsType }}
    </p>
    <el-select
        :value="value9"
        @change="changeValue"
        filterable
        remote
        reserve-keyword
        clearable
        placeholder="Выберите из списка"
        :remote-method="remoteMethod"
        :loading="loading">
      <el-option
          v-for="item in options4"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>


export default {
  name: "my-select-soato2",
  props: {
    propsType: null
  },
  data() {
    return {
      value9: "",
      options4: [],
      list: [],
      states: ["Alabama", "Alaska", "Arizona",
        "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida",
        "Georgia", "Hawaii", "Idaho", "Illinois",
        "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota",
        "Mississippi", "Missouri", "Montana",
        "Nebraska", "Nevada", "New Hampshire",
        "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio",
        "Oklahoma", "Oregon", "Pennsylvania",
        "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas",
        "Utah", "Vermont", "Virginia",
        "Washington", "West Virginia", "Wisconsin",
        "Wyoming"],
      loading: false,
    }
  },
  mounted() {
    this.list = this.states.map(item => {
      return {value: item, label: item};
    });
  },
  watch: {},
  computed: {},
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options4 = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options4 = [];
      }
    },
    changeValue(e){
      console.log(e)
      this.value9 = e
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
}

.name {
  grid-column-start: 1;
  grid-column-end: 3;
}

.select {
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  &.small {
    p {
      font-size: 11px;
    }
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;

      .el-input__inner {
        font-size: 12px;
      }

      &:hover {
        .el-input__inner {
          border-color: #C0C4CC;
          width: 100%;
          font-size: 12px;
        }

      }

      .el-tag.el-tag--info {
        color: #fff;
        background-color: #ef3123;

        .el-tag__close {
          color: #fff;
          background-color: #ef3123;
        }
      }

      &.warning {
        background: #fff2c5 !important;

        .el-input {
          input {
            background: #fff2c5 !important;
          }
        }
      }
    }
  }

}
</style>