var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters.user.id && _vm.$store.getters.user.role.name === 'admin')?_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper"},[_c('administration-menu',{attrs:{"state":_vm.adminLocalState},on:{"update":_vm.setAdminLocalState}}),_c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.$route.meta.title))]),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 1)?_c('documents'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 2)?_c('fields'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 3)?_c('offers'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 4)?_c('partners'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 5)?_c('trading-posts'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 6)?_c('employees'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 7)?_c('handbooks'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 8)?_c('roles'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 9)?_c('affiliation'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 10)?_c('sms-documents'):_vm._e(),(_vm.$store.getters.user.id &&
          _vm.$store.getters.user.role.name === 'admin' &&
          _vm.adminLocalState === 11)?_c('logs'):_vm._e()],1)],1)]):_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"24px","color":"red","height":"200px"}},[_vm._v("Данная страница Вам не доступна")])}
var staticRenderFns = []

export { render, staticRenderFns }