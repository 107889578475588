<template>
  <div>
    <div>
      <h4>Торговые точки</h4>
    </div>
    <div class="search">
      <span v-loading="renderLoading" element-loading-spinner="el-icon-loading">
            <img
                v-if="$store.state.user !== 'undefined' &&
                      ($store.state.user.role.name === 'admin' || $store.state.user.role.name === 'head_of_rp')"
                @click="renderExcel"
                src="@/assets/img/icons/icon-excel.svg"
                alt=""
            >
          </span>
      <div @keyup.enter="getTradingPosts">
        <el-input
            placeholder="Адрес торговой точки, партнер"
            v-model="search"
        >
          <my-button slot="append" icon="el-icon-search" @click="getTradingPosts">Поиск</my-button>
        </el-input>
      </div>
    </div>
    <div class="command-buttons">
      <el-button
          class="add"
          icon="el-icon-circle-plus"
          circle
          @click="add"
      />
    </div>

    <div class="data-table">
      <el-table
          :data="tradingPosts"
          height="calc(86vh - 200px)"
          style="overflow: auto;"
          @row-click="edit"
      >
        <el-table-column
            label="№"
            prop="id"
            width="60px"
        >
        </el-table-column>
        <el-table-column
            label="Адрес торговой точки"
            prop="name"
            sortable
        >
        </el-table-column>

        <el-table-column
            prop="code"
            label="Код"
            width="120px"
        >
        </el-table-column>

        <el-table-column
            label="Партнер"
            prop="code"
            sortable
        >
          <template slot-scope="scope">
            <div>{{scope.row.partner.name}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="Статус"
            width="100px"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.is_active ? 'Активен' : 'Деактивирован' }}</div>
          </template>
        </el-table-column>
      </el-table>

      <paginator
          v-if="totalCount"
          :paginator.sync="paginator"
          @page-changed="getTradingPosts"
      ></paginator>

    </div>

<!--    <div class="trading-posts">-->
<!--      <div class="trading-posts-header">-->
<!--        <div>№</div>-->
<!--        <div>Адрес партнёра</div>-->
<!--        <div>Код</div>-->
<!--        <div>Партнер</div>-->
<!--        <div>Статус</div>-->
<!--      </div>-->
<!--      <div class="trading-posts-body" v-if="tradingPosts">-->
<!--        <div v-for="post in tradingPosts" @click="edit(post)">-->
<!--          <div>{{ post.id }}</div>-->
<!--          <div>{{ post.name }}</div>-->
<!--          <div>{{ post.code }}</div>-->
<!--          <div>{{ post.partner.name }}</div>-->
<!--          <div>{{ post.is_active ? 'Активен' : 'Деактивирован' }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <el-drawer
        :visible.sync="tradingPostDrawer"
        direction="rtl"
        :before-close="clear"
        size="50%"
    >
      <el-form
          :data="selectedTradingPost"
          label-position="left"
          label-width="150px"
          size="small"
      >
        <el-form-item label="Адрес партнёра">
          <el-input v-model="selectedTradingPost.name"></el-input>
        </el-form-item>
        <el-form-item label="Код">
          <el-input v-model="selectedTradingPost.code"></el-input>
        </el-form-item>
        <el-form-item label="Партнер">
          <el-select filterable v-model="selectedTradingPost.partner_id">
            <el-option
                v-for="partner in partners"
                :key="partner.id"
                :value="partner.id"
                :label="partner.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Логист">
          <el-select
              v-model="selectedTradingPost.general_router_id"
              filterable
              clearable
          >
            <el-option
                v-for="user in generalRouters"
                :key="user.id"
                :value="user.id"
                :label="editFullName(user)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Рег. представитель">
          <el-select
              v-model="selectedTradingPost.general_rp_id"
              filterable
              clearable
          >
            <el-option
                v-for="user in generalRps"
                :key="user.id"
                :value="user.id"
                :label="editFullName(user)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Активен">
          <el-switch
              v-model="selectedTradingPost.is_active"
              active-color="#ef3123"
              inactive-color="#eeeeee"
              :active-value="1"
              :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="">
          <div style="color: #ef3123; font-size: 11px; line-height: 1.3;">При <b
              style="font-size: 13px;">декактивации</b> торговой точки, все пользователи, принадлежащие данному
            подразделению так же деактивируются. При <b style="font-size: 13px;">активации</b> - активация пользователей
            не применяется
          </div>
        </el-form-item>

        <div class="controls">
          <my-button props-variant="empty" @click="clear">Отмена</my-button>
          <my-button props-variant="fill" @click="save">Сохранить</my-button>
        </div>

      </el-form>
    </el-drawer>

  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import Paginator from "@/components/UI/Paginator.vue";
import {paginator} from "@/mixins/paginator";

export default {
  name: "tradingPosts",
  components: {Paginator, MyButton},
  mixins: [paginator],
  data() {
    return {
      exportSetting: false,
      tradingPostDrawer: false,
      renderLoading: false,
      tradingPosts: [],
      partners: [],
      search: null,
      totalCount: 0,
      selectedTradingPost: {
        id: null,
        name: null,
        code: null,
        is_active: 1,
        partner_id: null,
        general_router_id: null,
        general_rp_id: null,
      },
      generalRouters: [],
      generalRps: [],
    }
  },
  mounted() {
    this.getTradingPosts()
    this.getPartners()
    this.getAllGeneralRouters()
    this.getAllGeneralRPs()
  },
  methods: {
    renderExcel() {

      this.renderLoading = true

      this.$api.administration.renderTradingPosts( {
            search: this.search,
          },
          {responseType: 'blob'}
      )
    .then(data => {1
        const date = new Date();

        const url = URL.createObjectURL(new Blob([data.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'trading-posts-' + date.getDate()+ '-'+ (+date.getMonth() + 1) +'-' + date.getFullYear())
        document.body.appendChild(link)
        link.click()
      })
          .finally(() => {
            this.renderLoading = false
          })
    },
    getTradingPosts() {
      this.$api.administration.getTradingPosts({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        search: this.search,
      })
          .then(({data, paginator}) => {
            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount
            this.tradingPosts = data
          })
    },
    getPartners() {
      this.$api.administration.getPartners()
          .then((data) => {
            this.partners = data;
          })
    },
    getAllGeneralRouters() {
      this.$api.administration.getAllGeneralRouters()
          .then((data) => {
            this.generalRouters = data
          })
    },
    getAllGeneralRPs() {
      this.$api.administration.getAllGeneralRPs()
          .then((data) => {
            this.generalRps = data
          })
    },
    add() {
      this.selectedTradingPost = {
        id: null,
        name: null,
        code: null,
        is_active: 1
      }
      this.tradingPostDrawer = true
    },
    edit(partner) {
      this.selectedTradingPost = partner;
      this.tradingPostDrawer = true
    },
    save() {
      this.$api.administration.saveTradingPost(this.selectedTradingPost)
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }
            this.tradingPostDrawer = false
            this.getTradingPosts()
          })
    },
    deleteTradingPost(post) {
      this.$api.administration.deleteTradingPost({id: post.id})
          .then(data => {
            if (!data.errors) {

              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Удалено'
              })

              this.tradingPosts = this.tradingPosts.filter(item => item.id !== post.id)
            }
          })
    },
    editFullName(data){
      if (data){
        let dataSplit = data.full_name.split(' ')
        if (dataSplit.length === 3){
          return dataSplit[1]+' '+ dataSplit[0]+' '+dataSplit[2]
        }
        if (dataSplit.length === 2){
          return dataSplit[1]+' '+ dataSplit[0]
        }
        if (dataSplit.length === 1){
          return dataSplit[1]
        }
        return 'Не определено'
      }
    },
    clear() {
      this.selectedTradingPost = {
        id: null,
        name: null,
        code: null,
        is_active: 1
      }
      this.tradingPostDrawer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.trading-posts {
  &-header, &-body > div {
    display: grid;
    grid-template-columns: 0.5fr 11fr 2fr 6fr 2fr 3fr 2fr;
    padding: .3rem 1rem;
    gap: 20px;
    font-size: 10px;
  }

  &-header {
    margin-top: 1rem;
  }

  &-body {
    > div {
      cursor: pointer;
      position: relative;

      i {
        position: absolute;
        right: 1rem;
      }

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(2n - 1) {
        background: #f5f5f5;
      }
    }
  }
}

::v-deep .el-drawer {
  .el-form {
    padding: 1rem 10rem 1rem 2rem;

    .el-select {
      width: 100%;
    }

    .controls {
      text-align: right;

      > button {
        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
.data-table {
  ::v-deep .el-table {
    &__cell {
      padding: 5px 0;
      font-size: 10px;
    }
  }
}
.search {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}
</style>