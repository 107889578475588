var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.renderObject)?_c('div',{staticClass:"profile-block"},[_c('field-group',{attrs:{"fields":[
           // renderObject['organization_scope_types'].field,
           _vm.renderObject['employer'].field,
           _vm.renderObject['position_types'].field ]}}),_c('field-group',{attrs:{"fields":[
           _vm.renderObject['experience_types'].field,
           _vm.renderObject['family_status_types'].field ]}}),_c('field-group',{attrs:{"fields":[
           _vm.renderObject['working_family_members'].field,
           _vm.renderObject['banking_accounts_types'].field ]}}),_c('field-group',{attrs:{"fields":[
           _vm.renderObject['living_condition_types'].field,
           _vm.renderObject['estate_property_types'].field ]}}),_c('field-group',{attrs:{"fields":[
           _vm.renderObject['education_types'].field,
           _vm.renderObject['other_phone_number'].field ]}}),_c('field-group',{attrs:{"fields":[
           _vm.renderObject['is_public_official_types'].field ]}}),_c('div',{staticClass:"btn-block"},[_c('my-button',{attrs:{"props-variant":"fill","disabled":_vm.saveIsDisabled},on:{"click":_vm.saveProfileData}},[_vm._v("Сохранить")])],1)],1):_vm._e(),(!_vm.renderObject)?_c('my-skeleton',{attrs:{"variant":'profile'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }