<template>
  <div>
    <div class="profile-block" v-if="renderObject">

      <field-group
          :fields="[
             // renderObject['organization_scope_types'].field,
             renderObject['employer'].field,
             renderObject['position_types'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['experience_types'].field,
             renderObject['family_status_types'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['working_family_members'].field,
             renderObject['banking_accounts_types'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['living_condition_types'].field,
             renderObject['estate_property_types'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['education_types'].field,
             renderObject['other_phone_number'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['is_public_official_types'].field,
        ]"
      />
      <div class="btn-block">
        <my-button props-variant="fill" @click="saveProfileData" :disabled="saveIsDisabled">Сохранить</my-button>
      </div>
    </div>
    <my-skeleton v-if="!renderObject" :variant="'profile'"/>
  </div>
</template>

<script>
import MyInputText from "@/components/UI/MyInputText.vue";
import MyInputNumber from "@/components/UI/MyInputNumber.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MyInputPhone from "@/components/UI/MyInputPhone.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySkeleton from "@/components/UI/MySkeleton.vue";
import FieldGroup from "@/components/general/fieldGroup.vue";
import {mapActions} from "vuex";


export default {
  name: "profile-information",
  components: {FieldGroup, MySkeleton, MyButton, MyInputPhone, MySelect, MyInputNumber, MyInputText},
  props: {
    propsChangeMenuState: {
      type: Function
    }
  },
  data() {
    return {
      savedData: false,
      loadData: false,
      approved: false,
      fieldsData: null,
      renderObject: null,
    }
  },
  mounted() {
    this.getFields(this.$constants.navigations.newApplication.sub.profileInformation)
  },
  computed: {
    saveIsDisabled() {
      return [
            this.$constants.statuses.documentControl,
            this.$constants.statuses.appInDeal,
            this.$constants.statuses.paidUp,
            this.$constants.statuses.appCanceled,
            this.$constants.statuses.appRejection,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name !== 'admin'
    },
  },
  methods: {
    ...mapActions({ saveData: 'newApplication/saveData'}),
    saveProfileData() {
      this.saveData()
    },
    applicationApproval() {
      this.loadData = true
      setTimeout(() => {
        this.loadData = false
        this.savedData = true
        this.approved = true
      }, [2000])
    },
    goNextStep() {
      this.propsChangeMenuState(this.$constants.navigations.newApplication.sub.documentPrinting)
    },
    getFields(id) {
      this.$api.fields.getFields({id: id}).then((data) => {
        this.fieldsData = data
        this.formingObjectForRendering(data)

      })
    },
    formingObjectForRendering(data) {
      let object = {}
      data.forEach(el => {
        if (el.field['name'] in object) {
          object[el.field['name']] = el
        } else {
          object[el.field['name']] = [el][0]
        }
      })
      this.renderObject = object
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-block {
  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .address-block {
    margin-bottom: 40px;

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media (max-width: 1230px) {
        gap: 10px;
      }
      @media (max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;

      }
    }
  }

  .btn-block {
    display: flex;
    justify-content: flex-end;

    button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

}
</style>