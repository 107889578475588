<template>
  <div
      class="container"
      v-if="$store.getters.user.id && $store.getters.user.role.name === 'admin'"
  >
    <div class="wrapper">
      <administration-menu
          @update="setAdminLocalState"
          :state="adminLocalState"
      />
      <div class="content">
        <h1>{{$route.meta.title}}</h1>
          <documents
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 1"
          ></documents>

          <fields
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 2"
          ></fields>

          <offers
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 3"
          ></offers>

          <partners
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 4"
          ></partners>

          <trading-posts
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 5"
          ></trading-posts>

          <employees
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 6"
          ></employees>

          <handbooks
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 7"
          ></handbooks>

          <roles
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 8"
          ></roles>

          <affiliation
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 9"
          ></affiliation>

          <sms-documents
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 10"
          ></sms-documents>

          <logs
              v-if="$store.getters.user.id &&
            $store.getters.user.role.name === 'admin' &&
            adminLocalState === 11"
          ></logs>

      </div>
    </div>

  </div>
  <div v-else style="display: flex; justify-content: center; align-items: center; font-size: 24px; color: red; height: 200px;">Данная страница Вам не доступна</div>
</template>

<script>
import AdministrationMenu from "@/components/administration/AdministrationMenu.vue";
import Documents from "@/views/Documents.vue";
import Fields from "@/components/administration/Fields.vue";
import Offers from "@/components/administration/Offers.vue";
import Partners from "@/components/administration/Partners.vue";
import TradingPosts from "@/components/administration/TradingPosts.vue";
import Employees from "@/components/administration/Employees.vue";
import Handbooks from "@/components/administration/Handbooks.vue";
import Roles from "@/components/administration/Roles.vue";
import Affiliation from "@/components/administration/Affiliation.vue";
import Logs from "@/components/administration/Logs.vue";
import SmsDocuments from "@/components/administration/SmsDocuments.vue";

export default {
  name: "Administration",
  components: {
    SmsDocuments,
    Logs,
    Affiliation,
    Roles, Handbooks, Employees, TradingPosts, Partners, Offers, Fields, Documents, AdministrationMenu},
  data() {
    return {
      adminLocalState: 1
    }
  },
  methods: {
    setAdminLocalState(id) {
      this.adminLocalState = id
    },
  },
}
</script>


<style lang="scss" scoped>
.container {
  max-width: 1500px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;

  @media(max-width: 1024px) {
    padding: 10px 15px;
  }
}

.wrapper {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: flex-start;
  gap: 15px;
  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
  }


  .content {
    padding: 15px 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 2px 4px 0px #00000040;
    @media(max-width: 550px) {
      padding: 15px;
    }
  }
}
</style>