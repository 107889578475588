import axiosInstance from "./axiosInstance";
import router from '@/router'
import { Notification } from 'element-ui';
const requestSender = (method, url, payload, options= {}) => {
    method = method.toLowerCase();
    if( method === 'get' ){
        payload = {params:payload};
    }
    return new Promise((resolve, reject) => {
        axiosInstance[method](url, payload, options)
            .then((response) => {

                    if( response.data.success ){
                        if(response.data.paginator){
                            resolve({
                                data: response.data.data,
                                paginator: response.data.paginator,
                                // sorter: response.data.sorter
                            });
                        } else {
                            resolve(response.data.data);
                        }
                    } else {
                        if( (options && options.responseType && (options.responseType === 'blob'|| options.responseType === 'application/pdf'))){
                            resolve({
                                data: response.data,
                                headers: response.headers,
                            });
                        } else {
                            processError(response);
                            reject(response);
                        }
                    }


            })
            .catch(e => {
                processError(e.response);
                reject(e.response);
            })
    });

}

function processError(response){
     const status = response ? response.status : false;

    if( status === 404 ){
        if( router.currentRoute.name === '404' ){
            return;
        }
        router.push({ name: '404'});
        return;
    }


    let message = 'Что-то пошло не так';
    if( response && response.data ){
        message = parseErrorsForMessage(response.data.errors, response.data)
    }
    Notification.error({
        title: 'Ошибка',
        message: message,
        dangerouslyUseHTMLString: true
    });


}

function parseErrorsForMessage(errors, data){
    let message = '';

    if(data.data.message) {
        message = data.data.message;
        return message;
    }
    if( errors instanceof Array ){
        message = '';
        errors.forEach(error => {
            if( error instanceof Array ){
                message += '<div>' + data.data.type ? data.data.type : 'Что-то пошло не так' + '</div>';
            }else if( error instanceof Object ){
                message += '<div>' + error.error + '</div>';
            } else {
                if( !error ) return;
                message += '<div>' + error + '</div>';
            }
        })
    } else if( errors instanceof Object ){
        message = '';
        for (const [key, value] of Object.entries(errors)) {
            errors[key].forEach(error => {
                if( !error ) return;
                message += '<div>' + error + '</div>';
            })
        }
    }

    if( !message.length ){
        message = 'Что-то пошло не так';
    }
    return message;
}


export default requestSender
