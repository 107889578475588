import requestSender from "./base/requestSender";

export default function(axiosInstance){
    const BASE_URI  = 'scoring/'

    return {
        calculate(payload){
            return requestSender('post', BASE_URI + 'calculate-data', payload);
        },
        get(payload){
            return requestSender('post', BASE_URI + 'get-data', payload);
        }
    }
}