<template>
  <div>
    <div class="analisys" v-if="renderObject">

      <field-group
          :fields="[
              renderObject['visual_assessment_types'].field
              ]"
      />

      <el-divider></el-divider>

      <field-group
          :fields="[

              renderObject['document_types'].field,
              renderObject['series_and_number'].field,
              renderObject['who_issued_types'].field,
              ]"
      />

      <field-group
          :fields="[
              renderObject['date_of_issue'].field,
              renderObject['expiration_date'].field,

              ]"
      />

      <field-group
          :fields="[
              renderObject['basic_income_types'].field,
              renderObject['basic_income_size'].field,
              ]"
      />

      <field-group
          :fields="[
              renderObject['additional_income_types'].field,
              renderObject['additional_income_size'].field,
              ]"
      />

      <field-group
          :fields="[
              renderObject['used_loans_types'].field,
              renderObject['monthly_payment'].field,
              ]"
      />

      <field-group
          :fields="[
              renderObject['experience_on_last_place_types'].field,
              {},
              renderObject['number_of_children_under_18'].field,
              ]"
      />

      <field-group
          :fields="[
              renderObject['criminal_liability'].field,
              {},
              renderObject['debt_collection'].field,
              ]"
      />

      <div>
        <div class="btn-block">
          <div class="btn-block__wrapper">
<!--            <my-button :disabled="true" @click="getDocumentTemplatesForPrint">Распечатать согласия</my-button>-->
            <div>
<!--              <p>Нажимая данную кнопку подтверждаю, что подпись под документами клиентом поставлена</p>-->
<!--              <my-button class="btn-accept" :style="{pointerEvents:approved?'none':'unset'}"-->
<!--                         @click="applicationApproval">-->
<!--                <span v-if="!loadData && !approved">Согласия подписаны </span>-->
<!--                <i v-if="loadData && !approved" class="el-icon-loading"></i>-->
<!--                <span v-if="approved">Одобрено</span>-->
<!--              </my-button>-->
            </div>
          </div>
          <my-button @click="goNextStep" class="next" props-variant="fill" :disabled="saveIsDisabled">Сохранить</my-button>
        </div>
      </div>

    </div>
    <my-skeleton v-if="!renderObject" :variant="'analisys'"/>
  </div>
</template>

<script>

import MyInputPhone from "@/components/UI/MyInputPhone.vue";
import MyInputNumber from "@/components/UI/MyInputNumber.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MySwitchInput from "@/components/UI/MyInputSwitch.vue";
import MyInputText from "@/components/UI/MyInputText.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyInputCheckbox from "@/components/UI/MyInputCheckbox.vue";
import MyInputDate from "@/components/UI/MyInputDate.vue";
import MySkeleton from "@/components/UI/MySkeleton.vue";
import FieldGroup from "@/components/general/fieldGroup.vue";
import EditField from "@/components/general/EditField.vue";
import {mapActions} from "vuex";
import notification from "element-ui/packages/notification";


export default {
  name: "customer-analysis",
  components: {
    EditField,
    FieldGroup,
    MySkeleton,
    MyInputDate,
    MyInputCheckbox,
    MyButton,
    MyInputText,
    MySwitchInput,
    MySelect,
    MyInputNumber,
    MyInputPhone
  },
  props: {
    propsChangeMenuState: {
      type: Function
    }
  },
  data() {
    return {
      savedData: false,
      loadData: false,
      approved: false,
      fieldsData: null,
      renderObject: null,
      documentTemplates: [],
    }
  },
  mounted() {
    this.getFields(this.$constants.navigations.newApplication.sub.customerAnalysis)
  },
  computed: {
    saveIsDisabled() {
      return [
            this.$constants.statuses.documentControl,
            this.$constants.statuses.appInDeal,
            this.$constants.statuses.paidUp,
            this.$constants.statuses.appCanceled,
            this.$constants.statuses.appRejection,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name !== 'admin'
    },
  },

  methods: {
    ...mapActions({ saveData: 'newApplication/saveData'}),
    getDocumentTemplatesForPrint() {
      if(!this.$store.state.newApplication.leasing_application_id) {
        return this.$notify.error({
          duration: 2500,
          title: 'Ошибка',
          message: 'Печать документов возможна только после сохранения заявки.',
        });
      }
      this.$api.field.getDocumentTemplatesForPrint({
        leasing_application_id: this.$store.state.newApplication.leasing_application_id,
      })
          .then(data => {
            this.documentTemplates = data;
            let interestDocument = this.documentTemplates.filter(item => item.code === '0000')[0]
            var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(interestDocument.text);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();

            // this.documentTemplates.forEach(item => {
            //   var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            //   WinPrint.document.write(item.text);
            //   WinPrint.document.close();
            //   WinPrint.focus();
            //   WinPrint.print();
            //   WinPrint.close();
            // })

          })


    },
    applicationApproval() {
      this.loadData = true
      setTimeout(() => {
        this.loadData = false
        this.savedData = true
        this.approved = true
      }, [2000])
    },
    goNextStep() {
      this.saveData()
      // this.propsChangeMenuState(this.$constants.navigations.newApplication.sub.profileInformation)
    },
    getFields(id) {
      this.$api.fields.getFields({id: id}).then((data) => {
        this.fieldsData = data
        this.formingObjectForRendering(data)

      })
    },
    formingObjectForRendering(data) {
      let object = {}
      data.forEach(el => {
        if (el.field['name'] in object) {
          object[el.field['name']] = el
        } else {
          object[el.field['name']] = [el][0]
        }
      })
      this.renderObject = object
    }
  },
}
</script>

<style lang="scss" scoped>
.analisys {
  h3 {
    font-weight: 500;
    margin-bottom: 20px;

  }

  .inputs-block {
    &__item {
      position: relative;
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      @media(max-width: 1024px) {
        gap: 10px;
      }
      @media(max-width: 850px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
    }
  }
  .edit-for-role {
    position: absolute;
    right: 0;
  }

  .btn-block {
    display: grid;

    button {
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }

    &__wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 20px;
      @media(max-width: 850px) {
        display: block;
        width: 100%;
      }

      div {
        display: grid;
      }

      button {
        min-width: 240px;
        @media(max-width: 850px) {
          width: 100% !important;
          margin-bottom: 20px;
        }

        &.btn-accept {
          @media(max-width: 850px) {
            width: 100%;
            order: -1;
            margin-bottom: 5px;
          }

          i {
            font-size: 20px;
            line-height: 1;
          }
        }
      }

      p {
        padding: 5px 5px;
        text-align: start;
        max-width: 240px;
        font-size: 9px !important;
        color: #555;
        @media(max-width: 850px) {
          max-width: unset;
          width: 100%;

        }
      }
    }

    .next {
      margin-left: auto;
    }

  }
}

</style>