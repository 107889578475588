import {callLine} from "less/lib/less/less-error";
import Api from "@/api/base";
import notification from "element-ui/packages/notification";

import constants from '@/plugins/constants'
import store from "@/store/index";
import {includes} from "core-js/internals/array-includes";


function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function prepareTypes(data) {
    if (IsJsonString(data)) {
        return JSON.parse(data)
    } else {
        return +data ?  +data : null
    }
}
function changePhoneByMask(phone) {
    let cleaned = ('' + phone).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);

    if (!match)  return null;

    return ['+', match[1], ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
}

function checkDateOfBirth(state, checking) {
    let endDate = new Date(state.date_of_birth);
    endDate.setFullYear(endDate.getFullYear() + 75);
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

    let leasingTerm = store.getters.fields.find(item => item.name === 'leasing_term')
    leasingTerm = leasingTerm.data.find(item => item.id === +state.leasing_term)

    let endLeasingDate = new Date();
    endLeasingDate = new Date(endLeasingDate.setMonth(endLeasingDate.getMonth() + +leasingTerm.name));
    endLeasingDate = new Date(endLeasingDate.getFullYear(), endLeasingDate.getMonth(), endLeasingDate.getDate());
    if(endLeasingDate > endDate) {
        checking.status = false;
        checking.message = 'На момент погашения лизинга заявителю должно быть не более 75 лет. Уменьшите срок лизинга.'

    }
    return checking;

}
function checkDocumentType(state, checking) {
    if([1,3].includes(state.document_types)) {
        if(state.expiration_date) {
            let numberOfDaysToAdd = 35;
            let endDate = new Date();
            endDate.setDate(endDate.getDate() + numberOfDaysToAdd);
            endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

            let expirationDate = new Date(state.expiration_date)
            expirationDate = new Date(expirationDate.getFullYear(), expirationDate.getMonth(), expirationDate.getDate())

            if(expirationDate < endDate) {
                checking.status = false;
                checking.message = 'Срок действия документа должен быть более 35 дней. Рекомендуйте заявителю поменять паспорт.'
            }
        }
    }

    if([2,4,5].includes(state.document_types)) {
        if(state.expiration_date) {
            let leasingTerm = store.getters.fields.find(item => item.name === 'leasing_term')
            leasingTerm = leasingTerm.data.find(item => item.id === +state.leasing_term)

            let endDate = new Date();
            endDate = new Date(endDate.setMonth(endDate.getMonth() + +leasingTerm.name));
            endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
            let expirationDate = new Date(state.expiration_date)
            expirationDate = new Date(expirationDate.getFullYear(), expirationDate.getMonth(), expirationDate.getDate())

            if(expirationDate < endDate) {
                checking.status = false;
                checking.message = 'Срок действия вида на жительство должен быть больше срока лизинга. Уменьшите срок лизинга.'
            }
        }
    }
    return checking;
}
function checkingPhoneNumber(state, checking) {
    //TODO: "+375 (29) 565-56-55"
    const phonePattern = /^\+375 \((25|29|33|44)\) \d{3}-\d{2}-\d{2}$/;

    if(!phonePattern.test(state.phone_number)) {
        checking.status = false;
        checking.message = 'Введен некорректный номер телефона.'
    }

    return checking;

}

function fixFIO(state, checking) {

}
function checkBeforeSave(state) {

    let checking =  {
        status: true,
        message: ''
    }

    if(state.date_of_birth) {
        checking = checkDateOfBirth(state, checking)
    }

    if(state.document_types) {
        checking = checkDocumentType(state, checking)
    }

    if(state.phone_number) {
        checking = checkingPhoneNumber(state, checking)
    }

    return checking;



}

export const newApplicationModule = {
    state: {
        //general
        count_visible_subjects: 1,
        leasing_application_id: null,
        sms_check: 0,
        app_number: null,
        created_at: null,
        app_date: null,
        tn_number: null,
        tn_date: null,
        delayed_loading_date: null,
        updated_at: null,
        leasing_application_status_id: null,
        leasing_application_status_name: null,
        //ChartFormation
        leasing_subjects: [{
            id: null,
            name: null,
            price: Number(100).toFixed(2)
        }],
        leasing_subject_all_price: 0,
        start_full_price: 0,
        end_full_price: 0,
        client_result_documents: [],
        sms_documents: [],
        comment: null,
        down_payment: null,
        down_payment_types: 0,
        down_payment_price: 0,
        leasing_term: null,
        calculation_options: 2,
        first_payment: 0,
        first_name: null,
        last_name: null,
        patronymic: null,
        phone_number: null,
        info_price_percentage: null,
        //CustomerAnalysis
        visual_assessment_types: null,
        personal_number: null,
        document_types: null,
        series_and_number: null,
        date_of_issue: null,
        expiration_date: null,
        who_issued_types: null,
        date_of_birth: null,
        gender_types: null,
        nationality_types: null,
        basic_income_types: null,
        basic_income_size: null,
        additional_income_types: null,
        additional_income_size: null,
        used_loans_types: null,
        monthly_payment: null,
        experience_on_last_place_types: null,
        number_of_children_under_18: null,
        criminal_liability: null,
        debt_collection: null,
        //Profile-information
        organization_scope_types: null,
        employer: null,
        position_types: null,
        experience_types: null,
        family_status_types: null,
        working_family_members: null,
        banking_accounts_types: null,
        living_condition_types: null,
        estate_property_types: null,
        education_types: null,
        other_phone_number: null,
        is_public_official_types: null,
        //DocumentPrinting
        soato_registration_types:null,
        soato_residential_types:null,
        soato_code_registration:null,
        soato_code_residential:null,
        country_registration_types: null,
        country_residential_types: null,
        area_registration: null,
        area_residential: null,
        district_registration: null,
        district_residential: null,
        village_council_registration: null,
        village_council_residential: null,
        locality_name_registration: null,
        locality_name_residential: null,
        locality_registration_types: null,
        locality_residential_types: null,
        locality_street_registration_types: null,
        locality_street_residential_types: null,
        street_registration: null,
        street_residential: null,
        index_registration: null,
        index_residential: null,
        house_registration: null,
        house_residential: null,
        building_registration: null,
        building_residential: null,
        apartment_registration: null,
        apartment_residential: null,
        residential_in_registration_types: null,
        leasing_offer_id: null,
        leasing_offer_type_id: null,
        who_created: null,
        who_took_the_job: null,
        who_took_the_job_date: null,
        who_took_documents: null,
        who_took_documents_date: null,
        reason_for_rejection_types: null,
        is_disabled: false,
        assent_date: null,
    },
    getters: {
        //general
        get_leasing_offer_id(state) {
            return state.leasing_offer_id
        },
        get_sms_check(state) {
            return state.sms_check
        },
        get_leasing_offer_type_id(state) {
            return state.leasing_offer_type_id
        },
        count_visible_subjects(state) {
            return state.count_visible_subjects
        },
        leasing_application_id(state) {
            return state.leasing_application_id
        },
        app_number(state) {
            return state.app_number
        },
        leasing_application_status_id(state) {
            return state.leasing_application_status_id
        },
        created_at(state) {
            return state.created_at
        },
        app_date(state) {
            return state.app_date
        },
        delayed_loading_date(state) {
            return state.delayed_loading_date
        },
        tn_number(state) {
            return state.tn_number
        },
        tn_date(state) {
            return state.tn_date
        },
        updated_at(state) {
            return state.updated_at
        },
        leasing_application_status_name(state) {
            return state.leasing_application_status_name
        },

        //ChartFormation
        leasing_subjects(state) {
            return state.leasing_subjects
        },
        leasing_subject_all_price(state) {
            return state.leasing_subject_all_price
        },
        start_full_price(state) {
            return state.start_full_price
        },
        end_full_price(state) {
            return state.end_full_price
        },
        info_price_percentage(state, getters) {

            //let field = null;
            //             let value = null;
            //
            //             if(this.state.fields) {
            //                 field = this.state.fields.find(item => item.name === 'down_payment_types');
            //                 value = field.data.find(item => item.id === data)
            //             }
            //
            //             console.log(field)
            //TODO: СДЕЛАТЬ ВО 2-м релизе
            if (state.down_payment_types) {
                const rateObj = {1: 0, 2: 10, 3: 20, 4: 30, 5: 40, 6: 50}
                return parseFloat((getters.leasing_subject_all_price / 100 * rateObj[state.down_payment_types])
                    .toFixed(2), 10).toLocaleString('ru')
            }
            return parseFloat((state.down_payment_price / getters.leasing_subject_all_price * 100).toFixed(2), 10)
        },
        down_payment(state) {
            return state.down_payment
        },
        down_payment_types(state) {
            return state.down_payment_types
        },
        down_payment_price(state) {
            return state.down_payment_price
        },
        leasing_term(state) {
            return state.leasing_term
        },
        calculation_options(state) {
            return state.calculation_options
        },
        first_payment(state, getters) {
            const countMountObj = {1: 12, 2: 18, 3: 24, 4: 36};
            let first_payment
            if (+state.calculation_options === 2) {
                if (state.down_payment_types) {
                    let percentageFloatString = +getters.info_price_percentage.replace(' ','').replace(',','.')
                    first_payment = parseFloat(((getters.leasing_subject_all_price - percentageFloatString)
                        / countMountObj[state.leasing_term]).toFixed(2), 10)
                    return first_payment
                }
                if (state.down_payment_price) {
                    first_payment = parseFloat(((getters.leasing_subject_all_price - +state.down_payment_price) / countMountObj[state.leasing_term])
                        .toFixed(2), 10)
                    return first_payment
                }
            }
            //TO DO....
            return 0

        },
        client_result_documents(state) {
            return state.client_result_documents
        },
        sms_documents(state) {
            return state.sms_documents
        },
        first_name(state) {
            return state.first_name
        },
        last_name(state) {
            return state.last_name
        },
        patronymic(state) {
            return state.patronymic
        },
        phone_number(state) {
            return state.phone_number
        },
        //CustomerAnalysis
        visual_assessment_types(state) {
            return state.visual_assessment_types
        },
        personal_number(state) {
            return state.personal_number
        },
        nationality_types(state) {
            return state.nationality_types
        },
        document_types(state) {
            return state.document_types
        },
        series_and_number(state) {
            return state.series_and_number
        },
        date_of_issue(state) {
            return state.date_of_issue
        },
        expiration_date(state) {
            return state.expiration_date
        },
        who_issued_types(state) {
            return state.who_issued_types
        },
        date_of_birth(state) {
            return state.date_of_birth
        },
        gender_types(state) {
            return state.gender_types
        },
        basic_income_types(state) {
            return state.basic_income_types
        },
        basic_income_size(state) {
            return state.basic_income_size
        },
        additional_income_types(state) {
            return state.additional_income_types
        },
        additional_income_size(state) {
            return state.additional_income_size
        },
        used_loans_types(state) {
            return state.used_loans_types
        },
        monthly_payment(state) {
            return state.monthly_payment
        },
        experience_on_last_place_types(state) {
            return state.experience_on_last_place_types
        },
        number_of_children_under_18(state) {
            return state.number_of_children_under_18
        },
        criminal_liability(state) {
            return state.criminal_liability
        },
        debt_collection(state) {
            return state.debt_collection
        },
        //Profile-information
        organization_scope_types(state) {
            return state.organization_scope_types
        },
        employer(state) {
            return state.employer
        },
        position_types(state) {
            return state.position_types
        },
        experience_types(state) {
            return state.experience_types
        },
        family_status_types(state) {
            return state.family_status_types
        },
        working_family_members(state) {
            return state.working_family_members
        },
        banking_accounts_types(state) {
            return state.banking_accounts_types
        },
        living_condition_types(state) {
            return state.living_condition_types
        },
        estate_property_types(state) {
            return state.estate_property_types
        },
        education_types(state) {
            return state.education_types
        },
        other_phone_number(state) {
            return state.other_phone_number
        },
        is_public_official_types(state) {
            return state.is_public_official_types
        },
        //DocumentPrinting
        country_registration_types(state) {
            return state.country_registration_types
        },
        country_residential_types(state) {
            return state.country_residential_types
        },
        area_registration(state) {
            return state.area_registration
        },
        area_residential(state) {
            return state.area_residential
        },
        district_registration(state) {
            return state.district_registration
        },
        district_residential(state) {
            return state.district_residential
        },
        village_council_registration(state) {
            return state.village_council_registration
        },
        village_council_residential(state) {
            return state.village_council_residential
        },
        locality_registration_types(state) {
            return state.locality_registration_types
        },
        locality_residential_types(state) {
            return state.locality_residential_types
        },
        locality_name_registration(state) {
            return state.locality_name_registration
        },
        locality_name_residential(state) {
            return state.locality_name_residential
        },
        locality_street_registration_types(state) {
            return state.locality_street_registration_types
        },
        locality_street_residential_types(state) {
            return state.locality_street_residential_types
        },
        street_registration(state) {
            return state.street_registration
        },
        street_residential(state) {
            return state.street_residential
        },
        index_registration(state) {
            return state.index_registration
        },
        index_residential(state) {
            return state.index_residential
        },
        house_registration(state) {
            return state.house_registration
        },
        house_residential(state) {
            return state.house_residential
        },
        building_registration(state) {
            return state.building_registration
        },
        building_residential(state) {
            return state.building_residential
        },
        apartment_registration(state) {
            return state.apartment_registration
        },
        apartment_residential(state) {
            return state.apartment_residential
        },
        residential_in_registration_types(state) {
            return state.residential_in_registration_types
        },
        comment(state) {
            return state.comment
        },
        who_created(state) {
            return state.who_created
        },
        who_took_the_job(state) {
            return state.who_took_the_job
        },
        who_took_the_job_date(state) {
            return state.who_took_the_job_date
        },
        who_took_documents(state) {
            return state.who_took_documents
        },
        who_took_documents_date(state) {
            return state.who_took_documents_date
        },
        reason_for_rejection_types(state) {
            return state.reason_for_rejection_types
        },
        soato_registration_types(state){
          return state.soato_registration_types
        },
        soato_residential_types(state){
            return state.soato_residential_types
        },
        soato_code_registration(state){
            return state.soato_code_registration
        },
        soato_code_residential(state){
            return state.soato_code_residential
        },
        is_disabled(state) {
            console.log(state.leasing_application_status_id)
            console.log(store.getters.user.delayed_loading)

            switch(true) {
                case state.leasing_application_status_id === null:
                    return false;
                case [1,6,7,9,11].includes(state.leasing_application_status_id):
                    return false;
                case [13,14,15].includes(state.leasing_application_status_id):
                    return true;
                default:
                    return store.getters.user.role.name !== 'admin';
            }
        },
        assent_date(state) {
            return state.assent_date
        },
    },
    actions: {
        resetState(state) {

            if(!state.state.leasing_application_id) {
                return;
            }

            for (let item in state.state) {
                if(item === 'is_disabled') {
                    continue;
                }
                if(item === 'comment') {
                    state.commit('comment', '');
                }
                if(item === 'client_result_documents') {
                    state.commit('set_client_result_documents', []);
                    continue;
                }
                if(item === 'sms_documents') {
                    state.commit('set_sms_documents', []);
                    continue;
                }
                if(item === 'calculation_options') {
                    state.commit('set_calculation_options', 2);
                    continue;
                }
                state.commit('set_' + item, null)
            }

            state.commit('set_count_visible_subjects', 1);
            state.commit('set_leasing_subjects', [{
                id: '',
                name: '',
                price: 100
            }]);
            state.commit('set_down_payment', 1);

        },

        saveData(state, callback = null) {
            if(!state.state.leasing_subjects.length) {
                notification.error({
                    duration: 3500,
                    title: 'Ошибка',
                    message: 'Нельзя сохранить заявку без предмета(ов) лизинга',
                });
                return false;
            }

            state.state.first_name = state.state.first_name ? state.state.first_name.toUpperCase().trim() : ''
            state.state.last_name = state.state.last_name ? state.state.last_name.toUpperCase().trim() : ''
            state.state.patronymic = state.state.patronymic ? state.state.patronymic.toUpperCase().trim() : ''
            state.state.employer = state.state.employer ? state.state.employer.toUpperCase() : ''
            state.state.area_registration = state.state.area_registration ? state.state.area_registration.toUpperCase() : ''
            state.state.area_residential = state.state.area_residential ? state.state.area_residential.toUpperCase() : ''
            state.state.district_registration = state.state.district_registration ? state.state.district_registration.toUpperCase() : ''
            state.state.district_residential = state.state.district_residential ? state.state.district_residential.toUpperCase() : ''
            state.state.village_council_registration = state.state.village_council_registration ? state.state.village_council_registration.toUpperCase() : ''
            state.state.village_council_residential = state.state.village_council_residential ? state.state.village_council_residential.toUpperCase() : ''
            state.state.locality_name_registration = state.state.locality_name_registration ? state.state.locality_name_registration.toUpperCase() : ''
            state.state.locality_name_residential = state.state.locality_name_residential ? state.state.locality_name_residential.toUpperCase() : ''
            state.state.street_registration = state.state.street_registration ? state.state.street_registration.toUpperCase() : ''
            state.state.street_residential = state.state.street_residential ? state.state.street_residential.toUpperCase() : ''
            state.state.soato_code_registration = state.state.soato_code_registration ? state.state.soato_code_registration : ''
            state.state.soato_code_residential = state.state.soato_code_residential ? state.state.soato_code_residential : ''

            const checking = checkBeforeSave(state.state)

            if(!checking.status) {
                notification.error({
                    duration: 2000,
                    title: 'Ошибка',
                    message: checking.message,
                });
                return;
            }

            Api.leasingApplication.save(state.state)
                .then(data => {
                    if (!data.errors) {

                        state.commit('updateState', data)

                        notification.success({
                            duration: 1200,
                            title: 'Успешно',
                            message: 'Заявка сохранена',
                        });

                        if(callback) {
                            callback()
                        }
                        return true;
                    }

                    notification.error({
                        duration: 800,
                        title: 'Ошибка',
                        message: 'Что-то пошло не так',
                    });
                    return false;
                })
                .finally()
        },


    },
    mutations: {
        //general
        set_count_visible_subjects(state, data) {
            state.count_visible_subjects = data
        },
        set_leasing_offer_id(state, data) {
            state.leasing_offer_id = data
        },
        set_sms_check(state, data) {
            state.sms_check = data
        },
        set_leasing_offer_type_id(state, data) {
            state.leasing_offer_type_id = data
        },
        set_leasing_application_id(state, data) {
            state.leasing_application_id = data
        },
        set_app_number(state, data) {
            state.app_number = data
        },
        set_created_at(state, data) {
            state.created_at = data
        },
        set_app_date(state, data) {
            state.app_date = data
        },
        set_delayed_loading_date(state, data) {
            state.delayed_loading_date = data
        },
        set_tn_number(state, data) {
            state.tn_number = data
        },
        set_tn_date(state, data) {
            state.tn_date = data
        },
        set_updated_at(state, data) {
            state.updated_at = data
        },
        set_leasing_application_status_id(state, data) {
            state.leasing_application_status_id = data
        },
        set_leasing_application_status_name(state, data) {
            state.leasing_application_status_name = data
        },

        //ChartFormation
        set_leasing_subjects(state, data) {
            state.leasing_subjects = data
        },
        set_leasing_subject_all_price(state, data) {
            if(state.leasing_application_status_id < 12) {
                state.start_full_price = data ? data.toFixed(2) : data;
                state.end_full_price = data ? data.toFixed(2) : data;
                state.leasing_subject_all_price = data ? data.toFixed(2) : data;
            }else {

                state.end_full_price = data ? data.toFixed(2) : data;

                if(+state.end_full_price > (+state.start_full_price + (+state.start_full_price * 0.15))) {
                    notification.error({
                        duration: 3500,
                        title: 'Ошибка',
                        message: 'Итоговая стоимость не может быть больше первоначальной более чем на 15%. Максимально допустимая сумма  - ' + (+state.start_full_price + +state.start_full_price * 0.15).toFixed(2),
                    });
                }

                state.leasing_subject_all_price = data ? data.toFixed(2) : data;
            }

        },
        set_start_full_price(state, data) {
            state.start_full_price = data ? data.toFixed(2) : data;
        },
        set_end_full_price(state, data) {
            state.end_full_price = data ? data.toFixed(2) : data;
        },
        set_down_payment(state, data) {
            if(state.down_payment !== data) {
                state.down_payment_types = 0
                state.down_payment_price = 0
            }

            state.down_payment = data
        },
        set_down_payment_types(state, data) {
            state.down_payment_types = data
        },
        set_down_payment_price(state, data) {

            let maxValue = parseFloat((state.leasing_subject_all_price / 100 * 40).toFixed(2))

            if (+data >= +maxValue) {
                state.down_payment_price = +maxValue
            } else {
                if (data) {
                    state.down_payment_price = data
                } else {
                    state.down_payment_price = 0
                }
            }
        },
        set_leasing_term(state, data) {
            state.leasing_term = data
        },
        set_calculation_options(state, data) {
            state.calculation_options = data
        },
        set_first_payment(state, data) {
            state.first_payment = data
        },
        set_first_name(state, data) {
            if (data) {
                const validPattern = /^[а-яА-ЯёЁ \-]*$/;
                if (!validPattern.test(data)) {
                    data = data.replace(/[^а-яА-ЯёЁ \-]/g, '');
                }

                state.first_name = data.substring(0, 30)
            } else {
                state.first_name = data
            }
        },
        set_last_name(state, data) {
            if (data) {
                const validPattern = /^[а-яА-ЯёЁ \-]*$/;
                if (!validPattern.test(data)) {
                    data = data.replace(/[^а-яА-ЯёЁ \-]/g, '');
                }

                state.last_name = data.substring(0, 30)
            } else {
                state.last_name = data
            }
        },
        set_patronymic(state, data) {
            if (data) {
                const validPattern = /^[а-яА-ЯёЁ \-]*$/;
                if (!validPattern.test(data)) {
                    data = data.replace(/[^а-яА-ЯёЁ \-]/g, '');
                }

                state.patronymic = data.substring(0, 30)
            } else {
                state.patronymic = data
            }

        },
        set_phone_number(state, data) {
            state.phone_number = data
        },
        set_info_price_percentage(state, data) {
            state.info_price_percentage = data
        },
        //CustomerAnalysis
        set_visual_assessment_types(state, data) {
            state.visual_assessment_types = data
        },
        set_personal_number(state, data) {
            if (data) {
                const dirtyData = data.replace(/[^0-9a-zA-Z]/g, '').substring(0, 14).toUpperCase()
                if (dirtyData.length === 8) {
                    return state.personal_number = dirtyData.slice(0, 7) + dirtyData[7].replace(/[^ABCHKEMabchkem]/g, '')
                }
                if (dirtyData.length === 12 ){
                    return state.personal_number = dirtyData.slice(0, 11) + dirtyData[11].replace(/[^a-zA-Z]/g, '')
                }
                if (dirtyData.length === 13 ){
                    return state.personal_number = dirtyData.slice(0, 12) + dirtyData[12].replace(/[^a-zA-Z]/g, '')
                }
                    state.personal_number = dirtyData
            } else {
                state.personal_number = data
            }
        },
        set_document_types(state, data) {
            state.document_types = data
        },
        set_series_and_number(state, data) {
            if (data) {
                const dirtyData = data.replace(/[^0-9a-zA-Z]/g, '').substring(0, 9).toUpperCase()
                if (dirtyData.length<=2){
                    return  state.series_and_number=dirtyData.replace(/[^a-zA-Z]/g,'')
                }else{
                    return  state.series_and_number=dirtyData.slice(0,2)+ dirtyData.slice(2,).replace(/[^0-9]/g,'')
                }
            } else {
                state.series_and_number = data
            }
        },
        set_date_of_issue(state, data) {
            state.date_of_issue = data
        },
        set_expiration_date(state, data) {
            state.expiration_date = data
        },
        set_who_issued_types(state, data) {
            state.who_issued_types = data
        },
        set_date_of_birth(state, data) {
            state.date_of_birth = data
        },
        set_gender_types(state, data) {
            state.gender_types = data
        },
        set_nationality_types(state, data) {
            if(
                (data && data !== 1 && [1,3].includes(state.document_types)) ||
                (data && data === 1 && [2,4,5].includes(state.document_types))
            ) {
                state.document_types = null
            }
            state.nationality_types = data
        },
        set_basic_income_types(state, data) {
            state.basic_income_types = data
        },
        set_basic_income_size(state, data) {
            state.basic_income_size = data
        },
        set_additional_income_types(state, data) {
            state.additional_income_types = data
        },
        set_additional_income_size(state, data) {
            state.additional_income_size = data
        },
        set_used_loans_types(state, data) {
            state.used_loans_types = data
        },
        set_monthly_payment(state, data) {
            state.monthly_payment = data
        },
        set_experience_on_last_place_types(state, data) {
            state.experience_on_last_place_types = data
        },
        set_number_of_children_under_18(state, data) {
            if (data) {
                state.number_of_children_under_18 = data.replace(/[^0-9]/g, '').substring(0, 2)
            } else {
                state.number_of_children_under_18 = data
            }
        },
        set_criminal_liability(state, data) {
            state.criminal_liability = data
        },
        set_debt_collection(state, data) {
            state.debt_collection = data
        },
        //Profile-information
        set_organization_scope_types(state, data) {
            state.organization_scope_types = data
        },
        set_employer(state, data) {
            if (data) {
                state.employer = data.substring(0, 100)
            } else {
                state.employer = data
            }

        },
        set_position_types(state, data) {
            state.position_types = data
        },
        set_experience_types(state, data) {
            state.experience_types = data
        },
        set_family_status_types(state, data) {
            state.family_status_types = data
        },
        set_working_family_members(state, data) {
            if (data) {
                state.working_family_members = data.replace(/[^0-9]/g, '').substring(0, 2)
            } else {
                state.working_family_members = data
            }
        },
        set_banking_accounts_types(state, data) {
            state.banking_accounts_types = data
        },
        set_living_condition_types(state, data) {
            state.living_condition_types = data
        },
        set_estate_property_types(state, data) {
            state.estate_property_types = data
        },
        set_education_types(state, data) {
            state.education_types = data
        },
        set_other_phone_number(state, data) {
            state.other_phone_number = data
        },
        set_is_public_official_types(state, data) {
            state.is_public_official_types = data
        },
        //DocumentPrinting
        set_soato_residential_types(state,data){
          state.soato_residential_types = data

        },
        set_soato_registration_types(state,data){
            state.soato_registration_types = data
        },
        set_soato_code_registration(state,data){
            state.soato_code_registration = data
        },
        set_soato_code_residential(state,data){
            state.soato_code_residential = data
        },
        set_country_registration_types(state, data) {
            state.country_registration_types = data
        },
        set_country_residential_types(state, data) {
            state.country_residential_types = data
        },
        set_area_registration(state, data) {
            if (data) {
                state.area_registration = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.area_registration = data
            }

        },
        set_area_residential(state, data) {
            if (data) {
                state.area_residential = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.area_residential = data
            }
        },
        set_district_registration(state, data) {
            if (data) {
                state.district_registration = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.district_registration = data
            }
        },
        set_district_residential(state, data) {
            if (data) {
                state.district_residential = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.district_residential = data
            }
        },
        set_village_council_registration(state, data) {
            if (data) {
                state.village_council_registration = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.village_council_registration = data
            }
        },
        set_village_council_residential(state, data) {
            if (data) {
                state.village_council_residential = data.replace(/[^а-яА-Я-\s]/g, '').substring(0, 40)
            } else {
                state.village_council_residential = data
            }
        },
        set_locality_registration_types(state, data) {
            state.locality_registration_types = data
        },
        set_locality_residential_types(state, data) {
            state.locality_residential_types = data
        },
        set_locality_name_registration(state, data) {
            if (data) {
                state.locality_name_registration = data.replace(/[^а-я-А-Я.\s]/g, '').substring(0, 40)
            } else {
                state.locality_name_registration = data
            }
        },
        set_locality_name_residential(state, data) {
            if (data) {
                state.locality_name_residential = data.replace(/[^а-я-А-Я.\s]/g, '').substring(0, 40)
            } else {
                state.locality_name_residential = data
            }
        },
        set_locality_street_registration_types(state, data) {
            state.locality_street_registration_types = data
        },
        set_locality_street_residential_types(state, data) {
            state.locality_street_residential_types = data
        },
        set_street_registration(state, data) {
            if (data) {
                state.street_registration = data.replace(/[^а-я-А-Я.0-9\s]/g, '').substring(0, 30)
            } else {
                state.street_registration = data
            }
        },
        set_street_residential(state, data) {
            if (data) {
                state.street_residential = data.replace(/[^а-я-А-Я.0-9\s]/g, '').substring(0, 30)
            } else {
                state.street_residential = data
            }

        },
        set_index_registration(state, data) {
            if (data) {
                state.index_registration = data.replace(/[^0-9]/g, '').substring(0, 6)
            } else {
                state.index_registration = data
            }
        },
        set_index_residential(state, data) {
            if (data) {
                state.index_residential = data.replace(/[^0-9]/g, '').substring(0, 6)
            } else {
                state.index_residential = data
            }
        },
        set_house_registration(state, data) {
            if (data) {
                state.house_registration = data.replace(/[^0-9а-я\s]/g, '').substring(0, 7)
            } else {
                state.house_registration = data
            }
        },
        set_house_residential(state, data) {
            if (data) {
                state.house_residential = data.replace(/[^0-9а-я\s]/g, '').substring(0, 7)
            } else {
                state.house_residential = data
            }
        },
        set_building_registration(state, data) {
            if (data) {
                state.building_registration = data.replace(/[^0-9а-я\s]/g, '').substring(0, 3)
            } else {
                state.building_registration = data
            }
        },
        set_building_residential(state, data) {
            if (data) {
                state.building_residential = data.replace(/[^0-9а-я\s]/g, '').substring(0, 3)
            } else {
                state.building_residential = data
            }
        },
        set_apartment_registration(state, data) {
            if (data) {
                state.apartment_registration = data.replace(/[^0-9а-я=_\-\s]/g, '').substring(0, 7)
            } else {
                state.apartment_registration = data
            }
        },
        set_apartment_residential(state, data) {
            if (data) {
                state.apartment_residential = data.replace(/[^0-9а-я=_\-\s]/g, '').substring(0, 7)
            } else {
                state.apartment_residential = data
            }
        },
        set_residential_in_registration_types(state, data) {
            state.soato_residential_types = ''
            state.area_residential = ''
            state.locality_residential_types = ''
            state.district_residential = ''
            state.village_council_residential = ''
            state.locality_name_residential = ''

            state.residential_in_registration_types = data
        },
        set_client_result_documents(state, data) {
            state.client_result_documents = data
        },
        set_sms_documents(state, data) {
            state.sms_documents = data
        },
        set_client_result_document(state, data) {


            if(typeof data['index'] !== "undefined") {
                state.client_result_documents[data.index] = data.data
            }else {
                state.client_result_documents.push({
                    name:  data.data.original_name,
                    backend_name: data.data.name,
                    ext: data.data.ext,
                    uid: data.data.id,
                    file_path: data.data.file_path,
                    updated_at: data.data.updated_at,
                    who_verified: data.data.who_verified,
                    verified: data.data.verified,
                    who_verified_id: data.data.who_verified_id,
                })
            }
        },
        set_comment(state, data) {
            state.comment = data
        },
        set_assent_date(state, data) {
            state.assent_date = data
        },
        set_who_created(state, data) {
            state.who_created = data
        },
        set_who_took_the_job(state, data) {
            state.who_took_the_job = data
        },
        set_who_took_the_job_date(state, data) {
            state.who_took_the_job_date = data
        },
        set_who_took_documents(state, data) {
            state.who_took_documents = data
        },
        set_who_took_documents_date(state, data) {
            state.who_took_documents_date = data
        },
        set_reason_for_rejection_types(state, data) {
            state.reason_for_rejection_types = data
        },
        updateState(state, data) {

            state.leasing_application_id = data.id
            state.sms_check = data.sms_check
            state.app_number = data.app_number
            state.created_at = data.created_at
            state.app_date = data.app_date
            state.delayed_loading_date = data.delayed_loading_date
            state.tn_number = data.tn_number
            state.tn_date = data.tn_date
            state.updated_at = data.updated_at
            state.leasing_application_status_id = data.status_id
            state.leasing_offer_id = data.leasing_offer_id
            state.leasing_application_status_name = data.status.name
            state.start_full_price = data.start_full_price
            state.end_full_price = data.end_full_price

            state.leasing_subjects = data.leasing_subjects;

            state.count_visible_subjects = data.leasing_subjects.length
                ? data.leasing_subjects.length
                : 1

            state.first_payment = data.first_payment ?? null
            state.down_payment = data.client_chart_informations[0].down_payment
            state.down_payment_types = prepareTypes(data.client_chart_informations[0].down_payment_types)
            state.down_payment_price = +data.client_chart_informations[0].down_payment_price
            state.leasing_term = data.client_chart_informations[0].leasing_term
            state.calculation_options = data.client_chart_informations[0].calculation_options
            state.first_name = data.client_chart_informations[0].first_name
            state.last_name = data.client_chart_informations[0].last_name
            state.patronymic = data.client_chart_informations[0].patronymic
            state.phone_number = changePhoneByMask(data.client_chart_informations[0].phone_number)
            //
            state.visual_assessment_types = prepareTypes(data.clients[0].visual_assessment_types)

            state.personal_number = data.clients[0].personal_number
            state.series_and_number = data.clients[0].series_and_number
            state.document_types = prepareTypes(data.clients[0].document_types)

            state.date_of_issue = data.clients[0].date_of_issue


            state.expiration_date = data.clients[0].expiration_date
            state.who_issued_types = prepareTypes(data.clients[0].who_issued_types)
            state.date_of_birth = data.clients[0].date_of_birth
            state.gender_types = prepareTypes(data.clients[0].gender_types)
            state.nationality_types = prepareTypes(data.clients[0].nationality_types)

            state.basic_income_types = prepareTypes(data.clients[0].basic_income_types)
            state.basic_income_size = data.clients[0].basic_income_size
            state.additional_income_types = prepareTypes(data.clients[0].additional_income_types)


            state.additional_income_size = data.clients[0].additional_income_size
            state.used_loans_types = prepareTypes(data.clients[0].used_loans_types)
            state.monthly_payment = data.clients[0].monthly_payment
            state.experience_on_last_place_types = prepareTypes(data.clients[0].experience_on_last_place_types)
            state.number_of_children_under_18 = data.clients[0].number_of_children_under_18
            state.criminal_liability = prepareTypes(data.clients[0].criminal_liability)
            state.debt_collection = prepareTypes(data.clients[0].debt_collection)
            //
            state.organization_scope_types = prepareTypes(data.client_other_informations[0].organization_scope_types)
            state.employer = data.client_other_informations[0].employer
            state.position_types = prepareTypes(data.client_other_informations[0].position_types)
            state.experience_types = prepareTypes(data.client_other_informations[0].experience_types)
            state.family_status_types = prepareTypes(data.client_other_informations[0].family_status_types)
            state.working_family_members = data.client_other_informations[0].working_family_members
            state.banking_accounts_types = prepareTypes(data.client_other_informations[0].banking_accounts_types)

            state.living_condition_types = prepareTypes(data.client_other_informations[0].living_condition_types)
            state.estate_property_types = prepareTypes(data.client_other_informations[0].estate_property_types)
            state.education_types = prepareTypes(data.client_other_informations[0].education_types)
            state.other_phone_number = changePhoneByMask(data.client_other_informations[0].other_phone_number)
            state.is_public_official_types = prepareTypes(data.client_other_informations[0].is_public_official_types)

            state.country_registration_types = prepareTypes(data.client_other_informations[0].country_registration_types)
            state.locality_registration_types = prepareTypes(data.client_other_informations[0].locality_registration_types)
            state.locality_residential_types = prepareTypes(data.client_other_informations[0].locality_residential_types)
            state.locality_street_registration_types = prepareTypes(data.client_other_informations[0].locality_street_registration_types)
            state.locality_street_residential_types = prepareTypes(data.client_other_informations[0].locality_street_residential_types)

            state.area_registration = data.client_other_informations[0].area_registration
            state.district_registration = data.client_other_informations[0].district_registration
            state.village_council_registration = data.client_other_informations[0].village_council_registration
            state.locality_name_registration = data.client_other_informations[0].locality_name_registration
            state.street_registration = data.client_other_informations[0].street_registration
            state.index_registration = data.client_other_informations[0].index_registration
            state.house_registration = data.client_other_informations[0].house_registration
            state.building_registration = data.client_other_informations[0].building_registration
            state.apartment_registration = data.client_other_informations[0].apartment_registration
            state.residential_in_registration_types = prepareTypes(data.client_other_informations[0].residential_in_registration_type_types)

            state.country_residential_types = prepareTypes(data.client_other_informations[0].country_residential_types)
            state.area_residential = data.client_other_informations[0].area_residential
            state.district_residential = data.client_other_informations[0].district_residential
            state.village_council_residential = data.client_other_informations[0].village_council_residential
            state.locality_name_residential = data.client_other_informations[0].locality_name_residential
            state.street_residential = data.client_other_informations[0].street_residential
            state.index_residential = data.client_other_informations[0].index_residential
            state.house_residential = data.client_other_informations[0].house_residential
            state.building_residential = data.client_other_informations[0].building_residential
            state.apartment_residential = data.client_other_informations[0].apartment_residential
            state.reason_for_rejection_types = prepareTypes(data.reason_for_rejection_ids)

            state.soato_registration_types = data.client_other_informations[0].soato_registration
                ? data.client_other_informations[0].soato_registration.full_name
                : null

            state.soato_residential_types = data.client_other_informations[0].soato_residential
                ? data.client_other_informations[0].soato_residential.full_name
                : null


            state.soato_code_registration = data.client_other_informations[0].soato_code_registration
            state.soato_code_residential = data.client_other_informations[0].soato_code_residential

            state.sms_documents = data.sms_documents
            state.assent_date = data.assent_date

            state.client_result_documents = data.client_result_documents.map(item => {
                return {
                    name:  item.original_name,
                    backend_name: item.name,
                    ext: item.ext,
                    uid: item.id,
                    file_path: item.file_path,
                    updated_at: item.updated_at,
                    who_verified: item.who_verified,
                    verified: item.verified,
                    who_verified_id: item.who_verified_id,
                }
            })

            state.comment = data.comment
            state.who_created = data.who_created
            state.who_took_the_job = data.who_took_the_job
            state.who_took_the_job_date = data.who_took_the_job_date
            state.who_took_documents = data.who_took_documents
            state.who_took_documents_date = data.who_took_documents_date



        },
    },

    namespaced: true,
    strict: true
}
