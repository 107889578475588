<template>
  <div class="document" v-if="renderObject">
    <h3>Адрес регистрации</h3>
    <div class="content">



      <field-group
          :fields="[
             renderObject['soato_registration_types'].field,
        ]"
      />

      <field-group
          :fields="[
             renderObject['country_registration_types'].field,
             renderObject['area_registration'].field,
             renderObject['district_registration'].field,
        ]"
      />
      <field-group
          :fields="[
             renderObject['village_council_registration'].field,
             renderObject['locality_registration_types'].field,
             renderObject['locality_name_registration'].field,
        ]"
      />
      <field-group
          :fields="[
             renderObject['locality_street_registration_types'].field,
             renderObject['street_registration'].field,
             renderObject['index_registration'].field,
        ]"
      />
      <field-group
          :fields="[
             renderObject['house_registration'].field,
             renderObject['building_registration'].field,
             renderObject['apartment_registration'].field,
        ]"
      />

      <field-group

          :fields="[
             renderObject['residential_in_registration_types'].field,

        ]"
      />
      <field-group
          v-if="this.$store.getters['newApplication/residential_in_registration_types']===2"
          :fields="[
             renderObject['soato_residential_types'].field,
        ]"
      />

      <field-group
          v-if="this.$store.getters['newApplication/residential_in_registration_types']===2"
          :fields="[
             renderObject['country_residential_types'].field,
             renderObject['area_residential'].field,
             renderObject['district_residential'].field,
        ]"
      />
      <field-group
          v-if="this.$store.getters['newApplication/residential_in_registration_types']===2"
          :fields="[
             renderObject['village_council_residential'].field,
             renderObject['locality_residential_types'].field,
             renderObject['locality_name_residential'].field,
        ]"
      />
      <field-group
          v-if="this.$store.getters['newApplication/residential_in_registration_types']===2"
          :fields="[
             renderObject['locality_street_residential_types'].field,
             renderObject['street_residential'].field,
             renderObject['index_residential'].field,
        ]"
      />

      <field-group
          v-if="this.$store.getters['newApplication/residential_in_registration_types']===2"
          :fields="[
           renderObject['house_residential'].field,
           renderObject['building_residential'].field,
           renderObject['apartment_residential'].field,
      ]"
      />

      <div class="btn-block" style="text-align: right; margin-bottom: 25px;">
        <my-button
            :disabled="saveIsDisabled"
            props-variant="fill"
            @click="saveDocsData"
        >Сохранить</my-button>
      </div>

      <el-divider></el-divider>


      <div class="button-group">
        <div v-if="showPrintDocs">
          <h6>Распечатать пакет документов</h6>
          <p>Вы можете распечатать весь пакет документов, необходимый для оформления лизинга</p>
          <my-button
              @click="renderDocs"
              v-loading="wait"
              style="position:relative;"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.5)"
              :disabled="printDisabled"
          >
            Печать документов
          </my-button>
          <div style="margin-top: 10px; width: 50%;">
            <h6>Дата договора</h6>
            <el-date-picker
                :disabled="isDisabledAppDate"
                v-model="appDate"
                type="date"
                :picker-options="{
                   disabledDate(time) {
                      return time.getTime() > Date.now();
                   },
                   firstDayOfWeek: 1
                }"
                placeholder="Выберите дату"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
            >

            </el-date-picker>
          </div>
          <div
              v-if="isShowDelayed"
              style="margin-top: 10px; width: 50%;"
          >
            <h6>Дата отгрузки</h6>

                <el-date-picker
                    :disabled="isDisabledDelayedLoadingDate"
                    v-model="delayedLoadingDate"
                    type="date"
                    :picker-options="delayedLoadingDateOptions"
                    placeholder="Выберите дату"
                    format="dd.MM.yyyy"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            <el-popconfirm
                title='Выставляя дату отгрузки вы подтверждаете, что товар доставлен лизингополучателю в данную дату'
                @confirm="setDelayedLoadingDate"
            >
              <my-button
                  :disabled="isDisabledDelayedLoadingDate"
                  slot="reference"
                  style="margin-top: 10px;"
              >Подтвердить</my-button>
            </el-popconfirm>
          </div>

        </div>

        <div>
          <h6>Отмена заявки</h6>
          <p>Вы можете отправить запрос на отмену заявки</p>
          <div>
            <field-group
                v-if="$store.state.user !== 'undefined' && $store.state.user.role.name === 'admin'"
                :fields="[
                    renderObject['reason_for_rejection_types'].field,
              ]"
                :simple="true"
            />
            <el-popconfirm
                title="Отменяя заявку, вы потеряете возможность редактировать заявку. Вы уверены?"
                @confirm="setStatus($constants.statuses.appRejection)"
            >
              <my-button
                  :style="{marginTop: $store.state.user !== 'undefined' && $store.state.user.role.name === 'admin' ? '10px' : 0}"
                  :disabled="appRejectionDisabled"
                  slot="reference">Отменить заявку
              </my-button>
            </el-popconfirm>
          </div>

        </div>
      </div>
      <div class="button-group">
        <div v-if="showAppApproved">
          <h6>Утвердить заявку</h6>
          <p>Нажимая эту кнопку, вы отправляете заявку в статус “Утверждена/загрузите документы”</p>
          <el-popconfirm
              title="Вы уверены?"
              @confirm="setStatus($constants.statuses.appApproved)"
          >
            <my-button slot="reference">Утверждена</my-button>
          </el-popconfirm>
        </div>
        <div v-else></div>
        <div v-if="showAppCanceled">
          <h6>Аннулирование заявки</h6>
          <p>Вы можете отправить запрос на аннулирование заявки</p>
          <el-popconfirm
              title="Отменяя заявку, вы потеряете возможность редактировать заявку. Вы уверены?"
              @confirm="setStatus($constants.statuses.appCanceled)"
          >
            <my-button
                slot="reference">Аннулирование заявки
            </my-button>
          </el-popconfirm>

        </div>
      </div>
      <div class="button-group">
        <div v-if="showAppInDeal">
          <h6>Сделка</h6>
          <p>Нажимая эту кнопку, вы отправляете заявку в статус “Сделка”</p>
          <el-popconfirm
              title="Вы уверены?"
              @confirm="setStatus($constants.statuses.appInDeal)"
          >
            <my-button slot="reference">Сделка</my-button>
          </el-popconfirm>
        </div>
      </div>

      <div class="button-group" v-if="showAddDocs">
        <div>
          <h6>Прикрепить отсканированные документы</h6>
          <p>Вы можете прикрепить к заявке документы, необходимые для оформления лизинга</p>

          <el-upload
              ref="upload"
              action="#"
              :http-request="addFiles"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :show-file-list="true"
              :limit="10"
              :on-exceed="handleExceed"

              :file-list="$store.getters['newApplication/client_result_documents']"
          >
<!--            :disabled="isDisabled"-->
<!--            :class="{disabled: isDisabled}"-->
            <my-button>Прикрепить документы <i class="el-icon-upload el-icon-right"></i></my-button>
            <div slot="tip" class="el-upload__tip">pdf/jpg/png</div>
            <div
                slot="file"
                slot-scope="{file}"
                :style="{backgroundColor: file.verified ? '#eee' : ''}"
                style="max-height: 25px;"
            >
              <img
                  class="el-upload-list__item-thumbnail"
                  v-if="file.status !== 'uploading' && ['picture-card', 'picture'].indexOf('') > -1"
                  :src="file.url" alt=""
              >
              <a class="el-upload-list__item-name" @click="handlePreview(file)">
                <i class="el-icon-document"></i>
                {{ file.name }}
              </a>
              <el-popconfirm
                  style="height: 0"
                  :title="'Вы действительно хотите удалить ' +  file.name + '?'"
                  @confirm="handleRemove(file)"
              >
                <i class="el-icon-close" v-if="!file.verified" slot="reference"></i>
              </el-popconfirm>
            </div>
          </el-upload>
        </div>



      </div>

      <div class="button-group">
        <div v-if="showDocumentControl">
          <h6>Отправить на контроль документов</h6>
          <p>Нажимая эту кнопку, вы отправляете заявку в статус “Контроль документов”</p>
          <el-popconfirm
              title="Вы уверены?"
              @confirm="setStatus($constants.statuses.documentControl)"
          >
            <my-button slot="reference" :disabled="isDisabledSendToDocumentControl">Отправить документы</my-button>
          </el-popconfirm>
        </div>
      </div>
      <div
          v-if="$store.getters.user.role.name === 'admin' &&$store.getters['newApplication/leasing_application_status_id'] > $constants.statuses.appCreated"
          class="button-group"
          style="width: 50%; margin-top: 10px; display: block"
      >
        <div style="width: 50%; margin-bottom: 10px;">
          <h6>Номер ТН</h6>
          <el-input
              v-model="tnNumber"
              placeholder="Введите данные"
          ></el-input>
        </div>
        <div style="width: 50%;">
          <h6>Дата ТН</h6>
          <el-date-picker
              v-model="tnDate"
              type="date"
              placeholder="Выберите дату"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{
                   firstDayOfWeek: 1
              }"
              style="width: 100%;"
          >

          </el-date-picker>
        </div>
      </div>
      <div class="comment-group" :class="{client: $store.getters.user.role.name !== 'admin'}" v-if="showForRevision">
        <h6 v-if="comment">Комментарий</h6>
        <el-input
            v-if="$store.getters.user.role.name === 'admin'"
            type="textarea"
            v-model="comment"
            style="margin-bottom: 1rem;"
        ></el-input>
        <div v-else style="line-height: normal"> {{ $store.getters['newApplication/comment'] }}</div>

        <el-popconfirm

            title="Вы уверены что хотете вернуть заявку на редактирование менеджеру?"
            @confirm="forRevision()"
        >
          <my-button
              v-if="this.$store.getters.user.role.name === 'admin'"
              :disabled="forRevisionDisabled"
              slot="reference"
          >Вернуть на доработку
          </my-button>
        </el-popconfirm>

        <el-popconfirm

            title="Вы уверены?"
            @confirm="forAppApproved()"
        >
          <my-button
              style="margin-left: 10px;"
              v-if="this.$store.getters.user.role.name === 'admin' && $store.getters['newApplication/leasing_application_status_id'] === $constants.statuses.documentControl"
              slot="reference"
          >Вернуть на загрузку
          </my-button>
        </el-popconfirm>

        <div
            v-if="$store.getters.user.role.name === 'admin' && $store.getters['newApplication/who_created']"
            class="manager-info">
          <div><h6>Партнер: </h6>
            <span>{{ $store.getters['newApplication/who_created'].trading_post.partner.name }}</span>
          </div>
          <div><h6>Торговая точка: </h6>
            <span>{{ $store.getters['newApplication/who_created'].trading_post.name }}</span></div>
          <div><h6>ФИО менеджера: </h6>
            <span>{{ $store.getters['newApplication/who_created'].full_name }}</span></div>
          <div><h6>Телефон:</h6> <a
              :href="'tel:'+$store.getters['newApplication/who_created'].phone">{{
              $store.getters['newApplication/who_created'].phone
            }}</a>
          </div>
        </div>
      </div>

      <div class="button-group" v-if="showInControl">
        <div>
          <h6>Отправить заявку на Лайт Лизинг</h6>
          <el-popconfirm
              title="Отправляя на контроль, вы потеряете возможность редактировать заявку. Вы уверены?"
              @confirm="setStatus($constants.statuses.appInProcessing)"
          >
            <my-button slot="reference">
              Отправить на контроль
            </my-button>
          </el-popconfirm>

        </div>
      </div>

      <div class="button-group" v-if="showPaidUp">
        <div>
          <h6>Отправить заявку на оплату</h6>
          <el-popconfirm
              title="Вы уверены?"
              @confirm="setStatus($constants.statuses.paidUp)"
          >
            <my-button slot="reference">
              На оплату
            </my-button>
          </el-popconfirm>

        </div>
      </div>
    </div>

    <el-dialog
        class="document-dialog"
        :visible.sync="documentDialogVisible"
        width="70%"
    >
      <div
          v-if="$store.getters.user.role.name === 'admin'"
          class="for-admin-checker"
      >
        <my-button

            :disabled="!!selectedDocument.verified"
            @click="setFileToVerified"
        >
          {{ getTextForVerifiedButton }}
        </my-button>
        <span style="font-weight: bold; padding-left: 2rem;">{{ getTextForVerified }}</span>

      </div>

      <img
          v-if="selectedDocument.ext === 'png' || selectedDocument.ext === 'jpg' ||selectedDocument.ext === 'jpeg'"
          :src="selectedDocument.path"
          alt=""
          style="max-width: 100%;"
      >
      <a
          v-if="selectedDocument.ext === 'zip' ||
                      selectedDocument.ext === 'rar' ||
                       selectedDocument.ext === 'doc' ||
                      selectedDocument.ext === 'docx' ||
                      selectedDocument.ext === 'xls' ||
                      selectedDocument.ext === 'xlsx'"
          type="primary"
          download
          :href="selectedDocument.path"
          style="
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-size: 24px;
                    "
      >
        CКАЧАТЬ
      </a>
      <embed
          v-if="selectedDocument.ext === 'pdf'"
          :src="selectedDocument.path"
          type="application/pdf"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
      ></embed>
    </el-dialog>
    <chart-payment-by-month :data="chartPaymentData" ref="chartPaymentByMonth"/>
    <chart-payment-header :data="chartPaymentData" ref="chartPaymentHeader"/>
    <chart-payment-footer :data="chartPaymentData" ref="chartPaymentFooter"/>

  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import FieldGroup from "@/components/general/fieldGroup.vue";
import EditField from "@/components/general/EditField.vue";
import {mapActions} from "vuex";
import ChartPaymentByMonth from "@/components/chartPayment/ChartPaymentByMonth.vue";
import ChartPaymentHeader from "@/components/chartPayment/ChartPaymentHeader.vue";
import ChartPaymentFooter from "@/components/chartPayment/ChartPaymentFooter.vue";
import printJs from 'print-js'
import MyInputText from "@/components/UI/MyInputText.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MySelectSoato from "@/components/UI/MySelectSoato2.vue";
import SoatoBlock from "@/components/NewApplicationView/DocumentPrintingComponents/SoatoBlock.vue";

export default {
  name: "document-printing",
  components: {
    SoatoBlock,
    MySelectSoato,
    MySelect,
    MyInputText, ChartPaymentFooter, ChartPaymentHeader, ChartPaymentByMonth, EditField, FieldGroup, MyButton},
  props: {
    propsChangeMenuState: {
      type: Function
    }
  },
  data() {
    return {
      comment: '',
      appDate: null,
      tnNumber: null,
      tnDate: null,
      delayedLoadingDate: null,
      delayedLoadingDateOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now() || time.getTime() < Date.now() -(3600 * 1000 * 24 * 3);
        // },
        // firstDayOfWeek: 1
      },
      selectedDocument: {
        path: null,
        ext: null
      },
      documentDialogVisible: false,
      savedData: false,
      loadData: false,
      approved: false,
      chartResultsByMonths: {
        by_month: [],
        general: {}
      },
      fieldsData: null,
      renderObject: null,
      files: [],
      chartPaymentData: {},
      updated_at: null,
      wait: false,
      isDisabledDelayedLoadingDate: false,
    }
  },
  mounted() {

    this.getFields(this.$constants.navigations.newApplication.sub.documentPrinting)
    this.checkUploadedFiles()

    if (this.$store.state.newApplication.updated_at && this.$store.state.newApplication.updated_at !== this.updated_at) {
      this.updated_at = this.$store.state.newApplication.updated_at;
      // this.getPaymentSchedule()
    } else {
      this.chartPaymentData = {}
    }
    if (this.$store.state.newApplication.comment) {
      this.comment = this.$store.state.newApplication.comment;
    }
    if (this.$store.state.newApplication.app_date) {
      this.appDate = this.$store.state.newApplication.app_date;
    }
    if (this.$store.state.newApplication.tn_number) {
      this.tnNumber = this.$store.state.newApplication.tn_number;
    }
    if (this.$store.state.newApplication.tn_date) {
      this.tnDate = this.$store.state.newApplication.tn_date;
    }

    this.delayedLoadingDate = this.$store.getters["newApplication/delayed_loading_date"]
    this.getIsDisabledDelayedLoadingDate()
    this.getPrepareDelayedLoadingDateOptions()
  },
  computed: {
    fullName() {
      return this.$store.getters["newApplication/first_name"] + ' ' +
          this.$store.getters["newApplication/last_name"] + ' ' +
          this.$store.getters["newApplication/patronymic"]
    },
    currentDate() {
      return this.convertDate(new Date)
    },
    isDisabled() {
      return this.$store.getters['newApplication/is_disabled']
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    },
    isDisabledSendToDocumentControl() {
      return !this.$store.getters['newApplication/client_result_documents'].length
          || !!(!this.$store.getters["newApplication/delayed_loading_date"] &&
              this.$store.getters["newApplication/who_created"] &&
              this.$store.getters["newApplication/who_created"].id === this.$store.getters.user.id &&
              this.$store.getters.user.delayed_loading)
    },
    saveIsDisabled () {
      return [
        this.$constants.statuses.documentControl,
        this.$constants.statuses.appInDeal,
        this.$constants.statuses.paidUp,
        this.$constants.statuses.appCanceled,
        this.$constants.statuses.appRejection,
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name !== 'admin'
    },
    printDisabled() {
      let createdAt = new Date(this.$store.getters["newApplication/created_at"]);
      console.log(createdAt)
      let current = new Date()
      let limitDate =  createdAt.getTime()+ 61 * 24 * 60 * 60 * 1000
      limitDate = new Date(limitDate)


      console.log('current')
      console.log(current)
      console.log('limitDate')
      console.log(limitDate)
      console.log('id_disabled')
      console.log(current > limitDate)
      console.log(
          (this.$store.getters['newApplication/leasing_application_status_id'] === 12 && current > limitDate && this.$store.getters.user.role.name !== 'admin'))


      return this.$store.getters['newApplication/leasing_application_status_id'] === 5 ||
          (this.$store.getters['newApplication/leasing_application_status_id'] === 12 && current > limitDate && this.$store.getters.user.role.name !== 'admin')
    },
    forRevisionDisabled() {
      return (this.$store.getters.user.role.name !== 'admin' ||
          !this.$store.getters['newApplication/comment'] ||
          this.$store.getters['newApplication/comment'].length < 5) ||
          (
              this.$store.getters['newApplication/leasing_application_status_id'] !== this.$constants.statuses.appInProcessing &&
              this.$store.getters['newApplication/leasing_application_status_id'] !== this.$constants.statuses.documentControl &&
              this.$store.getters['newApplication/leasing_application_status_id'] !== this.$constants.statuses.appApproved
          )
    },
    appRejectionDisabled() {
      return [
            this.$constants.statuses.paidUp,
            this.$constants.statuses.appCanceled,
            this.$constants.statuses.appRejection
          ].includes(this.$store.getters['newApplication/leasing_application_status_id'])
    },
    showAppApproved(){
      return [
        this.$constants.statuses.appInProcessing,
        this.$constants.statuses.appForRevision,
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name === 'admin'

    },
    showAppInDeal(){
      return ([this.$constants.statuses.documentControl,].includes(this.$store.getters['newApplication/leasing_application_status_id']) && this.$store.getters.user.role.name === 'admin') ||
      ([this.$constants.statuses.appApproved].includes(this.$store.getters['newApplication/leasing_application_status_id']) && this.$store.getters.user.role.name === 'admin' && this.$store.getters["newApplication/who_created"] && !!this.$store.getters["newApplication/who_created"].delayed_loading)

    },
    showAppCanceled(){
      return [
        this.$constants.statuses.appForRevision,
        this.$constants.statuses.appApproved,
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) ||
          ([
            this.$constants.statuses.appForRevision,
            this.$constants.statuses.appApproved,
            this.$constants.statuses.appInDeal,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
              this.$store.getters.user.role.name === 'admin')



    },
    showPaidUp(){
      return [
        this.$constants.statuses.appInDeal,
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name === 'admin'

    },
    showForRevision(){
      // return this.$store.getters.user.role.name === 'admin'
      return [
            this.$constants.statuses.appForRevision,
            this.$constants.statuses.appApproved,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) ||
          this.$store.getters.user.role.name === 'admin'
    },
    showAddDocs(){
      console.log(
          [
            this.$constants.statuses.appInDeal,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          !!this.$store.getters["newApplication/who_created"] &&
          this.$store.getters["newApplication/who_created"].id === this.$store.getters.user.id &&
          !!this.$store.getters.user.delayed_loading
      )
      return [
        this.$constants.statuses.appCreated,
        this.$constants.statuses.appForRevision,
        this.$constants.statuses.appApproved
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) ||
          ([
              this.$constants.statuses.appInProcessing,
              this.$constants.statuses.appForRevision,
              this.$constants.statuses.documentControl,
              this.$constants.statuses.appInDeal,
              this.$constants.statuses.appRejection,
              this.$constants.statuses.paidUp
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) && this.$store.getters.user.role.name === 'admin') ||
          ([
                this.$constants.statuses.appInDeal,
              ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
              !!this.$store.getters["newApplication/who_created"] &&
              this.$store.getters["newApplication/who_created"].id === this.$store.getters.user.id &&
              !!this.$store.getters.user.delayed_loading
          )

    },
    showDocumentControl(){
      return [
        this.$constants.statuses.appApproved
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name !== 'admin'
    },
    showPrintDocs() {
      return [
        this.$constants.statuses.appApproved,
        this.$constants.statuses.documentControl
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) || (
          [
            this.$constants.statuses.appApproved,
            this.$constants.statuses.documentControl,
            this.$constants.statuses.appInDeal
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name === 'admin'
      )

    },
    showInControl() {
      return [
        this.$constants.statuses.appCreated,
        this.$constants.statuses.appForRevision,
        // this.$constants.statuses.appApproved
      ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
      this.$store.state.user.role.name !== 'admin'


    },
    getTextForVerifiedButton() {
      if (!this.selectedDocument.verified) {
        return 'Выставить проверено и запретить удаление'
      }
      return 'Проверено'
    },
    getTextForVerified() {
      if (!this.selectedDocument.verified || !this.selectedDocument.who_verified) {
        return ''
      }
      let date = this.convertDateWithTime(this.selectedDocument.updated_at)
      return 'Проверил: ' +
          this.selectedDocument.who_verified.last_name + ' ' + this.selectedDocument.who_verified.first_name +
          ', ' + date.date + ' - ' + date.time
    },
    isDisabledAppDate() {

      // let adminIncludes = [
      //   this.$constants.statuses.appCreated,
      //   this.$constants.statuses.appInProcessing,
      //   this.$constants.statuses.appForRevision,
      //   this.$constants.statuses.paidUp,
      //   this.$constants.statuses.appCanceled,
      //   this.$constants.statuses.appRejection,
      // ].includes(this.$store.getters['newApplication/leasing_application_status_id'])
      //
      // return  (this.$store.getters.user.role.name === 'admin' && adminIncludes) ||
      //     this.$store.getters.user.role.name !== 'admin';

      return this.$store.getters.user.role.name !== 'admin';
    },
    isShowDelayed() {
      return (this.$store.getters.user.role.name !== 'admin' && this.$store.getters.user.delayed_loading && this.$store.getters["newApplication/leasing_application_status_id"] >= this.$constants.statuses.appApproved) ||
          (this.$store.getters.user.role.name === 'admin' && this.$store.getters["newApplication/who_created"] && this.$store.getters["newApplication/who_created"].delayed_loading)

    },

  },
  watch: {
    '$store.state.newApplication.updated_at': function () {
      if (this.$store.state.newApplication.updated_at && this.$store.state.newApplication.updated_at !== this.updated_at) {
        this.updated_at = this.$store.state.newApplication.updated_at;
        // this.getPaymentSchedule()
      }
    },
    '$store.state.newApplication.client_result_documents': function () {
      this.files = this.$store.state.newApplication.client_result_documents
    },
    comment() {
      this.$store.commit('newApplication/set_comment', this.comment)
    },
    appDate() {
      this.$store.commit('newApplication/set_app_date', this.appDate)
    },
    // delayedLoadingDate() {
    //   this.$store.commit('newApplication/set_delayed_loading_date', this.delayedLoadingDate)
    // },
    tnNumber() {
      this.$store.commit('newApplication/set_tn_number', this.tnNumber)
    },
    tnDate() {
      this.$store.commit('newApplication/set_tn_date', this.tnDate)
    }
  },
  methods: {
    ...mapActions({saveData: 'newApplication/saveData'}),
    getPrepareDelayedLoadingDateOptions() {
      const appDate = this.appDate
      this.delayedLoadingDateOptions =  {
        disabledDate(time) {
          return time.getTime() > Date.now() ||
              time.getTime() < Date.now() -(3600 * 1000 * 24 * 3)
              || time < new Date(appDate);
        },
        firstDayOfWeek: 1
      }
    },
    saveDocsData() {
      this.saveData()
    },
    // getPaymentSchedule() {
    //
    //   this.$api.leasingApplication.getPaymentSchedule({
    //     leasing_application_id: this.$store.getters["newApplication/leasing_application_id"]
    //   })
    //       .then(data => {
    //         this.chartPaymentData = data;
    //       })
    //       .finally(() => {
    //
    //       })
    // },
    setDelayedLoadingDate() {
      console.log(this.delayedLoadingDate)
      this.$api.leasingApplication.setDelayedLoadingDate({
        leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
        delayed_loading_date: this.delayedLoadingDate
      }).then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Дата отгрузки установлена',
              });
              this.$store.commit('newApplication/set_delayed_loading_date', this.delayedLoadingDate)
              // this.getIsDisabledDelayedLoadingDate(true)
            }
      })
    },
    getIsDisabledDelayedLoadingDate(forcibly = false) {
      this.isDisabledDelayedLoadingDate =  forcibly
          ? forcibly
          : !this.$store.getters['newApplication/app_date'] ||
          (this.$store.getters["newApplication/leasing_application_status_id"] > this.$constants.statuses.appApproved && this.$store.getters.user.role.name !== 'admin')
    },
    changeAppDate(data) {
      console.log(data)
      this.$store.commit(`newApplication/set_app_date`, data)
    },

    checkUploadedFiles() {
      if (this.$store.getters["newApplication/client_result_documents"]) {
        this.files = this.$store.getters["newApplication/client_result_documents"]
      }
    },

    handleRemove(file, fileList) {

      if([
            this.$constants.statuses.appInDeal,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          !!this.$store.getters["newApplication/who_created"] &&
          this.$store.getters["newApplication/who_created"].id === this.$store.getters.user.id &&
          !!this.$store.getters.user.delayed_loading) {
        return;
      }

      this.$api.leasingApplication.deleteFile({id: file.uid})
          .then(data => {
            if (!data.errors) {
              this.files = this.files.filter(item => item.uid !== file.uid)
              this.$store.commit('newApplication/set_client_result_documents', this.files)

              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Документ сохранен',
              });
            } else {
              return this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors.message,
              });
            }

          })


    },
    handlePreview(file) {
      this.selectedDocument = file;
      this.selectedDocument.path = this.$constants.global.base_document_url + file.file_path + file.backend_name + '.' + file.ext
      this.selectedDocument.ext = file.ext

      this.documentDialogVisible = true
    },
    handleExceed(files, fileList) {
      // this.$message.warning('Разрешено максимум ${files.length} файлов`);
      this.$message.warning(`Ограничение - 10 файлов, Вы выбрали ${files.length}., Уже добавлено -  ${this.files.length}. Разрешенный остаток - ${10 - this.files.length}`);
    },
    beforeRemove(file, fileList) {
      this.$confirm(`Вы действительно хотите удалить ${file.name} ?`)
    },

    addFiles() {

      let $file = this.$refs.upload.uploadFiles.pop()

      if (!this.$store.getters["newApplication/leasing_application_id"]) {
        this.$notify.error({
          duration: 2500,
          title: 'Ошибка',
          message: 'Перед добавление документа необходимо сохранить заявку',
        });
        return;
      }


      let formData = new FormData()
      formData.append('leasing_application_id', this.$store.getters["newApplication/leasing_application_id"])
      formData.append('file', $file.raw)
      this.$api.leasingApplication.uploadFiles(formData)
          .then(data => {
            if (!data.errors) {
              this.$store.commit('newApplication/set_client_result_document', {data: data})

              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Документ сохранен',
              });
            }
          })

    },
    uploadFiles() {

    },
    setFileToVerified() {

      this.$api.leasingApplication.setFileToVerified({
        file_id: this.selectedDocument.uid
      })
          .then(data => {
            if (!data.errors) {
              let index = this.$store.getters['newApplication/client_result_documents'].findIndex(item => item.uid === data.id)
              this.$store.commit('newApplication/set_client_result_document', {
                data: data,
                index: index
              })
              this.selectedDocument = this.$store.getters['newApplication/client_result_documents'][index]
              this.files = [];
              this.files = this.$store.getters['newApplication/client_result_documents']
              this.$refs.upload.fileList = this.files
              this.selectedDocument = this.files[index];
              this.selectedDocument.path = this.$constants.global.base_document_url + this.selectedDocument.file_path + this.selectedDocument.name + '.' + this.selectedDocument.ext

              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Документ сохранен',
              });
            }
          })
    },
    renderDocs(){


      if(!this.$store.state.newApplication.app_date) {

        this.appDate = new Date().toLocaleDateString('ru-Ru', {'year': 'numeric'})
                  + '-' + new Date().toLocaleDateString('ru-Ru', {'month': '2-digit'})
                  + '-' + new Date().toLocaleDateString('ru-Ru', {'day': '2-digit'})

        this.$store.commit('newApplication/set_app_date',  this.appDate)
      }


      if(this.$store.state.user.role.name === 'admin' &&
          this.$store.state.newApplication.app_date.split(' ')[0] !== this.$store.state.newApplication.created_at.split(' ')[0]
      ) {
        this.$confirm('Дата договора не совпадает с текущей датой. Продолжить?', 'Warning', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.saveData(this.getDocs)
        }).catch(() => {
        });
      }else {
        this.saveData(this.getDocs)
      }


    },
    getDocs() {
      this.wait = true;
      this.$api.leasingApplication.renderDocs({
            leasing_application_id: this.$store.getters["newApplication/leasing_application_id"]
          },
          {responseType: 'blob'}

      ).then(({data, headers}) => {

        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
        window.open(url, '_blank')
        console.log(navigator)
        this.getIsDisabledDelayedLoadingDate()
        //printJS({printable:url, type:'pdf'})
      }).catch(error => {
        console.error(error);
      })
          .finally(() => {
            this.wait = false;
          })
    },
    getDocumentTemplatesForPrint() {
      if (!this.$store.state.newApplication.leasing_application_id) {
        return this.$notify.error({
          duration: 2500,
          title: 'Ошибка',
          message: 'Печать документов возможна только после сохранения заявки.',
        });
      }

      this.$api.field.getDocumentTemplatesForPrint({
        leasing_application_id: this.$store.state.newApplication.leasing_application_id,
      })
          .then(data => {
            this.documentTemplates = data;

            //[this.documentTemplates[0]].forEach((item, index) => {
            this.documentTemplates.forEach((item, index) => {

              let resultDocument = this.prepareTemplate(item.text)

              var doc = window.open('/template');

              doc.onload = () => {
                doc.document.write(resultDocument)
                doc.document.body.style.width = '210mm'
                doc.document.body.style.margin = '0 auto'
                doc.document.body.style.color = '#000 !important'
              }
              doc.focus();


              // let WinPrint = window.open(
              //     '', '',
              //     'left=0,top=0,right=0,bottom=0, width=1500,height=900,toolbar=0,scrollbars=0,status=0',
              // );
              // WinPrint.document.write(resultDocument);
              // WinPrint.document.close();
              // WinPrint.focus();
              // WinPrint.print();
              // WinPrint.close();
            })
          })
    },
    prepareLeasingSubjects(dom) {

      let subjectLength = this.$store.getters["newApplication/leasing_subjects"].length
      let subjects = this.$store.getters["newApplication/leasing_subjects"]

      for (let i = 1; i <= subjectLength; i++) {
        let substrName = '{{leasing_subject' + i + '}}'
        let substrPrice = '{{leasing_subject_price' + i + '}}'
        let newStrName = subjects[i - 1].name
        let newStrPrice = subjects[i - 1].price
        dom.innerHTML = dom.innerHTML.replaceAll(substrName, newStrName)
        dom.innerHTML = dom.innerHTML.replaceAll(substrPrice, (+newStrPrice).toFixed(2))


      }

      for (let i = subjectLength + 1; i <= 10; i++) {
        let substrName = '{{leasing_subject' + i + '}}'
        let substrPrice = '{{leasing_subject_price' + i + '}}'

        let test = dom.getElementsByInnerText(substrName)

        if (dom.getElementsByInnerText(substrName).length) {
          if (dom.getElementsByInnerText(substrName).length) {
            dom.getElementsByInnerText(substrName).forEach(item => {
              item.style.display = 'none'
            })
            // dom.getElementsByInnerText(substrName)[0].style.display = 'none'
          }
          if (dom.getElementsByInnerText(substrPrice).length) {
            dom.getElementsByInnerText(substrPrice).forEach(item => {
              item.style.display = 'none'
            })
            // dom.getElementsByInnerText(substrPrice)[0].style.display = 'none'
          }
          this.hideDomElem(dom, substrName, substrPrice)
        }
      }

      return dom.innerHTML

    },
    hideDomElem(dom, substrName, substrPrice, nodeIndex = 0) {

      for (let i = 0; i < dom.getElementsByInnerText(substrName).length; i++) {

        let item = dom.getElementsByInnerText(substrName)[i]

        let nodes = [
          item.parentNode,
          item.parentNode.parentNode,
          item.parentNode.parentNode.parentNode,
          item.parentNode.parentNode.parentNode.parentNode,
          item.parentNode.parentNode.parentNode.parentNode.parentNode
        ]

        if (nodes[nodeIndex].nodeName === 'TR') {
          nodes[nodeIndex].style.display = 'none'
        } else {
          this.hideDomElem(dom, substrName, substrPrice, i + 1)
        }
      }
    },
    prepareTemplate(template) {

      let dom = document.createElement('div');
      dom.innerHTML = template;


      dom.innerHTML = this.prepareLeasingSubjects(dom)

      if (template.includes("{{partner_name}}")) {

        let data = this.$store.getters['newApplication/who_created'].trading_post ? this.$store.getters['newApplication/who_created'].trading_post.partner.name : '';
        dom.innerHTML = dom.innerHTML.replaceAll("{{partner_name}}", data === null ? '' : data)
      }

      if (template.includes("{{partner_unp}}")) {
        let data = this.$store.getters['newApplication/who_created'].trading_post ? this.$store.getters['newApplication/who_created'].trading_post.partner.unp : '';
        dom.innerHTML = dom.innerHTML.replaceAll("{{partner_unp}}", data === null ? '' : data)
      }

      if (template.includes("{{partner_address}}")) {
        let data = this.$store.getters['newApplication/who_created'].trading_post ? this.$store.getters['newApplication/who_created'].trading_post.partner.address : '';
        dom.innerHTML = dom.innerHTML.replaceAll("{{partner_address}}", data === null ? '' : data)
      }

      if (template.includes("{{trading_post_name}}")) {
        let data = this.$store.getters['newApplication/who_created'].trading_post ? this.$store.getters['newApplication/who_created'].trading_post.name : '';
        dom.innerHTML = dom.innerHTML.replaceAll("{{trading_post_name}}", data === null ? '' : data)
      }

      if (template.includes("{{partner_bank_details}}")) {
        let data = this.$store.getters['newApplication/who_created'].trading_post ? this.$store.getters['newApplication/who_created'].trading_post.partner.bank_details : '';
        dom.innerHTML = dom.innerHTML.replaceAll("{{partner_bank_details}}", data === null ? '' : data)
      }

      if (template.includes("{{first_payment_percent}}")) {
        let data = this.chartPaymentData.general.first_payment_percent * 100
        dom.innerHTML = dom.innerHTML.replaceAll("{{first_payment_percent}}", data.toFixed(2))
      }

      if (template.includes("{{app_number}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll("{{app_number}}", this.$store.getters["newApplication/app_number"])
      }

      if (template.includes("{{first_payment}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{first_payment}}",
            this.chartPaymentData.general.first_payment.toFixed(2))
      }

      if (template.includes("{{full_subject_price_with_vat}}")) {
        let data = this.chartPaymentData.general.full_leasing_payment_with_vat + this.chartPaymentData.general.first_payment
        dom.innerHTML = dom.innerHTML.replaceAll("{{full_subject_price_with_vat}}", data.toFixed(2))
      }

      if (template.includes("{{full_subject_price_with_vat_AZ}}")) {

        let data = this.chartPaymentData.general.full_principal_payment + this.chartPaymentData.general.first_payment
        data = this.$rubles.translate(data.toFixed(2))
        dom.innerHTML = dom.innerHTML.replaceAll("{{full_subject_price_with_vat_AZ}}", data)
      }

      if (template.includes("{{leasing_term}}")) {
        let data = this.chartPaymentData.general.leasing_term + ' мес.'
        dom.innerHTML = dom.innerHTML.replaceAll("{{leasing_term}}", data)
      }

      if (template.includes("{{calculate_option}}")) {
        let data = this.chartPaymentData.general.calculate_option === 1 ? 'Убывающий' : 'Аннуитет'
        dom.innerHTML = dom.innerHTML.replaceAll("{{calculate_option}}", data)
      }

      if (template.includes("{{full_principal_payment}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_principal_payment}}",
            this.chartPaymentData.general.full_principal_payment.toFixed(2)
        )
      }

      if (template.includes("{{full_contact_payment}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_contact_payment}}",
            this.chartPaymentData.general.full_contact_payment.toFixed(2)
        )
      }

      if (template.includes("{{full_leasing_compensation}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_leasing_compensation}}",
            this.chartPaymentData.general.full_leasing_compensation.toFixed(2)
        )
      }

      if (template.includes("{{full_leasing_payment_with_vat}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_leasing_payment_with_vat}}",
            this.chartPaymentData.general.full_leasing_payment_with_vat.toFixed(2)
        )
      }

      if (template.includes("{{full_leasing_payment_with_vat_AZ}}")) {
        let data = this.chartPaymentData.general.full_leasing_payment_with_vat.toFixed(2)
        data = this.$rubles.translate(data)
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_leasing_payment_with_vat_AZ}}",
            data
        )
      }

      if (template.includes("{{full_ransom_payment}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{full_ransom_payment}}",
            this.chartPaymentData.general.full_ransom_payment.toFixed(2)
        )
      }

      if (template.includes("{{leasing_subjects_count}}")) {
        dom.innerHTML = dom.innerHTML.replaceAll(
            "{{leasing_subjects_count}}",
            this.$store.getters["newApplication/leasing_subjects"].length
        )
      }

      this.$store.state.fields.forEach(field => {

        let substr = "{{" + field.name + "}}";
        let newStr = this.$store.getters["newApplication/" + field.name]
            ? this.$store.getters["newApplication/" + field.name]
            : '';

        if (field.type === 'date' && newStr) {
          newStr = this.convertDate(newStr)
          dom.innerHTML = dom.innerHTML.replaceAll(substr, newStr)
          return
        }

        if ((field.type === 'select' || field.type === 'checkbox' || field.type === 'radio' || field.type === 'radio-group') && newStr) {
          if (Array.isArray(newStr)) {
            let fieldData = field.data.filter(item => newStr.includes(item.id));
            newStr = fieldData.map(item => item.name).join()
          } else {
            newStr = field.data.find(item => item.id === +newStr);
            newStr = newStr ? newStr.name : ''
            newStr = newStr ? newStr.name : ''
          }
        }

        dom.innerHTML = dom.innerHTML.replaceAll(substr, newStr)
      })

      if (template.includes("{{leasing_subject_all_price_AZ}}")) {
        let num = this.$store.getters["newApplication/leasing_subject_all_price"]
        let numToString = this.$rubles.translate(num)
        dom.innerHTML = dom.innerHTML.replaceAll('{{leasing_subject_all_price_AZ}}', numToString)
      }

      if (template.includes("{{leasing_application_id}}")) {
        let id = this.$store.getters["newApplication/leasing_application_id"]
        dom.innerHTML = dom.innerHTML.replaceAll('{{leasing_application_id}}', id)
      }

      if (template.includes("{{created_at}}")) {
        let date = this.$store.getters["newApplication/created_at"]
        date = date ? this.convertDate(date) : '';
        dom.innerHTML = dom.innerHTML.replaceAll('{{created_at}}', date)
      }

      if (template.includes("{{chart_payment_by_month}}")) {
        let data = this.$refs.chartPaymentByMonth.$el.innerHTML
        dom.innerHTML = dom.innerHTML.replaceAll("{{chart_payment_by_month}}", data)
      }

      if (template.includes("{{chart_payment_by_month_full}}")) {
        let header = this.$refs.chartPaymentHeader.$el.innerHTML;
        let footer = this.$refs.chartPaymentFooter.$el.innerHTML;
        let data = this.$refs.chartPaymentByMonth.$el.innerHTML
        let result = header + data + footer
        dom.innerHTML = dom.innerHTML.replaceAll("{{chart_payment_by_month_full}}", result)
      }

      return dom.innerHTML;
    },
    isAN(value) {
      if (value instanceof Number)
        value = value.valueOf();
      return isFinite(value) && value === parseInt(value, 10);
    },

    applicationApproval() {
      this.loadData = true
      setTimeout(() => {
        this.loadData = false
        this.savedData = true
        this.approved = true
      }, [2000])
    },
    goNextStep() {
      this.propsChangeMenuState(this.$constants.navigations.newApplication.sub.documentPrinting)
    },
    getFields(id) {
      this.$api.fields.getFields({id: id}).then((data) => {
        this.fieldsData = data
        this.formingObjectForRendering(data)

      })
    },
    convertDate(data) {
      return new Date(data).toLocaleDateString('ru-Ru',
          {
            'month': "2-digit",
            'day': "2-digit",
            'year': 'numeric'
          })
    },
    convertDateWithTime(data) {
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
    formingObjectForRendering(data) {
      let object = {}
      data.forEach(el => {

        if (el.field['name'] in object) {
          object[el.field['name']] = el
        } else {
          object[el.field['name']] = [el][0]
        }
      })
      this.renderObject = object
    },
    setStatus(status) {
      this.$api.leasingApplication.setStatus(
          {
            id: this.$store.state.newApplication.leasing_application_id,
            status_id: status,
            reason_for_rejection_ids: this.$store.getters['newApplication/reason_for_rejection_types']
          }
      ).then(data => {

        if (!data.errors) {
          this.$store.commit('newApplication/set_leasing_application_status_id', status)
          this.getIsDisabledDelayedLoadingDate()
          return this.$notify.success({
            duration: this.$constants.global.notifyDurationError,
            title: 'Успешно',
            message: 'Заявка сохранена',
          });
        }
      })
    },
    forRevision() {

      if (!this.$store.getters['newApplication/comment'] || this.$store.getters['newApplication/comment'].length < 5) {
        return this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Заполните поле комментарий. Не менее 5-ти символов',
        });
      }
      this.$api.leasingApplication.forRevision(
          {
            id: this.$store.getters['newApplication/leasing_application_id'],
            comment: this.$store.getters['newApplication/comment']
          }
      ).then(data => {
        if (!data.errors) {
          this.$store.commit('newApplication/set_leasing_application_status_id', this.$constants.statuses.forRevision)
          return this.$notify.success({
            duration: this.$constants.global.notifyDurationError,
            title: 'Успешно',
            message: 'Заявка сохранена',
          });
        }
      })
    },
    forAppApproved() {

      if (!this.$store.getters['newApplication/comment'] || this.$store.getters['newApplication/comment'].length < 5) {
        return this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Заполните поле комментарий. Не менее 5-ти символов',
        });
      }
      this.$api.leasingApplication.forAppApproved(
          {
            id: this.$store.getters['newApplication/leasing_application_id'],
            comment: this.$store.getters['newApplication/comment']
          }
      ).then(data => {
        if (!data.errors) {
          this.$store.commit('newApplication/set_leasing_application_status_id', this.$constants.statuses.appApproved)
          return this.$notify.success({
            duration: this.$constants.global.notifyDurationError,
            title: 'Успешно',
            message: 'Заявка сохранена',
          });
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .document-dialog {
  .el-dialog {
    margin-top: 1vh !Important;
  }

  .el-dialog__body {
    height: 90vh;
    overflow-y: auto;
    text-align: center;
  }
}

.document {
  h3 {
    font-weight: 500;
  }

  .button-group {
    position: relative;
    display: grid;
    grid-template-columns: 50% 1fr;
    column-gap: 60px;
    margin-bottom: 10px;

    .disabled {
      ::v-deep.el-upload {
        button {
          background: gray;
          border-color: gray;
          color: #fff
        }
      }
    }

    ::v-deep.el-upload {
      &-list {
        &__item-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

      }
    }

    h6 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      color: #1A2122;
      margin-bottom: 10px;
    }

    .manager-info {
      margin-top: 1rem;

      > div {
        margin-bottom: .5rem;

        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    margin-top: 30px;
    margin-left: 50px;
    @media (max-width: 500px) {
      margin: 20px 0;
    }

    .item {
      margin-bottom: 25px;

      h6 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: #1A2122;
        margin-bottom: 10px;
      }
    }
  }

  .for-admin-checker {
    margin-bottom: 2rem;
  }

  ::v-deep.el-loading-spinner {
    margin-top: -8px;

    .el-icon-loading {
      position: absolute;
      color: #fff;
      font-weight: bold;
    }
  }
  .comment-group {
    position: relative;
    width: 47%;
    left: 53%;
    //transform: translateY(-223px);
    &.client {
      //transform: translateY(-110px);
    }
    h6 {
      margin-bottom: .5rem;
    }
  }


}
</style>
