import store from "../store";
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'; // Ensure this is installed
Vue.use(VueCompositionAPI); // VueCompositionAPI must be used as plugin before any function, otherwise see your console if warning/errpr
import { computed } from '@vue/composition-api'; // import computed function
import { createAcl, defineAclRules } from 'vue-simple-acl/dist/vue-simple-acl.vue2';

const user = computed(() => store.getters.user);

const rules = () => defineAclRules((setRule) => {
    setRule('menu-see-management', (user) =>  user.value.permissions.includes('menu.see_management'));
    setRule('menu-see-edit-organization', (user) =>  user.value.permissions.includes('menu.see_edit_organization'));
    setRule('menu-see-locations', (user) =>  user.value.permissions.includes('menu.see_locations'));
    setRule('menu-see-dictionaries-and-checklists', (user) =>  user.value.permissions.includes('menu.see_dictionaries_and_checklists'));
    setRule('menu-see-search', (user) =>  user.value.permissions.includes('menu.see_search'));
    setRule('menu-see-violations', (user) =>  user.value.permissions.includes('menu.see_violations'));
    setRule('menu-see-trends', (user) =>  user.value.permissions.includes('menu.see_trends'));
    setRule('menu-see-constructor', (user) =>  user.value.permissions.includes('menu.see_constructor'));
});

const checkObjectPermission = (object, permission) => {
    if( !object || !object.permissions || !object.permissions[permission] ){
        return false;
    }
    return object.permissions[permission].can;
}

const simpleAcl = createAcl({
    user,
    rules,
    // router
});

export default simpleAcl;
