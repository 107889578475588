import Vue from "vue";
import Vuex from "vuex";

import Api from '../api/base'
import ConstantsPlugin from '../plugins/constants'
import router from '@/router'

Vue.use(Vuex);
Vue.use(ConstantsPlugin);
import {newApplicationModule}  from './newApplicationModule'



export default new Vuex.Store({
    modules:{
      newApplication:newApplicationModule
    },
    state: {
        user: {
            id: null,
            permissions: [],
            role: {}
        },
        fields: null,
    },
    getters: {
        user(state) {
            return state.user
        },
        fields(state) {
            return state.fields
        }

    },
    actions: {
        fetchUser(ctx) {
            Api.user.getAuthUser()
                .then(data => {
                    ctx.commit('updateUser', data.user);
                })
        },
    },

    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
        updateFields(state, fields) {
            state.fields = fields
        }
    }

})
