
<template>
    <div class="general-info" :class="{empty: !countApps }">
      <div>
        <div>Заявок</div>
        <div>{{countApps}}</div>
      </div>
      <div>
        <div>Предметов лизинга</div>
        <div>{{countLeasingSubjects}}</div>
      </div>
      <div>
        <div>Сумма</div>
        <div>{{fullPrice}} <span>BYN</span></div>
      </div>
    </div>
</template>

<script>


export default {
  name: "general-info",
  components: {},
  props: {
    countApps: {
      type: Number,
      default: 0
    },
    countLeasingSubjects: {
      type: Number,
      default: 0
    },
    fullPrice: {
      default: 0
    }
  },
  data() {
    return {

    }
  }
}

</script>


<style scoped lang="scss">
  .general-info {
    position: relative;
    top: -2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 40%;
    gap: 5px;
    &.empty {
      top: 1rem;
    }
    > div  {
      > div {
        text-align: center;
        &:last-child {
          color: #ef3123;
          font-weight: bold;
          > span {
            color: #1A2122;
          }
        }
      }
    }
  }
</style>