<template>
  <div class="switch group">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <div class="switch">
      <p :class="{required: !!field.required}">
        <slot></slot>
      </p>
      <el-radio-group  v-model="state" size="small">
        <el-radio-button
            :disabled="isDisabled"
            v-for="datum in computedFieldData"
            :label="datum.id"
            :key="datum.id"
        >
          {{datum.name}}
        </el-radio-button>
      </el-radio-group>
    </div>
  </div>

</template>

<script>
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-input-switch",
  components: {EditField},
  data(){
    return{
      state:'',
      labels: []
    }
  },
  props:{
    field: {},
  },
  mounted() {
    this.setLabels()
    this.setDefaultState()
  },
  computed: {
    computedFieldData: {
      cache: false,
      get() {
        this.setDefaultState()
        return this.field.data
      }
    },
    isDisabled() {
        return this.$store.getters['newApplication/is_disabled'];
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    }
  },
  watch:{
    state(){
      this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
    }
  },
  methods:{
    setLabels() {
      this.labels = this.field.data.map(item => item.name)
    },
    setDefaultState(){
      if(!this.$store.state.newApplication[this.field.name]) {
        this.state = this.field.data[0]['id']
      }else {
        this.state = this.$store.state.newApplication[this.field.name]
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
}
.switch{
  margin-bottom: 15px;
  @media(max-width: 850px){
    max-width: unset;
  }
  p{
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    span{
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }
  ::v-deep{
    .el-radio-group{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 50%;
      label{
        width: 100%;
        span{
          width: 100%;
          font-size: 12px;
          padding: 13.5px 18px;
          @media(max-width: 450px){
            font-size: 10px;
            padding: 13.5px 10px;
          }
        }
      }
      .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
        background-color: #ef3123;
        color: #fff;
      }
    }
  }
}

</style>