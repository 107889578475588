

<template>
  <div>
    <h2>СМС документы</h2>
    <el-input
        v-model="search"
        @change="getAll"
        size="mini"
        placeholder="Поиск..."/>
    <el-table
        class="smsDocuments-table"
        @row-click="openDocument"
        :data="smsDocuments"
        style="width: 100%">
      <el-table-column
          width="120"
          label="Номер заявки"
          prop="app.app_number">
      </el-table-column>
      <el-table-column
          label="ФИО клиента"
          width="200"
      >
        <template slot-scope="scope">
          <span>{{scope.row.app.client_chart_informations[0].last_name}} {{scope.row.app.client_chart_informations[0].first_name}}  {{scope.row.app.client_chart_informations[0].patronymic}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Дата создания"
          width="100"
          align="center"
      >
        <template slot-scope="scope">
          <span> {{convertDate(scope.row.created_at).date}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Время создания"
          width="100"
          align="center"
      >
        <template slot-scope="scope">
          <span> {{convertDate(scope.row.created_at).time}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Кто выполнил"
          width="200"
      >
        <template slot-scope="scope">
          <span>{{scope.row.app.who_created.first_name}} {{scope.row.app.who_created.last_name}} {{scope.row.app.who_created.patronym}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Партнер"
          width="200"
      >
        <template slot-scope="scope">
          <span>{{scope.row.app.who_created.trading_post.partner.name}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Роль"
          prop="app.who_created.role.description">
      </el-table-column>


    </el-table>


    <paginator
        v-if="totalCount"
        :paginator.sync="paginator"
        @page-changed="getAll"
    ></paginator>


  </div>

</template>

<script>
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/UI/Paginator.vue";
export default {
  name: "SmsDocuments",
  components: {Paginator},
  mixins: [paginator],
  data() {
    return {
      totalCount: 0,
      smsDocuments: [],

      search: '',
    }
  },
  mounted() {
    this.paginator.page = 1
    this.paginator.perPage = 100
    this.getAll()
  },
  methods: {

    getAll(page) {
      this.$api.administration.getCompletedSmsDocuments({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        search: this.search
      })
          .then(({data, paginator}) => {

            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount

            this.smsDocuments = data
          })
    },
    openDocument(data) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: data.path,
      }).click();
    },

    convertDate(data) {
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .smsDocuments-table {
    font-size: 9px;
    .el-table__row {
      td.el-table__cell{
        font-size: 10px;
        padding: 2px 0;
      }

    }
  }
</style>