<template>
  <div class="container">
    <div class="wrapper">
      <menu-page
          :propsChangeMenuState="changeMenuState"
          :propsPageState="pageMenuState"
      />
      <div class="content">
        <chart-formation
            v-if="pageMenuState===$constants.navigations.newApplication.sub.chartFormation"
        />
        <customer-analysis
            v-if="pageMenuState===$constants.navigations.newApplication.sub.customerAnalysis"
            :propsChangeMenuState="changeMenuState"
        />
        <profile-information
            v-if="pageMenuState===$constants.navigations.newApplication.sub.profileInformation"
            :propsChangeMenuState="changeMenuState"
        />
        <document-printing
            v-if="pageMenuState===$constants.navigations.newApplication.sub.documentPrinting"
        />
        <scoring
            v-if="pageMenuState===$constants.navigations.newApplication.sub.scoring"
        />
        <application-logs
            v-if="
            pageMenuState===$constants.navigations.newApplication.sub.applicationLogs

        "/>
<!--        && $store.getters.user.role.name === 'admin'-->
<!--        <documents-->
<!--            v-if="$store.getters.user.id &&-->
<!--            $store.getters.user.role.name === 'admin' &&-->
<!--            pageMenuState === $constants.navigations.newApplication.sub.documents"-->
<!--        ></documents>-->
      </div>
    </div>

  </div>
</template>

<script>
import MySteps from "@/components/UI/MySteps.vue";
import CustomerAnalysis from "@/components/NewApplicationView/CustomerAnalysis.vue";
import MenuPage from "@/components/NewApplicationView/MenuPage.vue";
import ChartFormation from "@/components/NewApplicationView/ChartFormation.vue";
import ProfileInformation from "@/components/NewApplicationView/ProfileInformation.vue";
import DocumentPrinting from "@/components/NewApplicationView/DocumentPrinting.vue";
import Documents from "@/views/Documents.vue";
import ChartPaymentByMonth from "@/components/chartPayment/ChartPaymentByMonth.vue";
import ApplicationLogs from "@/components/NewApplicationView/ApplicationLogs.vue";
import Scoring from "@/components/NewApplicationView/Scoring/Scoring.vue";

export default {
  name: "NewApplicationView",
  components: {
    Scoring,
    ApplicationLogs,
      ChartPaymentByMonth,
      Documents, DocumentPrinting, ProfileInformation, ChartFormation, MenuPage, CustomerAnalysis, MySteps},
  data() {
    return {
      pageMenuState: this.$constants.navigations.newApplication.sub.chartFormation
    }
  },
  mounted() {

  },
  methods: {
    changeMenuState(menuId) {
      this.pageMenuState = menuId
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1360px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;

  @media(max-width: 1024px) {
    padding: 10px 15px;
  }
}

.wrapper {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 20% 1fr;
  align-items: flex-start;
  gap: 15px;
  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
  }


  .content {
    padding: 15px 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 1px 2px 4px 0px #00000040;
    @media(max-width: 550px) {
      padding: 15px;
    }
  }
}
</style>
