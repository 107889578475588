<template>
  <div>
    <div>
      <h4>Сотрудники</h4>
    </div>
    <div class="search">
      <span v-loading="renderLoading" element-loading-spinner="el-icon-loading">
            <img
                v-if="$store.state.user !== 'undefined' &&
                      ($store.state.user.role.name === 'admin' || $store.state.user.role.name === 'head_of_rp')"
                @click="renderExcel"
                src="@/assets/img/icons/icon-excel.svg"
                alt=""
            >
          </span>
      <div @keyup.enter="getEmployees">
        <el-input
            placeholder="ФИО, телефон, партнер, торговая точка"
            v-model="search"
        >
          <my-button slot="append" icon="el-icon-search" @click="getEmployees">Поиск</my-button>
        </el-input>
      </div>
    </div>
    <div class="command-buttons">
      <el-button
          class="add"
          icon="el-icon-circle-plus"
          circle
          @click="add"
      />
    </div>

    <div class="data-table">
      <el-table
          :data="employees"
          height="calc(86vh - 200px)"
          style="overflow: auto;"
          @row-click="edit"
      >
        <el-table-column
            label="№"
            prop="id"
            width="60px"
        >
        </el-table-column>
        <el-table-column
            label="ФИО"
            prop="fullname"
            sortable
        >
          <template slot-scope="scope">
            <div>{{ editFullName(scope.row) }}</div>
          </template>
        </el-table-column>

        <el-table-column
            prop="phone"
            label="Телефон"
        >
        </el-table-column>

        <el-table-column
            label="E-mail"
            prop="email"
            sortable
        >
        </el-table-column>

        <el-table-column
            label="Партнер"
            prop="trading_post.partner.name"
            sortable
        >
          <template slot-scope="scope">
            <div>{{scope.row.trading_post.partner.name}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="Торговая точка"
            prop="trading_post.name"
            sortable
        >
          <template slot-scope="scope">
            <div>{{scope.row.trading_post.name}}</div>
          </template>
        </el-table-column>

        <el-table-column
            label="Статус"
            width="100px"
            sortable
            prop="is_active"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.is_active ? 'Активен' : 'Деактивирован' }}</div>
          </template>
        </el-table-column>
      </el-table>

      <paginator
          v-if="totalCount"
          :paginator.sync="paginator"
          @page-changed="getEmployees"
      ></paginator>

    </div>

<!--    <div class="employees">-->
<!--      <div class="employees-header">-->
<!--        <div>№</div>-->
<!--        <div>ФИО</div>-->
<!--        <div>Телефон</div>-->
<!--        <div>E-mail</div>-->
<!--        <div>Партнер</div>-->
<!--        <div>Торговая точка</div>-->
<!--        <div>Статус</div>-->
<!--      </div>-->
<!--      <div class="employees-body">-->
<!--        <div v-for="(employee,index) in employees" @click="edit(employee)">-->
<!--          <div>{{ index+1 }}</div>-->
<!--&lt;!&ndash;          <div>{{ employee.id }}</div>&ndash;&gt;-->
<!--          <div>{{ editFullName(employee) }}</div>-->
<!--          <div>{{ employee.phone }}</div>-->
<!--          <div>{{ employee.email }}</div>-->
<!--          <div>{{ employee.trading_post.partner.name }}</div>-->
<!--          <div>{{ employee.trading_post.name }}</div>-->
<!--          <div>{{ employee.is_active ? 'Активен' : 'Деактивирован' }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <el-drawer
        :visible.sync="employeeDrawer"
        direction="rtl"
        :before-close="clear"
        size="30%"
    >
      <div class="drawer__title">
        <h4>Создание/Редактирование пользователя</h4>
      </div>

      <el-form
          :data="selectedEmployee"
          label-position="left"
          label-width="150px"
          size="small"
      >
        <el-form-item label="Фамилия">
          <el-input v-model="selectedEmployee.first_name"></el-input>
        </el-form-item>
        <el-form-item label="Имя">
          <el-input v-model="selectedEmployee.last_name"></el-input>
        </el-form-item>
        <el-form-item label="Отчество">
          <el-input v-model="selectedEmployee.patronym"></el-input>
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="selectedEmployee.email"></el-input>
        </el-form-item>
        <el-form-item label="Телефон">
          <!-- -->
          <el-input
              v-model="selectedEmployee.phone"
              v-mask="'+375 (##) ###-##-##'"
              :placeholder="'+375 (##) ###-##-##'"
              masked="true"
              clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="Партнер">
          <el-select filterable v-model="selectedEmployee.partner_id">
            <el-option
                v-for="partner in partners"
                :key="partner.id"
                :value="partner.id"
                :label="partner.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Торговая точка">
          <el-select
              filterable
              v-model="selectedEmployee.trading_post_id"
              :disabled="!selectedEmployee.partner_id">
            <el-option
                v-for="post in computedTradingPosts"
                :key="post.id"
                :value="post.id"
                :label="post.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Роль">
          <el-select filterable v-model="selectedEmployee.role.name">
            <el-option
                v-for="role in roles"
                :key="role.name"
                :value="role.name"
                :label="role.description"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Логин">
          <el-input v-model="selectedEmployee.login"></el-input>
        </el-form-item>
        <el-form-item
            v-if="!selectedEmployee.id"
            label="Пароль"
        >
          <el-input type="password" v-model="selectedEmployee.password"></el-input>
        </el-form-item>
        <el-form-item
            v-if="!selectedEmployee.id"
            label="Подтвержденеие пароля"
        >
          <el-input type="password" v-model="selectedEmployee.password_confirm"></el-input>
        </el-form-item>
        <el-form-item label="Активен">
          <el-switch
              v-model="selectedEmployee.is_active"
              active-color="#ef3123"
              inactive-color="#eeeeee"
              :active-value="1"
              :inactive-value="0"
          >
          </el-switch>

        </el-form-item>
        <el-form-item label="Примечание">
          <el-input type="textarea" v-model="selectedEmployee.notice"></el-input>
        </el-form-item>

        <el-form-item label="Изменение пароля" v-if="selectedEmployee.id">

          <my-button
              :class="'change_password'"
              style="margin-top: -5px;"
              props-variant="fill"
              @click="openChangePasswordDialog"
          >
            Изменить пароль
          </my-button>
          <!--                    <my-button-->
          <!--                        style="margin-top: -5px; margin-left: 1rem"-->
          <!--                        props-variant="fill"-->
          <!--                        @click="openResetPasswordDialog"-->
          <!--                    >-->
          <!--                        Восстановить пароль-->
          <!--                    </my-button>-->
        </el-form-item>

        <div class="controls__wrapper">
          <div v-if="selectedEmployee.role.name==='supervisor'" class="controls-api">
            <div class="api-row">
              <label for="switch-api">
                <p>Получить ключ для интергации</p>
                <el-switch
                    id="switch-api"
                    v-model="apiStateSwitch"
                    @change="changeActivationIntegrationToken"
                    active-color="#ef3123"
                    inactive-color="#eeeeee"
                    :active-value="1"
                    :inactive-value="0"
                    :width="35"
                >
                </el-switch>
              </label>
            </div>
            <div :class="apiStateSwitch?'api-row':'api-row disabled'">
              <el-input
                  size="small"
                  :readonly="true"
                  placeholder="Ключ доступа"
                  v-model="apiKey">
              </el-input>
              <my-button
                  props-variant="fill"
                  :class="'generate__key'"
                  @click="generateApiKey"
              >
                Сгенерировать
              </my-button>
            </div>
          </div>
          <div class="controls">
            <my-button :class="'btns-custom'" props-variant="empty" @click="clear">Отмена</my-button>
            <my-button :class="'btns-custom'" props-variant="fill" @click="save">Сохранить</my-button>
          </div>
        </div>
      </el-form>
    </el-drawer>
    <el-dialog
        :visible.sync="changePasswordDialog"
        width="35%"
    >
      <el-form
          :data="changePassword"
          label-position="left"
          label-width="200px"
          size="small"
      >
        <el-form-item label="Новый пароль">
          <el-input type="Новый пароль" v-model="changePassword.password"></el-input>
        </el-form-item>
        <el-form-item label="Подтверждение пароля">
          <el-input type="Новый пароль" v-model="changePassword.password_confirm"></el-input>
        </el-form-item>
      </el-form>
      <div class="controls">
        <my-button props-variant="empty" @click="()=>changePasswordDialog=false">Отмена</my-button>
        <my-button props-variant="fill" @click="saveNewPassword">Сохранить</my-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="resetPasswordDialog"
        width="60%"
    >
      <el-form
          :data="resetPassword"
          label-position="left"
          label-width="220px"
          size="small"
      >
        <el-form-item label="Почта для восстановления">
          <el-input v-model="resetPassword.email"></el-input>
        </el-form-item>
      </el-form>
      <div class="controls">
        <my-button props-variant="empty" @click="clear">Отмена</my-button>
        <my-button props-variant="fill" @click="save">Отправить</my-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import MyButton from "@/components/UI/MyButton.vue";
import Paginator from "@/components/UI/Paginator.vue";
import {paginator} from "@/mixins/paginator";

export default {
  name: "employees",
  directives: {mask},
  components: {Paginator, MyButton},
  mixins: [paginator],
  data() {
    return {
      exportSetting: false,
      renderLoading: false,
      search: null,
      totalCount: 0,

      serviceNameMask: {
        mask: "A.##",
        tokens: {
          "#": {pattern: /[0-9]/},
          // A: {pattern: /[a-dA-D]/, transform: v => v.toLocaleUpperCase()}
        }
      },
      resetPassword: {
        email: null
      },
      changePassword: {
        current: null,
        password: null,
        password_confirm: null,
      },
      employees: [],
      employeeDrawer: false,
      changePasswordDialog: false,
      resetPasswordDialog: false,
      partners: [],
      tradingPosts: [],
      roles: [],
      selectedEmployee: {
        id: null,
        first_name: null,
        last_name: null,
        patronym: null,
        email: null,
        login: null,
        trading_post_id: null,
        partner_id: null,
        role: {
          name: null
        },
        is_active: 1,
        notice: null,
      },
      apiStateSwitch: null,
      apiKey: null
    }
  },
  mounted() {
    this.getPartners()
    this.getTradingPosts()
    this.getEmployees();
    this.getRoles();
  },
  computed: {
    computedTradingPosts() {
      return this.tradingPosts.filter(post => post.partner_id === this.selectedEmployee.partner_id)
    }
  },
  methods: {
    renderExcel() {
      this.$api.administration.renderExcel({
        search: this.search
      },
          {responseType: 'blob'}
      )
          .then(data => {
            const date = new Date();

            const url = URL.createObjectURL(new Blob([data.data], {
              type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'employees-' + date.getDate()+ '-'+ (+date.getMonth() + 1) +'-' + date.getFullYear())
            document.body.appendChild(link)
            link.click()
          })
          .finally(() => {
            this.renderLoading = false
          })

    },
    editFullName(data){
      if (data){
        let dataSplit = data.full_name.split(' ')
        if (dataSplit.length === 3){
          return dataSplit[1]+' '+ dataSplit[0]+' '+dataSplit[2]
        }
        if (dataSplit.length === 2){
          return dataSplit[1]+' '+ dataSplit[0]
        }
        if (dataSplit.length === 1){
          return dataSplit[1]
        }
        return 'Не определено'
      }
    },
    generateApiKey() {

      this.$api.user.generationIntegrationToken({
        user_id: this.selectedEmployee.id
      })
          .then(data => {
            this.apiKey = data;
          })
    },
    changeActivationIntegrationToken() {

        this.$api.user.changeActivationIntegrationToken({
          user_id: this.selectedEmployee.id,
          is_active: this.apiStateSwitch
        })
            .then(data => {
              if (!data.errors) {
                this.$notify.success('Успешно')
              }
            })


    },
    getEmployees() {
      this.$api.administration.getEmployees({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        search: this.search,
      })
          .then(({data, paginator}) => {
            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount
            this.employees = data.sort((a, b) => a.first_name.localeCompare(b.first_name));
          })
    },
    getPartners() {
      this.$api.administration.getPartners()
          .then(data => {
            this.partners = data;
          })
    },
    getTradingPosts() {
      this.$api.administration.getTradingPosts()
          .then(data => {
            this.tradingPosts = data;
          })
    },
    getRoles() {
      this.$api.administration.getRoles()
          .then(data => {
            this.roles = data;
          })
    },
    openChangePasswordDialog() {
      this.changePasswordDialog = true;
    },
    openResetPasswordDialog() {
      this.resetPasswordDialog = true;
    },
    add() {
      this.employeeDrawer = true
    },
    edit(employee) {
      this.selectedEmployee = employee;
      this.selectedEmployee.partner_id = employee.trading_post.partner.id;
      this.apiStateSwitch = this.selectedEmployee.integration_token ? this.selectedEmployee.integration_token.is_active : 0;
      this.apiKey = this.selectedEmployee.integration_token ? this.selectedEmployee.integration_token.token : null;
      this.employeeDrawer = true
    },
    save() {
      if (!this.selectedEmployee.trading_post_id) {
        return this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Выберите торговую точку',
        })
      }
      this.$api.administration.saveEmployee(this.selectedEmployee)
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });

              this.selectedEmployee = data
              this.apiStateSwitch = data.integration_token ? data.integration_token.is_active : 0;
              this.apiKey = data.integration_token ? data.integration_token.token : null;
              this.employeeDrawer = false;
            }
            this.getEmployees();
          })
    },
    saveNewPassword() {

      this.$api.administration.saveNewPassword( {
        id: this.selectedEmployee.id,
        password: this.changePassword.password,
        password_confirm: this.changePassword.password_confirm
      })
          .then(data => {
            if (data.errors) {
              return this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: 'пароли не совпадают',
              });
            }

            this.$notify.success({
              duration: this.$constants.global.notifyDurationError,
              title: 'Успешно',
              message: 'Данные сохранены',
            });

            this.changePassword.password = null;
            this.changePassword.password_confirm = null;
            this.changePasswordDialog = false;

          })


    },
    clear() {
      this.selectedEmployee = {
        first_name: null,
        last_name: null,
        patronym: null,
        email: null,
        login: null,
        trading_post_id: null,
        partner_id: null,
        role: {
          name: null
        },
        password: null,
        password_confirm: null,
        is_active: 1,
        notice: null,
      }
      this.employeeDrawer = false;
    }
  },
  watch: {
    apiStateSwitch() {
      if (!this.apiStateSwitch) {
        // this.apiKey = null
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.employees {
  &-header, &-body > div {
    display: grid;
    grid-template-columns: 0.5fr 3.7fr 2.1fr 3fr 3fr 3fr 1.5fr;
    padding: .3rem 1rem;
    gap: 20px;
    font-size: 10px;
  }

  &-header {
    margin-top: 1rem;
  }

  &-body {
    > div {
      cursor: pointer;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-child(2n - 1) {
        background: #f5f5f5;
      }
    }
  }
}

::v-deep .el-drawer {
  .el-form {
    padding: 1rem 2rem 1rem 2rem;

    .el-select {
      width: 100%;
    }

  }

  .drawer__title {
    position: absolute;
    top: 2%;
    padding: 0 1rem 0 4.7rem;
    color: #ef3123;

    h4 {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

}

.controls-api {
  border-top: solid 1px #DCDFE6;
  border-bottom: solid 1px #DCDFE6;
  padding: 10px 0;
  margin-bottom: 20px;

  .api-row {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;

      .generate__key {
        background: gray;
        border: gray;
      }
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      user-select: none;

      p {
        order: 1;
        font-size: 14px;
        color: #1A2122;

      }
    }

    .generate__key {
      padding: 0 13px;
      min-height: 32px;
      font-weight: 500;
      font-size: 13px;
      min-width: 140px;
    }


  }

}

.btns-custom {
  padding: 0 13px;
  min-height: 32px;
  font-weight: 500;
  font-size: 13px;
}


.controls {
  text-align: right;

  > button {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.change_password {
  padding: 0 13px;
  min-height: 30px;
  font-weight: 500;
  font-size: 13px;
}
.data-table {
  ::v-deep .el-table {
    &__cell {
      padding: 5px 0;
      font-size: 10px;
    }
  }
}
.search {
  display: grid;
  grid-template-columns: 50px 1fr;
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}

</style>