<template>
    <div
            :style="{display: show ? 'block' : 'none'}"
    >
        <div style="width:210mm;" v-if="Object.keys(data).length !== 0">
            <div>Стороны</div>
            <div style="display: grid; grid-template-columns: 1fr 1fr">
                <div style="display: flex">
                    <div>
                        <div>Лизингодатель:</div>
                        <div style="color: cornflowerblue">ООО "Лайт Лизинг"</div>
                        <img
                            :src="$constants.global.base_document_url + '/images/fixemile.png'"
                            alt=""
                            style="max-width: 65%; position: relative; left: -10px"
                        >
                        <div>{{ currentDate }}</div>
                    </div>
                    <div>

                    </div>

                </div>
                <div style="text-align: right">
                    <div>Лизингополучатель:</div>
                    <div><span>&#10003;_______</span>{{ fullName }}</div>
                    <div>{{ currentDate }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "chart-payment-footer",
    props: {
        data: {},
        show: {
            Type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {
        fullName() {
            return this.$store.getters["newApplication/last_name"] + ' ' +
                this.$store.getters["newApplication/first_name"] + ' ' +
                this.$store.getters["newApplication/patronymic"]
        },
        currentDate() {
            return this.convertDate(new Date)
        }
    },
    methods: {
        convertDate(data) {
            return new Date(data).toLocaleDateString('ru-Ru',
                {
                    'month': "2-digit",
                    'day': "2-digit",
                    'year': 'numeric'
                })
        },
    }
}
</script>

<style scoped>

</style>