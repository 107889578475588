import Vue from "vue";

const base_document_url = process.env.NODE_ENV  === 'production'
    ? window.location.host === 'agent.lls.by'
        ? 'https://agent.api.lls.by'
        : 'https://devagent.api.lls.by'
    : 'http://leasing-back.local';

const Constants = {
    global: {
        notifyDurationSuccess: 800,
        notifyDurationError: 1200,
        base_document_url: base_document_url
    },
    offers: {
        leasingStandart: 1,
        installment: 2,
        hybrid: 3,
        postponement: 4,

    },
    documentStatuses: {
        trading_post: 1,
        router: 2,
        paid_without_refinement: 3,
        paid_with_refinement: 4,
        refinement: 5,
        archive: 6,
        withdrawn: 7,
    },
    statuses: {
        created:   1,
        inControl: 2,
        approved:  3,
        rejected:  4,
        canceled:  5,
        distribution:  6,
        forRevision:  7,

        appDenied:  8,

        appCreated:  9,
        appInProcessing:  10,
        appForRevision:  11,
        appApproved:  12,
        documentControl:  13,
        appInDeal:  14,
        paidUp: 15,
        appCanceled: 16,
        appRejection: 17,
    },
    navigations: {
        general: {
            id: 1,
            sub: {}
        },
        newApplication: {
            id:2,
            sub:{
                chartFormation:1,
                customerAnalysis:2,
                profileInformation:3,
                documentPrinting:4,
                documents:5,
                applicationLogs:6,
                scoring:7,
            }
        },
        listOfApplications: {
            id:3,
            sub:{}
        },
        help: {
            id:4,
            sub:{}
        },
        profile: {
            id:5,
            sub:{}
        }
    },

    rbac: {
        roles: {
            ADMIN: 'admin',
            USER: 'user',
            MANAGER: 'manager'

        }
    },

}

export default {
    install(Vue) {
        Vue.prototype.$constants = Constants;
    }
}
