<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/img/logo-black.png" alt="logo">
          </router-link>
        </div>
        <div class="mobile-wrapper">
          <div class="mobile-profile">
            <div class="el-dropdown-link">
              <el-dropdown :hide-on-click="true">
                <span class="el-dropdown-link">
                 <i class="el-icon-user-solid"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item disabled>{{ $store.state.user.login }}</el-dropdown-item>
                  <el-dropdown-item disabled>Профиль</el-dropdown-item>
                  <el-dropdown-item divided>
                    <div @click="logout(false)">Выйти</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="separator"></div>
          <div @click="closeOpenMobileMenuDrawer" class="burger">
            <span></span>
          </div>
        </div>
        <div class="wrapper">
          <nav class="menu">
            <ul>
              <li @click="getActualMenu" data-menu="1" :class="activeMenu===1 ? 'active':''">
                <router-link to="/">Главная</router-link>
              </li>
              <li @click="getActualMenu" data-menu="2" :class="activeMenu===2 ? 'active':''">
                <router-link to="/new-applications">Новая заявка</router-link>
              </li>
              <li @click="getActualMenu" data-menu="3" :class="activeMenu===3 ? 'active':''">
                <router-link to="/list-of-applications">Список заявок</router-link>
              </li>
              <li @click="getActualMenu" data-menu="6" :class="activeMenu===6 ? 'active':''">
                <router-link to="/list-of-applications-archive">Архив заявок</router-link>
              </li>
              <!--              <li @click="getActualMenu" data-menu="4" :class="activeMenu===4 ? 'active':''">-->
              <!--                <router-link to="/help" >Справка</router-link>-->
              <!--              </li>-->
              <li @click="getActualMenu" data-menu="4" :class="activeMenu===4 ? 'active':''">
                <router-link to="/calculator">Калькулятор</router-link>
              </li>
              <li
                  v-if="$store.getters.user.id && $store.getters.user.role.name === 'admin'"
                  @click="getActualMenu" data-menu="5" :class="activeMenu===5 ? 'active':''">
                <router-link to="/administration">Администрирование</router-link>
              </li>
              <li
                  v-if="showDocumentStatuses"
                  @click="getActualMenu" data-menu="7" :class="activeMenu===7 ? 'active':''">
                <router-link to="/document-statuses">Статус документов</router-link>
              </li>
            </ul>
          </nav>
          <div class="profile">
            <i class="el-icon-user-solid"></i>
            <div class="el-dropdown-link">
              <el-dropdown :hide-on-click="true">
                <span class="el-dropdown-link">
                  Профиль<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item disabled>{{ $store.state.user.login }}</el-dropdown-item>
                  <el-dropdown-item disabled>Профиль</el-dropdown-item>
                  <el-dropdown-item divided>
                    <div @click="logout(false)">Выйти</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <el-drawer
          title="I am the title"
          :visible.sync="mobileMenuState"
          :size="setSizeMobileDrawer()"
          :direction="'ltr'"
          :with-header="false">
        <mobile-header-menu :propsGetActualMenu="getActualMenu" :propsActiveMenu="activeMenu"
                            :closeProps="closeOpenMobileMenuDrawer"/>
      </el-drawer>
    </div>
    <main class="main">
      <router-view></router-view>
    </main>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MobileHeaderMenu from "@/layouts/header/MobileHeaderMenu.vue";

export default {
  name: "default-layout",
  components: {MobileHeaderMenu},
  data() {
    return {
      mobileMenuState: false,
      activeMenu: 1
    }
  },
  created() {
    this.$store.dispatch('fetchUser');
  },
  mounted() {
    this.setSizeMobileDrawer()
    this.getAllFields()
  },
  computed: {
    ...mapGetters(['user']),

    showDocumentStatuses() {
      const roles = ['admin', 'head_of_rp', 'general_rp', 'general_router']
      return !!(roles.includes(this.$store.getters.user.role.name) && this.$store.getters.user.id)
    },
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  methods: {
    ...mapActions({
      resetState: 'newApplication/resetState', // map `this.resetState()` to `this.$store.dispatch('resetState')`
    }),
    getAllFields() {
      if (!this.$store.state.fields) {
        this.$api.field.getAll()
            .then(data => {
              this.$store.commit('updateFields', data)
            })
      }
    },
    resetLeasingApp() {
      this.resetState()
    },
    logout(all_devices = false) {

      this.$api.user.logout({
        user_id: this.$store.state.user.id,
        refresh: localStorage.getItem('refresh_token'),
        all_devices: all_devices ? 1 : 0
      })
          .then(data => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            this.$router.go();
          })

    },
    setSizeMobileDrawer() {
      if (window.innerWidth > 520) {
        return '50%'
      }
      return '100%'
    },
    closeOpenMobileMenuDrawer() {
      this.mobileMenuState = !this.mobileMenuState
    },
    getActualMenu(event) {
      this.activeMenu = +event.currentTarget.attributes['data-menu'].value
      this.resetLeasingApp()
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";


.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    img {
      max-width: 140px;
      width: 100%;
    }
  }

  .mobile-wrapper {
    display: none;
    align-items: center;
    gap: 40px;
    position: relative;
    @media(max-width: 1160px) {
      display: flex;
    }

    i {
      font-size: 22px;
    }

    .burger {
      display: none;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 2px;
        background: #1A2122;

        &:before, &:after {
          position: absolute;
          content: '';
          width: 30px;
          height: 2px;
          background: #1A2122;
        }

        &:before {
          top: -7px;
        }

        &:after {
          top: 7px;
        }
      }

      @media (max-width: 1160px) {
        display: grid;

      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 1160px) {
      display: none;
    }

    .menu {
      flex-basis: 100%;
      margin-right: 50px;

      ul {
        height: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 10px;
        box-sizing: border-box;

        li {
          &.active {
            a {
              color: #ef3123;
            }
          }

          a {
            padding: 10px 5px;
            color: #1A2122;
            font-weight: normal;

          }
        }
      }
    }

    .profile {
      border-left: solid 1px #1A2122;
      padding: 10px 20px 10px 50px;
      display: flex;
      align-items: center;
      gap: 30px;

      i {
        font-size: 20px;
        line-height: 1;
      }

      div {
        cursor: pointer;
      }
    }
  }

}

.el-dropdown-link {
  display: flex;
}

.separator {
  left: 45%;
  height: 100%;
  width: 1px;
  background: #1A2122;
  position: absolute;

}

.main {
  height: calc(100vh - 71px);
  background: #f5f5f57a;
  overflow-y: auto;
}
</style>
