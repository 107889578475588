<template>
  <div :class="'select' + ' ' + propsType" class="group">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p :class="{required: !!field.required}">
      <slot></slot>
    </p>
    <!--  <el-select-->
    <!--      :class="{warning: isWarning}"-->
    <!--      v-model="state"-->
    <!--      :name="field.name"-->
    <!--      :multiple="isMultiple"-->
    <!--      :disabled="isDisabled"-->
    <!--      :required="isMultiple"-->
    <!--      filterable-->
    <!--      placeholder="Выберите из списка"-->
    <!--  >-->
    <el-select
        :class="{warning: isWarning}"
        :value="this.$store.getters['newApplication/'+field.name]"
        @change="setState"
        :name="field.name"
        :multiple="isMultiple"
        :disabled="isDisabled"
        :required="isMultiple"
        filterable
        placeholder="Выберите из списка"
    >
      <el-option
          v-for="item in computedFieldData"
          :key="item.id"
          :disabled="isDisabledOption(item)"
          :label="item.name"
          :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-select",
  components: {EditField},
  props: {
    propsType: {
      type: String
    },
    propsOptions: {
      type: Array
    },
    field: {},
  },
  data() {
    return {
      state: null,
      isWarning: {}
    }
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  watch: {
    state() {
      this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
      this.isWarningObject()
    }
  },
  computed: {
    computedFieldData: {
      cache: false,
      get() {
        if (this.field.name === 'who_issued_types' && this.$store.getters['newApplication/document_types']) {
          let paperDocs = ['03', '06']
          let electronDocs = ['15', '16', '17']
          let documentTypesField = this.$store.getters['fields'].find(item => item.name === 'document_types')

          let selectedDocType = documentTypesField.data.find(item => item.id === this.$store.getters['newApplication/document_types'])

          if (paperDocs.includes(selectedDocType.code)) {
            return this.field.data.filter(item => item.document_type_attribute === "0")
          }

          return this.field.data.filter(item => item.document_type_attribute === "1")

        }
        return this.field.data
      },
    },
    isMultiple() {
      return !!this.field.multiple
    },
    isRequired() {
      return !!this.field.required
    },
    isDisabled() {
      if (this.field.name === 'locality_registration_types' && this.$store.getters['newApplication/soato_registration_types']) {
        return true
      }

      if (this.field.name === 'locality_residential_types' && this.$store.getters['newApplication/soato_residential_types']) {
        return true
      }

      if (this.field.name === 'country_registration_types' && this.$store.getters['newApplication/soato_registration_types']) {
        return true
      }

      if (this.field.name === 'country_residential_types' && this.$store.getters['newApplication/soato_residential_types']) {
        return true
      }

      return this.$store.getters['newApplication/is_disabled'] ||
          (this.field.name === 'who_issued_types' && !this.$store.getters['newApplication/document_types'])
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    }
  },
  methods: {
    // setState() {
    //   this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
    // },
    setState(e) {
      this.$store.commit(`newApplication/set_${this.field.name}`, e)
    },
    setDefaultState() {
      if (!this.$store.state.newApplication[this.field.name]) {
        if (this.isMultiple) {
          this.state = [];
        } else {
          this.state = ''
        }
      } else {
        this.state = this.$store.state.newApplication[this.field.name]
      }
    },
    isWarningObject() {

      let bool = this.$store.getters[`newApplication/${this.field.name}`]
      let obj = typeof this.$store.getters[`newApplication/${this.field.name}`]
      if (obj === 'object' && bool !== null) {
        bool = bool.length
      }
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !bool
      )
    },
    isDisabledOption(option) {

      // if(this.field.name === 'nationality_types' && this.$store.getters[`newApplication/document_types`] && [1,3].includes( this.$store.getters[`newApplication/document_types`])) {
      //   return option.code !== '112'
      // }
      //
      // if(this.field.name === 'nationality_types' && this.$store.getters[`newApplication/document_types`] && [2,4,5].includes( this.$store.getters[`newApplication/document_types`])) {
      //   return option.code === '112'
      // }

      if(this.field.name === 'document_types' && this.$store.getters[`newApplication/nationality_types`] && this.$store.getters[`newApplication/nationality_types`] === 1) {
        return ![1,3].includes(option.id)
      }

      if(this.field.name === 'document_types' && this.$store.getters[`newApplication/nationality_types`] && this.$store.getters[`newApplication/nationality_types`] !== 1) {
        return ![2,4,5].includes(option.id)
      }

      return false;
    }

  }
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
}

.select {
  max-width: 315px;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  &.small {
    p {
      font-size: 11px;
    }
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;

      .el-input__inner {
        font-size: 12px;
      }

      &:hover {
        .el-input__inner {
          border-color: #C0C4CC;
          width: 100%;
          font-size: 12px;
        }

      }

      .el-tag.el-tag--info {
        color: #fff;
        background-color: #ef3123;

        .el-tag__close {
          color: #fff;
          background-color: #ef3123;
        }
      }

      &.warning {
        background: #fff2c5 !important;

        .el-input {
          input {
            background: #fff2c5 !important;
          }
        }
      }
    }
  }

}
</style>