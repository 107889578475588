<template>
  <div class="wrapper">

    <div :class="'select' + ' ' + propsType" class="group">
      <div class="select-item">
        <edit-field
            v-if="$store.getters.user.role.name === 'admin'"
            :field="field"
        />
        <p :class="{required: !!field.required}">
          <slot></slot>
        </p>
        <el-select
            :class="{warning: isWarning}"
            v-model="state"
            :name="field.name"
            :multiple="isMultiple"
            :disabled="isDisabled"
            :required="isMultiple"
            filterable
            remote
            reserve-keyword
            clearable
            :loading="loading"
            :remote-method="remoteMethod"
            placeholder="Начните вводить населенный пункт"
        >
          <el-option
              v-for="item in options"
              :key="item.code"
              :label="item.full_name"
              :value="item.full_name">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="code"
         v-if="$store.getters.user.role.name === 'admin'"
    >
      <p>Код СОАТО</p>
      <el-input
          v-if="field.name === 'soato_registration_types'"
          class="code-input"
          placeholder="Код"
          :value="this.$store.getters['newApplication/soato_code_registration']"

      />
      <el-input
          v-if="field.name === 'soato_residential_types'"
          class="code-input"
          placeholder="Код"
          :value="this.$store.getters['newApplication/soato_code_residential']"

      />
    </div>
  </div>
</template>

<script>
import EditField from "@/components/general/EditField.vue";
import logs from "@/components/administration/Logs.vue";
import field from "@/api/field";

export default {
  name: "my-select-soato",
  components: {EditField},
  props: {
    propsType: {
      type: String
    },
    propsOptions: {
      type: Array
    },
    field: {},
  },
  data() {
    return {
      codeState: null,
      options: [],
      loading: false,
      state: "",
      isWarning: {}
    }
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  watch: {
    state() {
      this.setValueInputs()
      this.isWarningObject()
    }
  },
  computed: {

    isMultiple() {
      return !!this.field.multiple
    },
    isRequired() {
      return !!this.field.required
    },
    isDisabled() {
      return this.$store.getters['newApplication/is_disabled'] ||
          (this.field.name === 'who_issued_types' && !this.$store.getters['newApplication/document_types'])

    }
  },
  methods: {

    setValueInputs() {
      if (this.field.name === 'soato_registration_types' && this.options.length) {
        if (this.state) {
          let currentSoato = this.options.filter((item) => item.full_name == this.state)[0]
          this.$store.commit('newApplication/set_soato_code_registration', currentSoato.code)
          this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
          this.$store.commit(`newApplication/set_area_registration`, currentSoato.area)
          this.$store.commit(`newApplication/set_locality_registration_types`, currentSoato.locality_type_id)
          this.$store.commit(`newApplication/set_district_registration`, currentSoato.district)
          this.$store.commit(`newApplication/set_village_council_registration`, currentSoato.village_council)
          this.$store.commit(`newApplication/set_locality_name_registration`, currentSoato.locality_name)
          this.$store.commit(`newApplication/set_country_registration_types`, 1)
          // this.disabledInputs("off")
        } else {
          this.$store.commit('newApplication/set_soato_code_registration',null)
          this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
          this.$store.commit(`newApplication/set_area_registration`, null)
          this.$store.commit(`newApplication/set_locality_registration_types`, null)
          this.$store.commit(`newApplication/set_district_registration`, null)
          this.$store.commit(`newApplication/set_village_council_registration`, null)
          this.$store.commit(`newApplication/set_locality_name_registration`, null)
          this.$store.commit(`newApplication/set_country_registration_types`, null)
          this.codeState = null
          // this.disabledInputs()
        }
      }
      if (this.field.name === 'soato_residential_types' && this.options.length) {
        if (this.state) {
          let currentSoato = this.options.filter((item) => item.full_name == this.state)[0]
          this.$store.commit('newApplication/set_soato_code_residential', currentSoato.code)
          this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
          this.$store.commit(`newApplication/set_area_residential`, currentSoato.area)
          this.$store.commit(`newApplication/set_locality_residential_types`, currentSoato.locality_type_id)
          this.$store.commit(`newApplication/set_district_residential`, currentSoato.district)
          this.$store.commit(`newApplication/set_village_council_residential`, currentSoato.village_council)
          this.$store.commit(`newApplication/set_locality_name_residential`, currentSoato.locality_name)
          this.$store.commit(`newApplication/set_country_residential_types`, 1)
          // this.disabledInputs("off")
        } else {
          this.$store.commit('newApplication/set_soato_code_residential', null)
          this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
          this.$store.commit(`newApplication/set_area_residential`, null)
          this.$store.commit(`newApplication/set_locality_residential_types`, null)
          this.$store.commit(`newApplication/set_district_residential`, null)
          this.$store.commit(`newApplication/set_village_council_residential`, null)
          this.$store.commit(`newApplication/set_locality_name_residential`, null)
          this.$store.commit(`newApplication/set_country_residential_types`, null)
          this.codeState = null
          // this.disabledInputs()
        }
      }
    },
    disabledInputs(status) {
      if (this.field.name === 'soato_registration_types') {
        let area = document.querySelector(".area_registration ")
        let locality = document.querySelector(".locality_registration_types ")
        let district = document.querySelector(".district_registration ")
        let village_council = document.querySelector(".village_council_registration ")
        let locality_name = document.querySelector(".locality_name_registration ")
        let arrInputs = [area, locality, district, village_council, locality_name]
        if (status === 'off') {
          arrInputs.forEach(input => {
            if (input) {
              input.classList.add('disabled')
            }
          })
        } else {
          arrInputs.forEach(input => {
            if (input) {
              input.classList.remove('disabled')
            }
          })
        }
      }
      if (this.field.name === 'soato_residential_types') {
        let area = document.querySelector(".area_residential")
        let locality = document.querySelector(".locality_residential_types")
        let district = document.querySelector(".district_residential")
        let village_council = document.querySelector(".village_council_residential")
        let locality_name = document.querySelector(".locality_name_residential")
        let arrInputs = [area, locality, district, village_council, locality_name]
        if (status === 'off') {
          arrInputs.forEach(input => {
            if (input) {
              input.classList.add('disabled')
            }
          })
        } else {
          arrInputs.forEach(input => {
            if (input) {
              input.classList.remove('disabled')
            }
          })
        }
      }
    },
    remoteMethod(query) {
      this.options = []
      if (this.debouncedQuery) {
        clearTimeout(this.debouncedQuery);
      }

      if (query !== '' && query.length >= 2) {
        this.loading = true;
        this.debouncedQuery = setTimeout(() => {
          this.$api.field.getSoatoDataByName({name: query}).then(response => {
            this.options = response;
            this.loading = false;
          });
        }, 500);
      } else {
        this.options = [];
      }
    },
    setState() {
      this.$store.commit(`newApplication/set_${this.field.name}`, this.state)
    },
    setDefaultState() {
      if ( this.field.name ==='soato_registration_types' && this.$store.getters["newApplication/soato_registration_types"]){
        this.state = this.$store.getters["newApplication/soato_registration_types"]

      }
      if ( this.field.name ==='soato_residential_types' && this.$store.getters["newApplication/soato_residential_types"]){
        this.state = this.$store.getters["newApplication/soato_residential_types"]

      }

      // if (!this.$store.state.newApplication[this.field.name]) {
      //   if (this.isMultiple) {
      //     this.state = [];
      //   } else {
      //     this.state = ''
      //   }
      // } else {
      //   this.state = this.$store.state.newApplication[this.field.name]
      // }
    },
    isWarningObject() {

      let bool = this.$store.getters[`newApplication/${this.field.name}`]
      let obj = typeof this.$store.getters[`newApplication/${this.field.name}`]
      if (obj === 'object' && bool !== null) {
        bool = bool.length
      }
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !bool
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;
  align-items: center;
  justify-content: center;

  .code {
    align-self: self-start;
    pointer-events: none;

    p {
      color: #1A2122;
      font-weight: 500;
      margin-bottom: 5px;

    }
  }
}

.group {
  width: 100%;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;


  .select-item {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.select {
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  &.small {
    p {
      font-size: 11px;
    }
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }

    &.required {
      position: relative;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;

      .el-input__inner {
        font-size: 12px;
      }

      &:hover {
        .el-input__inner {
          border-color: #C0C4CC;
          width: 100%;
          font-size: 12px;
        }

      }

      .el-tag.el-tag--info {
        color: #fff;
        background-color: #ef3123;

        .el-tag__close {
          color: #fff;
          background-color: #ef3123;
        }
      }

      &.warning {
        background: #fff2c5 !important;

        .el-input {
          input {
            background: #fff2c5 !important;
          }
        }
      }
    }
  }

}
</style>