import requestSender from "./base/requestSender";

export default function(axiosInstance){


    return {



        getAllGeneralRouters(payload){
            return requestSender('get', 'trading-post/get-general-routers', payload);
        },
        getAllGeneralRPs(payload){
            return requestSender('get', 'trading-post/get-general-rps', payload);
        },


        getPartners(payload){
            return requestSender('get', 'partner/get-all', payload);
        },
        getPartner(payload){
            return requestSender('get', 'partner/get-one', payload);
        },
        savePartner(payload){
            return requestSender('get', 'partner/save', payload);
        },
        deletePartner(payload){
            return requestSender('get', 'partner/delete', payload);
        },
        getAffiliationPartner(payload) {
            return requestSender('get', 'partner/get-all-partners-data-for-admin', payload);
        },


        getTradingPosts(payload){
            return requestSender('get', 'trading-post/get-all', payload);
        },
        renderTradingPosts(payload, options){
            return requestSender('post', 'trading-post/render-trading-posts', payload, options);
        },

        getTradingPost(payload){
            return requestSender('get', 'trading-post/get-one', payload);
        },
        saveTradingPost(payload){
            return requestSender('get', 'trading-post/save', payload);
        },
        deleteTradingPost(payload){
            return requestSender('get', 'trading-post/delete', payload);
        },

        getEmployees(payload){
            return requestSender('get', 'user', payload);
        },
        getEmployee(payload){
            return requestSender('get', 'user/get-one', payload);
        },
        saveEmployee(payload){
            return requestSender('post', 'user/save', payload);
        },
        renderExcel(payload, options){
            return requestSender('post', 'user/render-users-excel', payload, options);
        },
        saveNewPassword(payload){
            return requestSender('post', 'user/save-new-password', payload);
        },
        deleteEmployee(payload){
            return requestSender('post', 'user/delete', payload);
        },

        getRoles(payload){
            return requestSender('post', 'user/get-roles', payload);
        },


        getLeasingOffers(payload){
            return requestSender('get', 'leasing-application/get-leasing-offers', payload);
        },
        getLeasingOffersTypes(payload){
            return requestSender('get', 'leasing-application/get-leasing-offers-types', payload);
        },

        saveLeasingOffer(payload){
            return requestSender('post', 'leasing-application/save-leasing-offer', payload);
        },
        deleteLeasingOffer(payload){
            return requestSender('post', 'leasing-application/delete-leasing-offer', payload);
        },
        getLeasingOffersForPartner(payload){
            return requestSender('get', 'leasing-application/get-leasing-offers-for-partner', payload);
        },
        changePartnerOfferTermStatus(payload){
            return requestSender('post', 'leasing-application/change-partner-offer-term-status', payload);
        },
        changePartnerOfferTermDealerCompensationPercent(payload){
            return requestSender('post', 'leasing-application/change-partner-offer-term-dealer-compensation-percent', payload);
        },
        changeRateAdjustment(payload){
            return requestSender('post', 'leasing-application/change-rate-adjustment', payload);
        },

        setWhoTookTheJob(payload){
            return requestSender('post', 'leasing-application/set-who-took-the-job', payload);
        },
        setWhoTookDocuments(payload){
            return requestSender('post', 'leasing-application/set-who-took-documents', payload);
        },

        getCompletedSmsDocuments(payload){
            return requestSender('get', 'leasing-application/get-completed-sms-documents', payload);
        },


        getLogs(payload){
            return requestSender('get', 'logs/get-all', payload);
        },




    }
}