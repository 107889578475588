<template>
  <div class="date group">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p :class="{required: !!field.required}">
      <slot></slot>
    </p>
    <el-date-picker
        v-model="state"
        :picker-options="pickerOptions"
        :name="field.name"
        @change="setState"
        format="dd.MM.yyyy"
        v-mask="'##.##.####'"
        value-format="yyyy-MM-dd"
        :disabled="isDisabled"
        :class="{warning: isWarning}"
        type="date"
        placeholder="Выберите дату">
    </el-date-picker>
  </div>

</template>

<script>
import {mask} from "vue-the-mask";
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-input-date",
  components: {EditField},
  directives: {mask},
  props: {
    field: {},
    name: {
      type: String
    }
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      state: null,
      serviceNameMask: {
        mask: "A.##",
        tokens: {
          "#": {pattern: /[0-9]/},
          // A: {pattern: /[a-dA-D]/, transform: v => v.toLocaleUpperCase()}
        }
      },
      isWarning: {}
    }
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  watch:{
    state(){
      this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
    }
  },
  computed: {
    isDisabled() {
        return this.$store.getters['newApplication/is_disabled']
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    }
  },
  methods: {
    setState(val) {
      this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
      this.isWarningObject()
    },
    setDefaultState(){
      if(this.$store.state.newApplication[this.field.name]) {
        this.state = this.$store.state.newApplication[this.field.name]
      }
    },
    isWarningObject() {
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !this.$store.getters[`newApplication/${this.field.name}`]
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
  display: inline-block;
}
.group.date {
  max-width: 315px;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 850px){
    max-width: unset;
  }
  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    span{
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }
  ::v-deep{
    .el-date-editor{
      width: 100%;
      .el-input__inner{
        width: 100%;
        font-size: 12px;
      }
      &.warning{
          input {
            background: #fff2c5 !important;
          }

      }
    }

  }

}

</style>