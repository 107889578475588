<template>
  <div class="input group number" :class="{warning: isWarning}">
    <edit-field
        v-if="$store.getters.user.role.name === 'admin'"
        :field="field"
    />
    <p :class="{required: !!field.required}">
      <slot></slot>
    </p>
<!--    fdsfds-->
<!--    <el-input-number-->
<!--        :name="field.name"-->
<!--        :precision="2"-->
<!--        :controls="false"-->
<!--        @change="changeValue"-->
<!--        :value="$store.getters[`newApplication/${field.name}`]"-->
<!--        :min="setMinValue"-->
<!--        :max="setMaxValue"-->
<!--        :disabled="isDisabled"-->
<!--    ></el-input-number>-->
    <input
        :name="field.name"
        class="newInputNumber"
        type="text"
        :value="$store.getters[`newApplication/${field.name}`] ? $store.getters[`newApplication/${field.name}`]:'0.00'"
        :min="setMinValue"
        :max="setMaxValue"
        :disabled="isDisabled"
        @change="(e)=>newChangeValue(e,setMinValue,setMaxValue)"
    />

  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import EditField from "@/components/general/EditField.vue";

export default {
  name: "my-input-number",
  components: {EditField},

  props: {

    propsMin: {
      type: Number,
      default: 0
    },
    propsMax: {
      type: Number,
      default: 1000000
    },
    field: {},
  },
  data() {
    return {
      state: "",
      isWarning: {}
    };
  },
  mounted() {
    this.setDefaultState()
    this.isWarningObject()
  },
  methods: {
    setDefaultState() {
      if (!this.$store.getters[`newApplication/${this.field.name}`]) {
        this.state = ''
      } else {
        this.state = this.$store.getters[`newApplication/${this.field.name}`]
      }
    },
    isWarningObject() {
      this.isWarning = !!(this.field.required
          && this.$store.getters['newApplication/leasing_application_id']
          && !this.$store.getters[`newApplication/${this.field.name}`]
      )
    },
    newChangeValue(event,min=0, max=99999999999999999){
      let value = event.target.value;
      value = value.replace(/[^0-9.,]/g, '');
      value = value.replace(/,/g, '.');
      const parts = value.split('.');
      if (parts.length > 1) {
        // Ограничиваем количество знаков после точки до 2
        parts[1] = parts[1].slice(0, 2);
        value = parts[0] + '.' + parts[1];
      }
        // Преобразуем строковое значение в число для проверки ограничений
      let numericValue = parseFloat(value);

        // Установка минимального и максимального значения
      const minValue = Number(min); // Ваше минимальное значение
      const maxValue = Number(max); // Ваше максимальное значение

      if (!isNaN(numericValue)) {
        if (numericValue < minValue) {
          numericValue = minValue;
        } else if (numericValue > maxValue) {
          numericValue = maxValue;
        }
        value = numericValue.toFixed(2); // Ограничиваем число до 2 знаков после запятой
      }

      event.target.value = value;

      this.$store.commit(`newApplication/set_${this.field.name}`, value);
      this.isWarningObject()
    }
  },
  computed: {
    setMinValue() {
      if (this.field.name === 'leasing_subject_price') {
        return 100
      }
      return 0
    },
    setMaxValue() {
      // if (this.field.name === 'leasing_subject_price' || this.field.name === 'leasing_subject_price2'
      //     || this.field.name === 'leasing_subject_price3' || this.field.name === 'leasing_subject_price4'
      //     || this.field.name === 'leasing_subject_price5') {
      //   return 30000
      // }
      if (this.field.name === 'basic_income_size' || this.field.name === 'additional_income_size'
          || this.field.name === 'monthly_payment') {
        return 9999999999999
      }
      return 9999999999999
    },
    isDisabled() {
      // if (this.field.name === 'leasing_subject_all_price' || this.field.name === 'first_payment') {
      if (this.field.name === 'leasing_subject_all_price') {
        return true;
      }
      return this.$store.getters['newApplication/is_disabled'];
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
      //     || this.field.name === 'leasing_subject_all_price'
      //     || this.field.name === 'first_payment'
    }
  }


};
</script>

<style lang="scss" scoped>
.group {
  position: relative;
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
    color: #000;
    font-weight: 600;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.input {
  max-width: 315px;
  margin-bottom: 15px;
  @media(max-width: 850px) {
    max-width: unset;
  }

  p {
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;

    span {
      font-size: 10px;
      font-weight: normal;
    }
    &.required {
      position: relative;
      margin-left: 10px;
      &:before {
        position: absolute;
        content: '*';
        color: #ef3123;
        font-size: 24px;
        left: -10px;
        top: -2px;
      }
    }
  }


  ::v-deep {
    .el-input-number {
      width: 100%;
    }

    .is-disabled {
      pointer-events: none;
      background: inherit;


      input {
        background: #fff;

        &:disabled {
          font-weight: bold !important;
          color: #1A2122;
        }
      }
    }

    .el-input {
      input {
        background: #fff;

        font-size: 12px;

      }
    }
  }
  &.warning {
    ::v-deep {
      input{
        background: #fff2c5 !important;
      }
    }
  }
}
</style>
