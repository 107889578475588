<template>
  <div v-loading="loading">
    <h4>Калькулятор</h4>
    <div class="variables">
      <div>
        <div>Общая стоимость</div>
        <input
            class="newInputNumber"
            type="text"
            :value="data.full_price?data.full_price:'0.00'"
            @change="(e)=>{
              data.full_price=newChangeValue(e, 0,30000)
            }"

        />
      </div>
      <div>

        <p
            style="color: #1A2122;
            font-weight: 500;
            margin-bottom: 5px;"
        >
          Лизинговые предложения
        </p>
        <el-select
            style="margin-bottom: 10px;"
            :value="$store.getters['newApplication/get_leasing_offer_id']"
            @change="setSelectedOffer"
        >
          <el-option
              v-for="offer in partnerOffers"
              :key="offer.id"
              :label="offer.name"
              :value="offer.id"
          >
          </el-option>
        </el-select>

        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id']"
            :class="'one-row'"
            :fields="[selectedOffer]"
        />

        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id']"
            :class="'one-row'"
            :fields="[selectedOfferTerms]"
        />
        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id'] && $store.getters['newApplication/get_leasing_offer_type_id'] !== $constants.offers.installment && typeof renderObject.calculation_options !== 'undefined' && typeof renderObject.calculation_options.field !== 'undefined'"
            :fields="[
             renderObject['calculation_options'].field,
             {},
             {}
        ]"
        />
      </div>
      <div>
        <my-button @click="getPaymentSchedule">Показать график</my-button>
      </div>
    </div>


    <el-dialog
        width="220mm"
        v-loading="chartLoading"
        :visible.sync="chartDialogIsOpen"
    >
      <div v-if="chartData">
        <div ref="chartData" class="chart-data">
          <chart-payment-header :data="chartData" :show="true" ref="chartPaymentHeader" :without-full-name="true"/>
          <chart-payment-by-month :data="chartData" :show="true" ref="chartPaymentByMonth"/>
          <!--                    <chart-payment-footer :data="chartData" :show="true" ref="chartPaymentFooter"/>-->
        </div>
        <div style="text-align: right; margin-top: 30px;">
          <el-button @click="getPaymentScheduleForPrint">На печать</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import MySelect from "@/components/UI/MySelect.vue";
import MyButton from "@/components/UI/MyButton.vue";
import ChartPaymentHeader from "@/components/chartPayment/ChartPaymentHeader.vue";
import ChartPaymentByMonth from "@/components/chartPayment/ChartPaymentByMonth.vue";
import FieldGroup from "@/components/general/fieldGroup.vue";
import MyInputSwitch from "@/components/UI/MyInputSwitch.vue";
import MyInputCheckboxGroup from "@/components/UI/MyInputCheckboxGroup.vue";

export default {
  name: "calculator",
  components: {
    MyInputCheckboxGroup,
    MyInputSwitch, FieldGroup, ChartPaymentByMonth, ChartPaymentHeader, MyButton, MySelect},
  data() {
    return {
      chartLoading: false,
      chartDialogIsOpen: false,
      loading: false,
      chartData: {},

      data: {
        full_price: null,
        leasing_offer_id: null,
        leasing_term_id: null,
        calculation_option: null,
      },
      partnerOffers: [],
      selectedOfferTerms: {},
      selectedOffer: {
        id: null,
      },
      renderObject: {},
      fieldsData: null,
    }
  },
  mounted() {

    this.getFields()
  },
  methods: {
    formingObjectForRendering(data) {
      let object = {}
      data.forEach(el => {
        if (el.field['name'] in object) {
          object[el.field['name']] = el
        } else {
          object[el.field['name']] = [el][0]
        }
      })
      this.renderObject = object
    },
    getLeasingOffersByPartner() {
      this.$api.leasingApplication.getLeasingOffersByUser()
          .then(data => {
            this.partnerOffers = data
            if (this.$store.getters['newApplication/get_leasing_offer_id']) {
              this.setSelectedOffer(this.$store.getters['newApplication/get_leasing_offer_id'], false)
            } else {
              this.setDefaultSelectedOffer()
            }
          })
    },
    setDefaultSelectedOffer() {
      let defaultOffer = this.partnerOffers.find(item => item.name.toLowerCase() === 'лизинг стандарт')
      if (defaultOffer) {
        this.setSelectedOffer(defaultOffer.id, true)
      } else {
        this.setSelectedOffer(this.partnerOffers[0].id, true)
      }

    },
    setSelectedOffer(id, reload = true) {
      this.selectedOffer = this.partnerOffers.find(item => item.id === id)
      if (!this.selectedOffer) {
        return this.$notify.error({
          duration: this.$constants.global.notifyDurationSuccess,
          title: 'Ошибка',
          message: 'Лизинговое предложение не найдено. Перезагрузите страницу, или обратитесь к администратору.',
        });
      }

      this.$store.commit(`newApplication/set_leasing_offer_id`, this.selectedOffer.id)

      this.$store.commit(`newApplication/set_leasing_offer_type_id`, this.selectedOffer.offer_type.id)

      this.$store.commit(`newApplication/set_down_payment_price`, this.selectedOffer.down_payment ? +this.selectedOffer.down_payment : 0)
      this.selectedOfferTerms.data = this.selectedOffer.available_terms.sort((a, b) => +a.name > +b.name ? 1 : -1)
      this.selectedOfferTerms.name_for_web = "Срок лизинга (месяцев)"
      this.selectedOfferTerms.name = "leasing_term"
      this.selectedOfferTerms.type = "radio"

      if (this.renderObject) {
        this.renderObject['leasing_term'].field = this.selectedOfferTerms
      }
      if (reload) {
        this.$store.commit(`newApplication/set_leasing_term`, this.selectedOffer.available_terms[0].id)
      }

    },
    getFields() {
      this.loading = true
      this.$api.fields.getFields({
        id: this.$constants.navigations.newApplication.sub.chartFormation
      })
          .then((data) => {
            this.formingObjectForRendering(data)
            this.getLeasingOffersByPartner()
            this.loading = false
      })
    },
    newChangeValue(event, min = 0, max = 99999999999999999) {

      let value = event.target.value;
      value = value.replace(/[^0-9.,]/g, '');
      value = value.replace(/,/g, '.');
      const parts = value.split('.');
      if (parts.length > 1) {
        // Ограничиваем количество знаков после точки до 2
        parts[1] = parts[1].slice(0, 2);
        value = parts[0] + '.' + parts[1];
      }
      // Преобразуем строковое значение в число для проверки ограничений
      let numericValue = parseFloat(value);

      // Установка минимального и максимального значения
      const minValue = Number(min); // Ваше минимальное значение
      const maxValue = Number(max); // Ваше максимальное значение

      if (!isNaN(numericValue)) {
        if (numericValue < minValue) {
          numericValue = minValue;
        } else if (numericValue > maxValue) {
          numericValue = maxValue;
        }
        value = numericValue.toFixed(2); // Ограничиваем число до 2 знаков после запятой
      }
      event.target.value = value;
      return  value

    },
    getPaymentScheduleForPrint() {
      delete this.data.only_data
      this.data.calculation_option = this.$store.getters["newApplication/calculation_options"]
      this.data.leasing_term_id = this.$store.getters["newApplication/leasing_term"]
      this.data.leasing_offer_id = this.$store.getters["newApplication/get_leasing_offer_id"]

      this.$api.leasingApplication.getFreePaymentSchedule(
          this.data,
          {responseType: 'blob'}
      ).then(({data, headers}) => {
        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
        window.open(url, '_blank')

      })
          .finally(() => {
            this.chartLoading = false;
          })
    },
    getPaymentSchedule() {
      if(!this.checkBeforeSend()) {
        return false
      }

      this.data.only_data = 1;
      this.data.calculation_option = this.$store.getters["newApplication/calculation_options"]
      this.data.leasing_term_id = this.$store.getters["newApplication/leasing_term"]
      this.data.leasing_offer_id = this.$store.getters["newApplication/get_leasing_offer_id"]



      this.$api.leasingApplication.getFreePaymentSchedule(this.data)
          .then(data =>  {
            this.chartData = data;
            this.chartDialogIsOpen = true;
          })

    },
    checkBeforeSend() {
      if(!this.data.full_price) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Введите интересующую сумму',
        })
        return false;
      }
      if(!this.$store.getters["newApplication/get_leasing_offer_id"]) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Выберите лизинговое предложение',
        })
        return false;
      }
      if(!this.$store.getters["newApplication/calculation_options"]) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Выберите вариант расчета',
        })
        return false;
      }

      return true
    }
  },

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/project-variables";
h4 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.variables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  * {
    width: 100%;
    margin-bottom: 5px;
  }
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
::v-deep .switch .el-radio-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
}
::v-deep .edit-for-role {
  display: none;
}
</style>
