import requestSender from "./base/requestSender";

export default function(axiosInstance){
    const BASE = "/navigation/";

    return {
        getFields(payload){
            return requestSender('get', BASE + 'get-fields-by-sub-navigation', payload);
        },
        getNavigations(payload){
            return requestSender('get', BASE + 'get-all', payload);
        },

    }
}