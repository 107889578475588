import requestSender from "./base/requestSender";

export default function(axiosInstance){
    const BASE_URI  = 'field/'

    return {
        editFieldName(payload){
            return requestSender('post', BASE_URI + 'edit-field-name', payload);
        },
        addFieldData(payload){
            return requestSender('post', BASE_URI + 'add-field-data', payload);
        },
        editFieldData(payload){
            return requestSender('post', BASE_URI + 'edit-field-data', payload);
        },
        deleteFieldData(payload){
            return requestSender('post', BASE_URI + 'delete-field-data', payload);
        },

        getSoatoDataByName(payload){
            return requestSender('post', BASE_URI + 'get-soato-data-by-name', payload);
        },




        getAll(payload) {
            return requestSender('get', BASE_URI + 'get-all', payload);
        },
        getFieldsForTemplates(payload) {
            return requestSender('get', BASE_URI + 'get-fields-for-templates', payload);
        },

        getDocumentTemplates(payload){
            return requestSender('get', BASE_URI + 'get-document-templates', payload);
        },
        getDocumentTemplatesForPrint(payload){
            return requestSender('get', BASE_URI + 'get-document-templates-for-print', payload);
        },
        getFieldsByDocumentTemplateId(payload){
            return requestSender('get', BASE_URI + 'get-fields-by-document-template-id', payload);
        },
        addDocumentTemplate(payload){
            return requestSender('post', BASE_URI + 'add-document-template', payload);
        },
        addFieldForDocumentTemplate(payload){
            return requestSender('post', BASE_URI + 'add-field-for-document-template', payload);
        },
        deleteFieldForDocumentTemplate(payload){
            return requestSender('post', BASE_URI + 'delete-field-for-document-template', payload);
        },
        deleteDocumentTemplate(payload) {
            return requestSender('post', BASE_URI + 'delete-document-template', payload);
        },


        saveDocumentTemplate(payload){
            return requestSender('post', BASE_URI + 'save-document-template', payload);
        },
    }
}