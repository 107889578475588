<template>
  <div class="field-group row"   :class="{simple:simple}">
    <div v-for="field in fields" :class="setClassName(field)">
      <div v-if="field.type === 'select'">
        <my-select :field="field" :props-options="field['data']">
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-select>
      </div>
      <div v-if="field.type === 'remote-input'">
        <my-select-soato :field="field" :props-options="field['data']">
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-select-soato>
      </div>
      <div v-if="field.type === 'checkbox'">
        <my-input-checkbox-group :field="field"/>
      </div>
      <div v-if="field.type === 'radio'">
        <my-input-switch
            :field="field"
        >
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-input-switch>
      </div>
      <div v-if="field.type === 'radio-group'">
        <my-input-switch
            :field="field"
        >
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-input-switch>
      </div>
      <div v-if="field.type === 'input'">
        <my-input-text :field="field">
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-input-text>
      </div>
      <div v-if="field.type === 'number'">
          <my-input-number
                  v-if="field['name'] !== 'leasing_subjects'"
                  :field="field" >
              <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
              <i v-else style="color: #fff;">__</i>
          </my-input-number>
      </div>
      <div v-if="field.type === 'phone'">
        <my-input-phone :field="field">
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-input-phone>
      </div>
      <div v-if="field.type === 'date'">
        <my-input-date :field="field">
          <i v-if="field['name_for_web']">{{ field['name_for_web'] }}</i>
          <i v-else style="color: #fff;">__</i>
        </my-input-date>
      </div>
    </div>
    <div v-if="this.fields[0].name==='down_payment_types'" class="info-byn">
      <p v-if="this.$store.getters['newApplication/down_payment_types']">Это составляет {{this.$store.getters["newApplication/info_price_percentage"]}} BYN</p>
      <p v-if="this.$store.getters['newApplication/down_payment_price']">Это составляет {{this.$store.getters["newApplication/info_price_percentage"]}} % </p>
    </div>
  </div>
</template>

<script>
import MyInputCheckboxGroup from "@/components/UI/MyInputCheckboxGroup.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MyInputSwitch from "@/components/UI/MyInputSwitch.vue";
import MyInputText from "@/components/UI/MyInputText.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyInputPhone from "@/components/UI/MyInputPhone.vue";
import MyInputDate from "@/components/UI/MyInputDate.vue";
import MyInputNumber from "@/components/UI/MyInputNumber.vue";
import MySelectSoato from "@/components/UI/MySelectSoato.vue";



export default {
  name: "field-group",
  components: {
    MySelectSoato,
    MyInputNumber,
    MyInputDate, MyInputPhone, MyButton, MyInputText, MyInputSwitch, MySelect, MyInputCheckboxGroup
  },
  props: {
    fields: {},
    simple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  methods: {
    setClassName(field) {

      let switchTypeValue = +this.$store.state.newApplication.down_payment
      // if (switchTypeValue === 1 && field.name ===  'down_payment_price') {
      //   return 'hide'
      // }
      if (switchTypeValue === 2 && field.name === 'down_payment_types'  ) {
        return 'hide'
      }
      return field.name
    }
  },
}
</script>

<style lang="scss" scoped>
.field-group.row {
  .disabled{

    .input.group, .group.select{
      pointer-events: none;
      ::v-deep{
        .el-input,.el-select{

          .el-input__inner{
            background:gray;
          }
        }
      }

    }
  }
  position: relative;
  display: grid;
  column-gap: 35px;
  grid-template-columns: 1fr 1fr 1fr;
  &.simple {
    grid-template-columns: 1fr;
  }

  i {
    font-style: normal;
  }

  &.one-row {
    grid-template-columns: 1fr;
  }

  @media(max-width: 1024px) {
    column-gap: 10px;
  }
  @media(max-width: 850px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  ::v-deep .el-input{
    input{
      background: #fff;
      font-size: 12px;
      &:disabled {
        background: #ddd;
        color: #000;
        font-weight: 600;

      }
    }
  }

}

.hide {
  display: none;
}
.info-byn{
  display: flex;
  align-items: center;
  p{
    margin-top: 20px;
    color: #1A2122;
    font-weight: 500;
    margin-bottom: 5px;
    @media (max-width: 850px) {
      margin-top: 0px;
    }
  }
}
.leasing_subject_all_price{
  max-width: 150px;
  grid-column-start: 3;
  margin-left: 70px;
  p{
    i{
      font-size: 13px;
    }
  }
}

</style>

