<template>
  <div>
    <el-tabs type="border-card" v-model="activeTabName" @tab-click="reset">
      <el-tab-pane label="Все предложения" name="list">
        <div v-if="leasingOffers">
          <el-table
              :data="leasingOffers"
              stripe
              style="width: 100%">
            <el-table-column>
              <template slot-scope="scope">
                <el-popconfirm
                    title="Вы уверены, что хотите удалить предложение?"
                    @confirm="deleteLeasingOffer(scope.row)"
                >
                  <i style="margin-right: 10px" class="el-icon-delete" slot="reference" ></i>
                </el-popconfirm>

                <i class="el-icon-edit" @click="setSelectedLeasingOffer(scope.row)"></i>
              </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Наименование"
                width="180">
            </el-table-column>
            <el-table-column
                prop="description"
                label="Описание">
            </el-table-column>

            <el-table-column
                prop="degression_coefficient"
                label="Коэф. дигрессии">
            </el-table-column>
            <el-table-column
                prop="leasing_fee_scheme"
                label="Схема лизингового вознаграждения">
            </el-table-column>
            <el-table-column
                prop="fixed_remuneration_percent"
                label="Фиксированное вознаграждение, в % от стоимости">
            </el-table-column>
            <el-table-column
                prop="leasing_rate_percent"
                label="Ставка лизингового вознаграждения, в %">
            </el-table-column>
            <el-table-column
                prop="fixed_fee_price"
                label="Фиксированная сумма вознаграждения">
            </el-table-column>



          </el-table>

        </div>
        <div v-else>Лизинговые продукты отсутствуют. Перейдите на вкладку <b>"Сформировать"</b> для добавления продукта"</div>
      </el-tab-pane>
      <el-tab-pane label="Сформировать" name="edit">
        <div class="new-offer">

          <div class="offer-create">

            <div>
              <h3>Сформировать из существующего</h3>
              <el-select v-model="selectedCreatedLeasingOffer" @change="setSelectedLeasingOfferFromCreated" placeholder="Выберите из списка">
                <el-option
                    :key="item.id"
                    v-for="item in leasingOffers"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </div>


            <el-form ref="leasing-offer-form" :model="selectedLeasingOffer" label-width="250px" v-if="visible">
              <el-form-item label="Название продукта">
                <el-input v-model="selectedLeasingOffer.name"></el-input>
              </el-form-item>
              <el-form-item label="Код продукта">
                <el-input v-model="selectedLeasingOffer.code"></el-input>
              </el-form-item>
              <el-form-item label="Вид продукта">
                  <el-select v-model="selectedLeasingOffer.leasing_offer_type_id" placeholder="Выберите из списка">
                    <el-option
                        :key="item.id"
                        v-for="item in leasingOffersTypes"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="Описание">
                <el-input type="textarea" v-model="selectedLeasingOffer.description"></el-input>
              </el-form-item>
<!--              <el-form-item label="Варианты расчета">-->
<!--                <el-select v-model="selectedLeasingOffer.calculation_option_id" placeholder="Выберите из списка">-->
<!--                  <el-option-->
<!--                      :key="item.id"-->
<!--                      v-for="item in calculationOptionsData"-->
<!--                      :label="item.name" :value="item.id"-->
<!--                  ></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="Максимальная стоимость с НДС">
                <el-input-number :controls="false" v-model="selectedLeasingOffer.max_cost_with_vat"></el-input-number>
              </el-form-item>
              <el-form-item label="Аванс">
                <el-input v-model="selectedLeasingOffer.down_payment"></el-input>
              </el-form-item>

              <el-form-item label="Ожидаемый срок лизинга, месяцев">
                <el-select
                    v-model="selectedLeasingOffer.leasing_term_ids"
                    multiple
                    placeholder="Выберите из списка"
                >
                  <el-option
                      v-for="item in leasingTermData"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

<!--              <el-form-item label="Схема погашения КС">-->
<!--                <el-select v-model="selectedLeasingOffer.calculation_option_id" placeholder="Выберите из списка">-->
<!--                  <el-option label="Равномерно" value="2"></el-option>-->
<!--                  <el-option label="Дегрессия" value="1"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="Коэффициент дегрессии">
                <el-input-number :controls="false" :step="0.1"  v-model="selectedLeasingOffer.degression_coefficient"></el-input-number>
              </el-form-item>
              <el-form-item label="Схема лизингового вознаграждения">
                <el-select v-model="selectedLeasingOffer.leasing_fee_scheme" placeholder="Выберите из списка">
                  <el-option
                      v-for="item in leasing_fee_schemes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Фиксированное вознграждение, в % от стоимости">
                <el-input-number :controls="false" v-model="selectedLeasingOffer.fixed_remuneration_percent" :disabled="selectedLeasingOffer.leasing_fee_scheme !== 2"></el-input-number>
              </el-form-item>
              <el-form-item label="Фиксированная сумма вознаграждения">
                <el-input-number :controls="false" v-model="selectedLeasingOffer.fixed_fee_price" :disabled="selectedLeasingOffer.leasing_fee_scheme !== 3"></el-input-number>
              </el-form-item>
              <el-form-item label="Ставка лизингового вознаграждения, в %">
                <el-input-number :controls="false" v-model="selectedLeasingOffer.leasing_rate_percent" :disabled="selectedLeasingOffer.leasing_fee_scheme !== 1"></el-input-number>
              </el-form-item>

              <el-form-item label="Процент выкупной стоимости">
                <el-input-number :controls="false" v-model="selectedLeasingOffer.redemption_value"></el-input-number>
              </el-form-item>
            </el-form>
          </div>
          <div class="pre-result">
            <div>
              <div>Эффективная процентная ставка равномерно, %</div>
              <el-input :disabled="true" v-model="selectedLeasingOffer.effective_interest_rate_annuity"></el-input>
            </div>
            <div>
              <div>Эффективная процентная ставка дегрессия, %</div>
              <el-input :disabled="true" v-model="selectedLeasingOffer.effective_interest_rate_reduction"></el-input>
            </div>

          </div>
        </div>
        <div class="buttons">
          <el-button @click="reset">Сбросить</el-button>
          <el-button @click="saveLeasingOffer" :disabled="!selectedLeasingOffer.name">Сохранить</el-button>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "offers",
  data() {
    return {
      visible: true,
      selectedCreatedLeasingOffer: '',
      activeTabName: 'list',
      leasingOffers: [],
      leasingOffersTypes: [],
      emptyOffer: {
        id: null,
        name: '',
        code: '',
        calculation_options: null,
        leasing_term_ids: [],
        full_price: null,
        first_payment: null,
        degression_coefficient: null,
        redemption_value: null,
        leasing_fee_scheme: null,
        fixed_remuneration_percent: null,
        leasing_rate_percent: null,
        leasing_offer_type_id: null,
        payment_to_course_percent: null
      },
      selectedLeasingOffer: {},
      leasing_fee_schemes: [
        {id: 1, name: 'Процентная ставка'},
        {id: 2, name: 'Фиксированное'},
        {id: 3, name: 'Фиксированная сумма'},
      ]
    }
  },
  mounted() {
    this.getLeasingOffers()
    this.getLeasingOffersTypes()
  },
  computed: {
    leasingTermData() {
      return this.$store.getters.fields.find(item => item.name === 'leasing_term').data.sort((a, b) => +a.name > +b.name ? 1 : -1)
    },
    calculationOptionsData() {
      return this.$store.getters.fields.find(item => item.name === 'calculation_options').data
    }
  },
  methods: {
    getLeasingOffers() {
      this.$api.administration.getLeasingOffers()
          .then((data) => {
            this.leasingOffers = data;
          })
    },
    getLeasingOffersTypes() {
      this.$api.administration.getLeasingOffersTypes()
          .then((data) => {
            this.leasingOffersTypes = data;
          })
    },
    setSelectedLeasingOffer(offer) {
      this.visible = false;
      this.selectedLeasingOffer = {...offer}
      this.activeTabName = 'edit'
      this.visible = true;
    },
    setSelectedLeasingOfferFromCreated() {
      this.visible = false;
      let desired = this.leasingOffers.find(item => item.id === this.selectedCreatedLeasingOffer)
      this.selectedLeasingOffer = { ...desired }
      this.selectedLeasingOffer.code = ''
      this.selectedLeasingOffer.name = ''
      this.selectedLeasingOffer.id = null
      this.visible = true;
    },
    saveLeasingOffer() {
      this.$api.administration.saveLeasingOffer(this.selectedLeasingOffer)
          .then((data) => {
            if(!data.errors) {
              this.selectedLeasingOffer = data;
              this.getLeasingOffers()
              return this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Предложение сохранен',
              });
            }
            return this.$notify.error({
              duration: this.$constants.global.notifyDurationError,
              title: 'ошибка',
              message: data.errors,
            });

          })
    },
    deleteLeasingOffer(offer) {
      this.$api.administration.deleteLeasingOffer({
        id: offer.id
      })
          .then((data) => {
            if(!data.errors) {
              this.getLeasingOffers()
              return this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Предложение удалено',
              });
            }
            return this.$notify.error({
              duration: this.$constants.global.notifyDurationError,
              title: 'ошибка',
              message: data.errors,
            });

          })
    },
    reset() {
      this.visible = false;
      this.selectedCreatedLeasingOffer = null
      this.selectedLeasingOffer = {...this.emptyOffer}
      this.visible = true;
      console.log('1')
    }

  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
  text-align: right;
}
.new-offer {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 30px;
  .offer-create {

    .pre-result {
      text-align: right;
      ::v-deep .el-input {
        width: 100%;
      }
    }

    ::v-deep .el-form {
      &-item {
        width: 100%;
        margin-bottom: 10px;
        .el-select {
          width: 100%;
          .el-tag {
            background: #ef3123;
            color: #fff;
            &__close {
              background: #fff;
              color: #ef3123;
            }
          }
          &__tags-text {
            line-height: 12px;
          }
        }
        .el-input-number {
          width: 100%;
        }
        &__label {
          font-size: 12px;
          line-height: 1;
          margin-top: 10px;
        }
      }

    }
  }
}
::v-deep .el-table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

//.leasing-offer-form {
//  > div {
//    display: grid;
//    grid-template-columns: 250px 1fr;
//    gap: 20px;
//    margin-bottom: 10px;
//    margin-top: 10px;
//    > div {
//      &:first-child {
//        display: flex;
//        align-items: center;
//        justify-content: flex-end;
//      }
//      &:last-child {
//        * {
//          width: 100%;
//        }
//      }
//    }
//  }
//}


::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #f5f5f57a;
  border-bottom: 1px solid #f5f5f57a;
  margin: 0;
}

</style>