

<template>
  <div>
    <h2>Логирование</h2>
    <el-input
        v-model="search"
        size="mini"
        placeholder="Поиск..."/>
    <el-table
        class="logs-table"
        :data="logs.filter(data => !search  || data.leasing_application_id.toString().includes(search))"
        style="width: 100%">
      <el-table-column
          width="70"
          align="center"
          label="ID заявки"
          prop="leasing_application_id">
      </el-table-column>
      <el-table-column
          label="Дата создания"
          width="100"
          align="center"
      >
        <template slot-scope="scope">
          <span> {{convertDate(scope.row.created_at).date}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Время создания"
          width="100"
          align="center"
      >
        <template slot-scope="scope">
          <span> {{convertDate(scope.row.created_at).time}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Событие"
          prop="action.name"
          width="150"
      >
      </el-table-column>
      <el-table-column
          label="Тип события"
          prop="action.type"
          width="140"
      >
      </el-table-column>
      <el-table-column
          label="Кто выполнил"
          width="200"
      >
        <template slot-scope="scope">
          <span>{{scope.row.who.first_name}} {{scope.row.who.last_name}} {{scope.row.who.patronym}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Роль"
          prop="who.role.description">
      </el-table-column>


    </el-table>


    <paginator
        v-if="totalCount"
        :paginator.sync="paginator"
        @page-changed="getAll"
    ></paginator>


  </div>

</template>

<script>
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/UI/Paginator.vue";
export default {
  name: "logs",
  components: {Paginator},
  mixins: [paginator],
  data() {
    return {
      totalCount: 0,
      logs: [],

      search: '',
    }
  },
  mounted() {
    this.paginator.page = 1
    this.paginator.perPage = 100
    this.getAll()
  },
  methods: {

    getAll(page) {
      this.$api.administration.getLogs({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator}) => {

            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount

            this.logs = data
          })
    },
    convertDate(data) {
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .logs-table {
    font-size: 9px;
    .el-table__row {
      td.el-table__cell{
        font-size: 10px;
        padding: 2px 0;
      }

    }
  }
</style>