<template>
  <div class="authBlock">
    <div class="content">
      <div class="logo-mobile">
        <img src="../../assets/img/logo-black.png" alt="logo">
      </div>
      <div class="left-block">
        <div class="logo">
          <img src="../../assets/img/logo-black.png" alt="logo">
        </div>
        <div class="tabs">
          <div class="tabs__item active" data-tab="login"><i class="el-icon-user"></i> Войти</div>
          <div class="tabs__item" data-tab="register"><i class="el-icon-user"></i> Зарегистрироваться</div>
          <div class="tabs__item" data-tab="tools"><i class="el-icon-box"></i> Инструменты</div>
          <div class="tabs__item" data-tab="about"><i class="el-icon-copy-document"></i> О нас</div>
        </div>
      </div>
      <div class="welcomeBlock">
        <div class="welcomeBlock__sqrt">
          <h1>Добро пожаловать <br>
            <span>  в личный кабинет агента</span>

          </h1>
<!--          <p class="stupid-text"><i>Такие как вы,<br/> двигают этот мир вперёд</i></p>-->
        </div>
      </div>
      <div class="right-block">
        <div class="login">
          <h5>Для входа введите логин и пароль</h5>
          <div class="login__inputs">
            <el-input placeholder="Логин" v-model="model.login"></el-input>
            <el-input placeholder="Пароль" v-model="model.password" @keyup.enter.native="onEnter" show-password></el-input>
            <el-button
                :style="{pointerEvents:model.login.length && model.password.length ?'':'none'}"
                :loading="loading"
                class="login-button"
                type="primary"
                native-type="submit"
                block
                @click="login"
            >
              <i v-if="!loading" class="el-icon-right"></i>
            </el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: 'Login',
  components: {},

  data() {
    return {
      loading: false,
      model: {
        login: '',
        password: '',
      },
      rules: {},
    }
  },
  computed: {},

  mounted() {

  },
  methods: {
    onEnter(){
      if (this.model.login.length && this.model.password.length){
        this.login()
      }
    },
    login() {
      this.loading = true;
      this.$api.user.login({
        login: this.model.login,
        password: this.model.password,
      })
          .then(data => {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            this.$router.push({path: 'new-locations'})

          })
          .finally(() => {
            this.loading = false;
          })
    },

  }
}

</script>
<style lang="scss" scoped>

.authBlock {
  font-family: 'Gotham Pro';
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 700px){
    padding: 15px;
  }

  .content {
    max-width: 1200px;
    width: 100%;
    height: 400px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px #00000054;
    display: grid;
    grid-template-columns: 200px 500px 500px;
    padding: 0;
    @media(max-width: 1250px){
      max-width: 600px;
      grid-template-columns: 200px 1fr;
    }
    @media(max-width: 700px){
      grid-template-columns: 1fr;
      height: 330px;
    }
    .logo-mobile{
      display: none;
      @media(max-width: 700px){
        display: block;
        padding: 20px 0;
        img{
          width: 100%;
          max-width: 200px;
        }
      }
    }

    .left-block {
      @media(max-width: 700px){
        display: none;
      }
      .logo {
        margin-top: 20px;

        img {

          max-width: 130px;
          width: 100%;
        }
      }

      .tabs {
        margin-top: 30px;
        display: grid;
        gap: 10px;

        &__item {
          cursor: pointer;
          color: #dcdcdc;
          padding: 5px 0;
          display: grid;
          font-size: 12px;
          text-align: center;
          font-weight: normal;
          border-left: solid 4px inherit;
          gap: 2px;

          i {
            font-size: 20px;
          }
        }

        &__item.active {
          color: #555;
          border-left: solid 4px #ef3123;
        }
      }
    }

    .welcomeBlock {
      position: relative;
      @media (max-width: 1250px){
        display: none;
      }

      &__sqrt {
        position: absolute;
        background: #ef3123;
        width: 100%;
        height: 120%;
        top: -40px;
        box-shadow: 1px 4px 4px 1px #0000005e;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          font-weight: bold;
          text-shadow: 2px 0px 9px #00000063;
          width: 100%;
          text-align: center;
          color: #fff;
          font-family: inherit !important;
          margin-bottom: 20px;
          span{
            font-size:20px ;
          }
        }
        .stupid-text{
          position: absolute;
          bottom: 20px;
          right: 20px;
          text-align: end;
          margin-bottom: 0;
          font-size: 22px;
          font-family: 'Caveat', cursive;
          font-weight: bold;
        }

        p {
          max-width: 300px;
          width: 100%;
          text-align: start;
          color: #dadada;
        }
      }
    }

    .right-block {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      @media(max-width: 700px){
        padding-bottom:30px;
      }

      .login {
        max-width: 300px;

        h5 {
          margin-bottom: 30px;
          text-align: start;
        }

        &__inputs {
          button {
            border-radius: 50%;
            padding: 10px;
            border: none;
            background: #ef3123;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            line-height: 1;
            overflow: hidden;
            transition: translateX .3s;

            &:hover {
              background: #ef3123;

              i {
                animation: arrow-right 1s linear infinite;
              }

            }

            @keyframes arrow-right {
              0% {
                transform: translateX(0);
              }
              25% {
                transform: translateX(3px)
              }
              50% {
                transform: translateX(0px)
              }
              75% {
                transform: translateX(-3px)
              }
              100% {
                transform: translateX(0px);
              }
            }
          }

          ::v-deep {
            .el-input {
              margin-bottom: 20px;
              input{
                font-family: inherit;
                &::placeholder{
                  font-size: 13px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
