<template>
  <div>
    <div class="scoring-container" v-if="data">

      <h3>Рекомендация скоринга</h3>
      <div v-if="$store.getters[`newApplication/assent_date`]">
        <div class="scoring-result">
          <div class="bold-data">Результат оценки скоринга: <span :style="{color: getStatus().color}">{{getStatus().name}}</span></div>
          <div class="bold-data" v-if="getStatus().denialReason">Причина отказа: <span :style="{color: getStatus().color}">{{getStatus().denialReason}}</span></div>
          <div v-else></div>
          <div>
            <my-button class="update" @click="updateScoringData">Обновить</my-button>
          </div>
        </div>
<!--        <div class="old-result" style="margin-bottom: 20px;">-->
<!--          <div class="bold-data">Личный номер присутствует в базе данных</div>-->
<!--          <div class="bold-data">Номер телефона присутствует в базе данных</div>-->
<!--          <div class="bold-data">Результат предыдущего рассмотрения: "ОДОБРЕНО"</div>-->
<!--        </div>-->


        <el-collapse v-model="activeNames">

          <el-collapse-item title="Данные из ФСЗН" name="1">
            <div v-if="typeof data.solvencyRatingCalculation !== 'undefined' && data.solvencyRatingCalculation">
              <div class="fszn">
                <div class="fszn-data card">
                  <div>
                    <div>
                      <div class="bold-data">Зарплата</div>
                      <div>{{data.solvencyRatingCalculation.salary.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Зарплата по ФСЗН</div>
                      <div>{{data.solvencyRatingCalculation.fsznSalary.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Дополнительный доход</div>
                      <div>{{data.solvencyRatingCalculation.additionalIncome.toFixed(2)}}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div class="bold-data">Платежи по кредиту</div>
                      <div>{{data.solvencyRatingCalculation.loanPayments.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Платежи по лизингу</div>
                      <div>{{data.solvencyRatingCalculation.leasingPayment.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Количество иждивенцев</div>
                      <div>{{data.solvencyRatingCalculation.numOfDependents}}</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div class="bold-data">Фамилия</div>
                      <div>{{data.person.lastname}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Имя</div>
                      <div>{{data.person.firstname}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Отчество</div>
                      <div>{{data.person.middlename}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Дата рождения</div>
                      <div>{{convertDate(data.person.birthday).date}}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <div v-if="typeof data.solvencyRatingCalculation.fszn !== 'undefined'">
                    <div class="card" v-for="org in data.solvencyRatingCalculation.fszn.response.organizations">
                      <div style=" display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;">
                        <div>
                          <div class="bold-data">Наименование работодателя</div>
                          <div>{{org.name}}</div>
                        </div>
                        <div>
                          <div></div>
                          <div></div>
                        </div>
                        <div>
                          <div class="bold-data">УНП</div>
                          <div>{{org.unp}}</div>
                        </div>
                        <div>
                          <div class="bold-data">Дата приема</div>
                          <div>{{convertDate(org.workInfo[0].dateIn).date}}</div>
                        </div>
                      </div>
                      <div v-if="org.workInfo.length">
                        <h5>Сведения о начислениях</h5>
                        <el-table
                            v-for="info in org.workInfo"
                            border
                            :data="info.pays"
                            style="width: 75%">
                          <el-table-column
                              prop="month"
                              label="Порядковый номер месяца"
                          >
                          </el-table-column>
                          <el-table-column
                              prop="paymentSum"
                              label="Сумма выплат за месяц"
                          >
                          </el-table-column>
                          <el-table-column
                              prop="benefitSum"
                              label="Сумма пособий за месяц">
                          </el-table-column>
                        </el-table>
                        <!--                        <el-table-->
                        <!--                            border-->
                        <!--                            :data="org.workInfo[1].pays"-->
                        <!--                            style="width: 75%">-->
                        <!--                          <el-table-column-->
                        <!--                              prop="month"-->
                        <!--                              label="Порядковый номер месяца"-->
                        <!--                          >-->
                        <!--                          </el-table-column>-->
                        <!--                          <el-table-column-->
                        <!--                              prop="paymentSum"-->
                        <!--                              label="Сумма выплат за месяц"-->
                        <!--                          >-->
                        <!--                          </el-table-column>-->
                        <!--                          <el-table-column-->
                        <!--                              prop="benefitSum"-->
                        <!--                              label="Сумма пособий за месяц">-->
                        <!--                          </el-table-column>-->
                        <!--                        </el-table>-->
                      </div>
                    </div>

                  </div>
                  <div v-if="typeof data.solvencyRatingCalculation.fszn !== 'undefined'">
                    <div class="card" v-for="org in data.solvencyRatingCalculation.fszn.response.organizations">
                      <div style=" display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;">
                        <div>
                          <div class="bold-data">Наименование работодателя</div>
                          <div>{{org.name}}</div>
                        </div>
                        <div>
                          <div></div>
                          <div></div>
                        </div>
                        <div>
                          <div class="bold-data">УНП</div>
                          <div>{{org.unp}}</div>
                        </div>
                        <div>
                          <div class="bold-data">Дата приема</div>
                          <div>{{convertDate(org.workInfo[0].dateIn).date}}</div>
                        </div>
                      </div>
                      <div v-if="org.workInfo.length">
                        <h5>Сведения о начислениях</h5>
                        <el-table
                            border
                            :data="org.workInfo[0].pays"
                            style="width: 75%">
                          <el-table-column
                              prop="month"
                              label="Порядковый номер месяца"
                          >
                          </el-table-column>
                          <el-table-column
                              prop="paymentSum"
                              label="Сумма выплат за месяц"
                          >
                          </el-table-column>
                          <el-table-column
                              prop="benefitSum"
                              label="Сумма пособий за месяц">
                          </el-table-column>
                        </el-table>
<!--                        <el-table-->
<!--                            border-->
<!--                            :data="org.workInfo[1].pays"-->
<!--                            style="width: 75%">-->
<!--                          <el-table-column-->
<!--                              prop="month"-->
<!--                              label="Порядковый номер месяца"-->
<!--                          >-->
<!--                          </el-table-column>-->
<!--                          <el-table-column-->
<!--                              prop="paymentSum"-->
<!--                              label="Сумма выплат за месяц"-->
<!--                          >-->
<!--                          </el-table-column>-->
<!--                          <el-table-column-->
<!--                              prop="benefitSum"-->
<!--                              label="Сумма пособий за месяц">-->
<!--                          </el-table-column>-->
<!--                        </el-table>-->
                      </div>
                      <div v-if="org.workInfo.length">
                        <h5>Дополнительные сведения о стаже</h5>

                        <el-table
                            border
                            :default-sort = "{prop: 'from', order: 'ascending'}"
                            :data="org.workInfo[0].experienceInfo"
                            style="width: 100%">
                          <el-table-column
                              prop="from"
                              label="Дата начала периода"
                              width="120"
                          >
                            <template slot-scope="scope">
                              <div>{{convertDate(scope.row.from).date}}</div>
                            </template>
                          </el-table-column>
                          <el-table-column
                              label="Дата окончания периода"
                              width="130"
                          >
                            <template slot-scope="scope">
                              <div>{{convertDate(scope.row.to).date}}</div>
                            </template>
                          </el-table-column>
                          <el-table-column
                              prop="professionCode"
                              label="Код профессии">
                          </el-table-column>
                          <el-table-column
                              prop="activityCode"
                              label="Код вида деятельности">
                          </el-table-column>
                          <el-table-column
                              prop="index"
                              label="Показатель">
                          </el-table-column>
                          <el-table-column
                              prop="workingWeekLength"
                              label="Продолжительность рабочей недели">
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>

                  </div>

                </div>

              </div>



            </div>


            <div v-else>Данные из ФСЗН отсутствуют</div>
          </el-collapse-item>
          <el-collapse-item title="Данные из кредитного регистра" name="2">
            <div v-if="data.creditRatingCalculation && typeof data.creditRatingCalculation.creditHistory !== 'undefined' && data.creditRatingCalculation.creditHistory.response">
              <div class="credit-register">
                <div class="credit-register__rating">
                  <h5>Кредитный рейтинг: <span style="font-size: 16px;">{{data.creditRatingCalculation.rating}}</span></h5>
                </div>

                <div class="credit-register__table card">
                  <div class="credit-register__table-header">
                    <div class="bold-data">Наименование</div>
                    <div class="bold-data">Договоры Банков (кроме лизингов)</div>
                    <div class="bold-data">Договоры МФО</div>
                    <div class="bold-data">Договоры СОЗ</div>
                    <div class="bold-data">Договоры лизинга</div>
                  </div>
                  <div class="credit-register__table-body" v-if="typeof data.creditRatingCalculation.creditHistory.response !== 'undefined'">
                    <div>
                      <div class="bold-data">Общее количество договоров кредитного характера (ДКХ)*</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statBank.numOfContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statMFO.numOfContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statOLS.numOfContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statLeasing.numOfContracts)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Действующие ДКХ (отсутствие даты прекращения или наличие непогашенной задолженности)</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statBank.numOfActiveContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statMFO.numOfActiveContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statOLS.numOfActiveContracts)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statLeasing.numOfActiveContracts)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Закрытые ДКХ</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statBank.numOfContracts - data.creditRatingCalculation.creditHistory.response.statBank.numOfActiveContracts}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.numOfContracts - data.creditRatingCalculation.creditHistory.response.statMFO.numOfActiveContracts}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.numOfContracts - data.creditRatingCalculation.creditHistory.response.statOLS.numOfActiveContracts}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statLeasing.numOfContracts - data.creditRatingCalculation.creditHistory.response.statLeasing.numOfActiveContracts}}</div>
                    </div>
                    <div>
                      <div class="bold-data">ДКХ с текущей просроченной задолженностью (основной долг и/или проценты и/или плата за пользование)</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statBank.numOfContractsWithCOD)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statMFO.numOfContractsWithCOD)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statOLS.numOfContractsWithCOD)}}</div>
                      <div>{{getStatContractsCount(data.creditRatingCalculation.creditHistory.response.statLeasing.numOfContractsWithCOD)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Кредитный лимит</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statBank.creditLimit.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.creditLimit.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.creditLimit.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statLeasing.creditLimit.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Просроченная задолженность по основному обязательству</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statBank.overdueDebtSum.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.overdueDebtSum.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.overdueDebtSum.toFixed(2)}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statLeasing.overdueDebtSum.toFixed(2)}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Просроченная задолженность по процентам</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statBank.overdueDebtPercent}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.overdueDebtPercent}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.overdueDebtPercent}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statLeasing.overdueDebtPercent}}</div>
                    </div>
                    <div>
                      <div class="bold-data">Просроченная задолженность по плате за пользование кредитом</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statBank.overdueDebtPayment}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.overdueDebtPayment}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.overdueDebtPayment}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statLeasing.overdueDebtPayment}}</div>
                    </div>
                  </div>
                </div>

                <div class="credit-register__table card" v-if="data.creditRatingCalculation.creditHistory.status !== 'processed'">
                  <div class="credit-register__table-header second">
                    <div class="bold-data">Наименование</div>
                    <div class="bold-data">Банки, Лизинговые организации</div>
                    <div class="bold-data">МФО</div>
                    <div class="bold-data">СОЗ</div>
                  </div>
                  <div class="credit-register__table-body second">
                    <div>
                      <div>Договоры обеспечения с предъявленными по ним требованиями</div>
                      <div>{{
                          data.creditRatingCalculation.creditHistory.response.statBank.numOfSecurityContractsWithClaims
                            ? data.creditRatingCalculation.creditHistory.response.statBank.numOfSecurityContractsWithClaims
                            : 0
                              +
                          data.creditRatingCalculation.creditHistory.response.statLeasing.numOfSecurityContractsWithClaims
                            ? data.creditRatingCalculation.creditHistory.response.statLeasing.numOfSecurityContractsWithClaims
                            : 0
                        }}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statMFO.numOfSecurityContractsWithClaims}}</div>
                      <div>{{data.creditRatingCalculation.creditHistory.response.statOLS.numOfSecurityContractsWithClaims}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <h5>Количество фактов просрочки</h5>
                <div class="delinquency">
                  <div v-for="(datum, index) in data.creditRatingCalculation.creditHistory.response.numOfOverdues">
                    <div>
                      <div>{{prepareIndex(index)}}</div>
                      <div>{{datum}}</div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="delinquency third card">
                <div>
                  <div>
                    <div class="bold-data">Количество запросов в КИ за последние</div>
                    <div class="bold-data">7 дней</div>
                    <div class="bold-data">30 дней</div>
                  </div>
                  <div>
                    <div class="bold-data">Банки</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d7.bank}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d30.bank}}</div>
                  </div>
                  <div>
                    <div class="bold-data">Лизинговые организации</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d7.leasing}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d30.leasing}}</div>
                  </div>
                  <div>
                    <div class="bold-data">Иные пользователи</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d7.other}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequests.d30.other}}</div>
                  </div>
                </div>

              </div>

              <div class="delinquency four card">
                <div>
                  <div>
                    <div class="bold-data">Количество пользователей КИ от которых поступил запрос за последние</div>
                    <div class="bold-data">3 дня</div>
                    <div class="bold-data">30 дней</div>
                    <div class="bold-data">180 дней</div>
                  </div>
                  <div>
                    <div class="bold-data">Банки</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d3.bank}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d30.bank}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d180.bank}}</div>
                  </div>
                  <div>
                    <div class="bold-data">Лизинговые организации</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d3.leasing}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d30.leasing}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d180.leasing}}</div>
                  </div>
                  <div>
                    <div class="bold-data">Иные пользователи</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d3.other}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d30.other}}</div>
                    <div>{{data.creditRatingCalculation.creditHistory.response.numOfRequestors.d180.other}}</div>
                  </div>
                </div>

              </div>

              <div
                  v-if="sortedContracts.current_bank_agreements.length"
                  class="card"
              >
                <h5>Список действующих договоров Банков, СФО</h5>
                <div v-for="datum in sortedContracts.current_bank_agreements">
                  <div class="sub-card">
                    <div class="table-four-column">
                      <div>
                        <div class="bold-data">Дата заключения</div>
                        <div class="bold-data">{{datum.creditType.name}}</div>
                        <div class="bold-data">Сумма остатка для погашения</div>
                        <div class="bold-data">Платеж</div>
                      </div>
                      <div>
                        <div>{{ datum.date }}</div>
                        <div></div>
                        <div>{{datum.remainingDebt.rest}}</div>
                        <div>{{datum.loanPayments.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенном периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>



              <div v-if="sortedContracts.current_leasing_agreements.length" class="card">
                <h5>Список действующих договоров лизинга</h5>
                <div v-for="datum in sortedContracts.current_leasing_agreements">
                  <div class="sub-card">
                    <div class="table-four-column">
                      <div>
                        <div class="bold-data">Дата заключения</div>
                        <div class="bold-data">{{datum.creditType.name}}</div>
                        <div class="bold-data">Сумма остатка для погашения</div>
                        <div class="bold-data">Платеж</div>
                      </div>
                      <div>
                        <div>{{ datum.date }}</div>
                        <div></div>
                        <div>{{typeof datum.remainingDebt !== 'undefined' ? datum.remainingDebt.rest : 0}}</div>
                        <div>{{datum.loanPayments.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>

              <div v-if="sortedContracts.current_mfo.length" class="card">
                <h5>Список действующих МФО</h5>
                <div v-for="datum in sortedContracts.current_mfo">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div class="bold-data">Дата заключения</div>
                        <div class="bold-data">{{datum.creditType.name}}</div>
                        <div class="bold-data">Сумма остатка для погашения</div>
                      </div>
                      <div>
                        <div>{{ datum.date }}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum}}</div>
                      </div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>

              <div v-if="sortedContracts.current_szo.length" class="card">
                <h5>Список действующих договоров СОЗ</h5>
                <div v-for="datum in sortedContracts.current_szo">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div class="bold-data">Дата заключения</div>
                        <div class="bold-data">{{datum.creditType.name}}</div>
                        <div class="bold-data">Сумма остатка для погашения</div>
                      </div>
                      <div>
                        <div>{{ datum.date }}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum}}</div>
                      </div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>


              <div  v-if="sortedContracts.closed_bank_agreements.length" class="card">
                <h5>Список закрытых договоров Банков, СФО</h5>
                <div v-for="datum in sortedContracts.closed_bank_agreements">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div class="bold-data">Дата заключения</div>
                        <div class="bold-data">{{datum.creditType.name}}</div>
                        <div class="bold-data">Сумма договора</div>
                      </div>
                      <div>
                        <div>{{datum.date}}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div class="after-table-two-column">
                        <div>Основание прекращения: </div>
                        <div>{{datum.stopContract.reason.name }}</div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>

              <div  v-if="sortedContracts.closed_leasing_agreements.length" class="card">
                <h5>Список закрытых договоров лизинга</h5>
                <div v-for="datum in sortedContracts.closed_leasing_agreements">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div>Дата заключения</div>
                        <div>{{datum.creditType.name}}</div>
                        <div>Сумма договора</div>
                      </div>
                      <div>
                        <div>{{datum.date}}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div class="after-table-two-column">
                        <div>Основание прекращения </div>
                        <div>{{datum.stopContract.reason.name }}</div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>

              <div  v-if="sortedContracts.closed_mfo.length" class="card">
                <h5>Список закрытых договоров МФО</h5>
                <div v-for="datum in sortedContracts.closed_mfo">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div>Дата заключения</div>
                        <div>{{datum.creditType.name}}</div>
                        <div>Сумма договора</div>
                      </div>
                      <div>
                        <div>{{datum.date}}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div class="after-table-two-column">
                      <div>Основание прекращения </div>
                      <div>{{datum.stopContract.reason.name }}</div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>


              <div  v-if="sortedContracts.closed_szo.length" class="card">
                <h5>Список закрытых договоров СОЗ</h5>
                <div v-for="datum in sortedContracts.closed_szo">
                  <div class="sub-card">
                    <div class="table-three-column">
                      <div>
                        <div>Дата заключения</div>
                        <div>{{datum.creditType.name}}</div>
                        <div>Сумма договора</div>
                      </div>
                      <div>
                        <div>{{datum.date}}</div>
                        <div></div>
                        <div>{{datum.amountOnDate[0].amount[0].sum.toFixed(2)}}</div>
                      </div>
                    </div>
                    <div class="after-table-two-column">
                      <div>Основание прекращения </div>
                      <div>{{datum.stopContract.reason.name }}</div>
                    </div>
                    <div v-if=" typeof datum.lateStatisticsCount !== 'undefined' ">
                      <div style="padding: 10px 0 0 0; font-weight: 500;">Количество фактов просрочки в запрошенному периоде</div>
                      <div class="credit-register__table">
                        <div class="credit-register__table-header seven">
                          <div class="bold-data">Просрочка</div>
                          <div class="bold-data">1 - 7</div>
                          <div class="bold-data">8 - 30</div>
                          <div class="bold-data">31 - 60</div>
                          <div class="bold-data">61 - 90</div>
                          <div class="bold-data">91 - 180</div>
                          <div class="bold-data">180+</div>
                        </div>
                        <div class="credit-register__table-body seven">
                          <div v-if="typeof datum.lateStatisticsCount.lateSumCount !== 'undefined'">
                            <div class="bold-data">Основной долг</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateSumCount.lateRange[5].count}}</div>
                          </div>
                          <div  v-if="typeof datum.lateStatisticsCount.latePercentCount !== 'undefined'">
                            <div class="bold-data">Проценты</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePercentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.latePaymentCount !== 'undefined'">
                            <div class="bold-data">Польз. Кредитом</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.latePaymentCount.lateRange[5].count}}</div>
                          </div>
                          <div v-if="typeof datum.lateStatisticsCount.lateFactoringDisclosedCount !== 'undefined'">
                            <div class="bold-data">Просроченная задолженность по уступленному фактору денежному требованию</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[0].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[1].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[2].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[3].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[4].count}}</div>
                            <div>{{datum.lateStatisticsCount.lateFactoringDisclosedCount.lateRange[5].count}}</div>
                          </div>
                        </div>
                        <div class="after-table" v-if="typeof datum.currentOverdueDebt !='undefined'">
                          Действующая текущая задолженность с {{datum.currentOverdueDebt.from}} в размере {{getCurrentOverdueDebt(datum)}} BYN.
                        </div>
                        <div class="after-table" v-else>
                          Задолжность отсутствует
                        </div>
                      </div>
                    </div>
                    <div v-else class="after-table">Просрочек не было</div>
                  </div>
                </div>
                <hr/>
              </div>
            </div>
            <div v-else>Данные из кредитного регистра отсутствуют</div>
          </el-collapse-item>
        </el-collapse>


        <h3 style="margin: 15px 0; color: #1A2122; font-size: 20px; font-weight: 500; line-height: 48px;">Решение андеррайтера</h3>

        <div class="underwriter_decision">
          <my-button style="margin-right: 30px;">Утвердить</my-button>
          <my-button>Отказать</my-button>
        </div>
      </div>
      <div v-else>Не подписаны согласия</div>

    </div>
    <div v-else>
      <h5>Информация отсутствует</h5>
      <div>
        <my-button class="update" @click="updateScoringData">Обновить</my-button>
      </div>
    </div>
  </div>



</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";

export default {
  name: "scoring",
  components: {MyButton},
  data() {
    return {
      sortedExpInfo: null,
      activeNames: ['1','2'],
      statuses: {
        'in_process': {
          name: "В работе",
          color: 'blue'
        },
        "fulfilled" :{
          name: "Одобрено",
          color: 'green'
        }
      },
      result: {
        name: 'Отсутствует',
        color: 'red'
      },
      reasonForRefusal: {
        name: 'Отсутствует',
        color: 'red'
      },
      data: null,
      sortedContracts:  {
        current_bank_agreements: [],
        closed_bank_agreements: [],
        current_leasing_agreements: [],
        closed_leasing_agreements: [],
        current_mfo: [],
        closed_mfo: [],
        current_szo: [],
        closed_szo: [],
      },
      contractTypes: {
        bank : [1,2,3,4,5,29,30,31],
        leasing: [28],
        mfo: [16],
        szo: [32],
      },
      deniedStatuses: {
        //CreditRatingDenialReason
        credit_rating : {
          manual_processing: "ручная обработка",
          pending: "В обработке",
          num_of_overdues: "количество просрочек",
          num_of_contracts_with_cod: "Количество договоров с текущей просроченной задолженностью",
          num_of_requests_to_ch: "количество запросов в кредитную историю",
        },
        //SolvencyRatingDenialReason
        solvency_rating: {
          incorrectly_stated_salary: "Неверно заявленная зарплата",
          manual_processing: "Ручная обработка",
        },
        //SolvencyRatingNote
        solvency_rating_note : {
          maternity_leave: "Декретный отпуск",
          no_fszn_data: "Нет данных из ФСЗН",
          no_income_information: "Нет информации о доходах",
          unemployed: "Клиент безработный",
        }
      }
    }
  },        
  mounted() {
    // this.updateScoringData()
  },
  methods: {
    prepareIndex(index) {
      const obj = {
        '1_7':'1 - 7',
        '181_':'180+',
        '8_30':'8 - 30',
        '31_60':'31 - 60',
        '61_90':'61 - 90',
        '91_180':'91 - 180',
      };
      return obj[index]
    },
    getStatContractsCount(data){
      return data ? data : 0
    },
    getCurrentOverdueDebt(datum) {
      return datum.currentOverdueDebt.percent ? datum.currentOverdueDebt.percent : 0 +
      datum.currentOverdueDebt.payment ? datum.currentOverdueDebt.payment : 0 +
      datum.currentOverdueDebt.sum ? datum.currentOverdueDebt.sum : 0
    },
    getCanceledReason() {
      if(typeof this.data.creditRatingCalculation !== 'undefined' && this.data.creditRatingCalculation.status === 'error' && this.data.creditRatingCalculation.errorDescription) {
        return this.data.creditRatingCalculation.errorDescription;
      }
      return null;
    },
    getInProcessReason() {
      if(typeof this.data.creditRatingCalculation !== 'undefined' && this.data.creditRatingCalculation.status === 'action_required') {
        return this.deniedStatuses.credit_rating[this.data.creditRatingCalculation.errorDescription];
      }
      if(typeof this.data.creditRatingCalculation !== 'undefined' && this.data.creditRatingCalculation.status === 'pending') {
        return this.deniedStatuses.credit_rating.pending;
      }
      if(this.data.solvencyRatingCalculation && this.data.solvencyRatingCalculation.status === 'action_required') {
        return this.deniedStatuses.solvency_rating[this.data.solvencyRatingCalculation.errorDescription];
      }
      return null;
    },
    getDeniedReason() {
      if(typeof this.data.creditRatingCalculation !== 'undefined' && this.data.creditRatingCalculation.status === 'fulfilled' && this.data.creditRatingCalculation.denied === true ) {
        return this.deniedStatuses.credit_rating[this.data.creditRatingCalculation.denialReason]
      }
      if(typeof this.data.solvencyRatingCalculation !== 'undefined' && this.data.solvencyRatingCalculation.status === 'fulfilled' && this.data.solvencyRatingCalculation.denied === true) {
        return this.deniedStatuses.solvency_rating[this.data.solvencyRatingCalculation.denialReason]
      }
      if(typeof this.data.solvencyRatingCalculation !== 'undefined' && this.data.solvencyRatingCalculation.status === 'incorrectly_stated_salary' && this.data.solvencyRatingCalculation.denied === true) {
        return this.deniedStatuses.solvency_rating[this.data.solvencyRatingCalculation.denialReason]
      }
    },
    getStatus() {
      switch(true) {

        case this.data.status === 'canceled':
          return {
            color: 'orange',
            name: 'Отменено',
            denialReason: this.getCanceledReason()
          }


        case this.data.status === 'fulfilled' && this.data.denied === false:
          return {
            color: 'green',
            name: 'Одобрено',
            denialReason: null
          }

        case this.data.status === 'in_process':
          return {
            color: 'gray',
            name: 'В обработке',
            denialReason: this.getInProcessReason()
          }

        case this.data.status === 'fulfilled' && this.data.denied === true:
          return {
            color: 'red',
            name: 'Отказано',
            denialReason: this.getDeniedReason()
          }

      }


    },
    convertDate(data) {
      if(!data) {
        return  {date: 'Отсутствует'}
      }
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
    getSortedContracts () {

      if(typeof this.data.creditRatingCalculation.creditHistory !== 'undefined' && this.data.creditRatingCalculation.creditHistory.response.contracts) {
        this.data.creditRatingCalculation.creditHistory.response.contracts.forEach( item => {

          switch(true) {
            case this.contractTypes.bank.includes(item.creditType.type):
              typeof item.stopContract === 'undefined'
                  ? this.sortedContracts.current_bank_agreements.push(item)
                  : this.sortedContracts.closed_bank_agreements.push(item)
              break;
            case this.contractTypes.leasing.includes(item.creditType.type):
              typeof item.stopContract === 'undefined'
                  ? this.sortedContracts.current_leasing_agreements.push(item)
                  : this.sortedContracts.closed_leasing_agreements.push(item)
              break;
            case this.contractTypes.mfo.includes(item.creditType.type):
              typeof item.stopContract === 'undefined'
                  ? this.sortedContracts.current_mfo.push(item)
                  : this.sortedContracts.closed_mfo.push(item)
              break;
            case this.contractTypes.szo.includes(item.creditType.type):
              typeof item.stopContract === 'undefined'
                  ? this.sortedContracts.current_szo.push(item)
                  : this.sortedContracts.closed_szo.push(item)
              break;
          }
        })
      }else {
        this.sortedContracts = {
              current_bank_agreements: [],
              closed_bank_agreements: [],
              current_leasing_agreements: [],
              closed_leasing_agreements: [],
              current_mfo: [],
              closed_mfo: [],
              current_szo: [],
              closed_szo: [],
        }
      }

    },
    getScoringResult() {
      this.result.name = 'Отказано'
    },
    updateScoringData() {
      if(this.$store.getters["newApplication/assent_date"]) {
        this.calculateScoringData()
        this.getScoringData()
      }

    },
    getScoringData() {
      this.$api.scoring.get({
        leasing_application_id: this.$store.getters[`newApplication/leasing_application_id`]
      })
          .then(data => {
            this.data = data;
            this.getSortedContracts()
          })
    },
    calculateScoringData() {
      this.$api.scoring.calculate({
        leasing_application_id: this.$store.getters[`newApplication/leasing_application_id`]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 15px 0 5px 0;
  font-weight: 500;
}


::v-deep .el-table {
  &__cell {
    padding: 5px 0;
    font-size: 0.8rem;
  }
  &__header {
    thead {
      color: #1A2122;
      th {
        font-weight: 500;
      }
    }

  }
  &__body {
    font-weight: 300;
  }

}

::v-deep .el-collapse {
  &-item {
    &__header {
      color: #1A2122;
      font-size: 20px;
    }
    &__content {
      color: #1A2122;
    }
    &__wrap {
      background-color: #fcfcfc;
      padding: 10px 20px;
    }
  }

}

.scoring-container {
  > * {
    margin: 15px 0;
  }
}
 .scoring-result {
   display: grid;
   grid-template-columns: 2fr 2fr 1fr;
   .update {
     cursor: pointer;
   }
 }
.card {
  margin-top: 20px;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  h5 {
    margin-top: 0;

  }
  .sub-card {
    background: #fafafa;
    margin-bottom: 20px;
  }
}
 .fszn-data {
   > div {
     display: grid;
     margin-bottom: 15px;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &.other-data {
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
     }
   }
 }

 .credit-register {
   &__rating {
     display: flex;
     gap: 30px;
     padding: 0 5px;
     //margin-bottom: 30px;
   }
   &__table {
    &-header {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      &.second {
        grid-template-columns: 2fr 1fr 1fr 1fr;
      }
      &.seven {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
      > div {
        padding: 5px 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        border-top:  1px solid #eee;
      }
      > div {
        &:first-child {
          border-left: 1px solid #eee;
        }
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
    &-body {
      &.second {
        > div {
          grid-template-columns: 2fr 1fr 1fr 1fr;
        }
      }
      &.seven {
        > div {
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
        }
      }
      > div {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        > div {
          border-bottom: 1px solid #eee;
          border-right: 1px solid #eee;
          padding: 5px 10px;

          &:first-child {
            border-left: 1px solid #eee;
          }
          &:not(:first-child) {
            text-align: center;
          }
        }
      }

    }
   }
 }
 .delinquency {
   &.third {
      > div {
        > div {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
   }
   &.four {
      > div {
        > div {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
   }
   > div {
     > div {
       display: grid;
       grid-template-columns:  1fr 1fr;
       &:last-child {
         border-bottom: 1px solid #eee;
       }
       > div {
         padding: 5px 10px;
         border-right: 1px solid #eee;
         border-top: 1px solid #eee;

         &:first-child {
           border-left: 1px solid #eee;
         }
         &:not(:first-child) {
           text-align: center;
         }
       }
     }
   }
 }

 .table-four-column {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
       &:not(:first-child) {
         text-align: center;
       }
     }
   }
 }

 .table-three-column {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
       &:not(:first-child) {
         text-align: center;
       }
     }
   }
 }
 .after-table {
   padding-left: 5px;
   margin-top: 5px;
   font-size: 0.7rem;
   font-weight: 500;
 }
 .after-table-two-column {
   display: grid;
   grid-template-columns: 1fr 2fr;
   padding-left: 5px;
   margin-top: 5px;
   font-size: 0.7rem;
 }

 .four-columns {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
     }
   }
   margin-bottom: 15px;
 }

 .six-columns {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
     &:last-child {
       border-bottom: 1px solid #eee;
     }
     > div {
       padding: 5px 10px;
       border-right: 1px solid #eee;
       border-top: 1px solid #eee;

       &:first-child {
         border-left: 1px solid #eee;
       }
     }

   }
   margin-bottom: 15px;
 }
 .bold-data {
   font-size: .8rem;
   font-weight: 500;
 }

</style>