<template>
  <div v-loading="loading">
    <h4>Калькулятор</h4>
    <div class="variables">
      <div>
        <div>Общая стоимость</div>
<!--        <el-input-number :controls="false" v-model="data.full_price">-->

<!--        </el-input-number>-->
        <input
            class="newInputNumber"
            type="text"
            :value="data.full_price?data.full_price:'0.00'"
            @change="(e)=>{
              data.full_price=newChangeValue(e, 0,30000)
            }"

        />
      </div>
      <div   v-if="fields.leasing_term">
        <div>Кол-во месяцев</div>
        <el-select
            v-model="data.leasing_term"
        >
          <el-option
              v-for="item in fields.leasing_term.field.data"
              :key="item.id"
              :value="item.name"
              :label="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="fields.calculation_options">
        <div>Варианты расчета</div>
        <el-select
            v-model="data.calculation_option"
        >
          <el-option
              v-for="item in fields.calculation_options.field.data"
              :key="item.id"
              :value="item.id"
              :label="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <my-button @click="getPaymentSchedule">Показать график</my-button>
      </div>
    </div>


    <el-dialog
        width="220mm"
        v-loading="chartLoading"
        :visible.sync="chartDialogIsOpen"
    >
      <div v-if="chartData">
        <div ref="chartData" class="chart-data">
          <chart-payment-header :data="chartData" :show="true" ref="chartPaymentHeader" :without-full-name="true"/>
          <chart-payment-by-month :data="chartData" :show="true" ref="chartPaymentByMonth"/>
          <!--                    <chart-payment-footer :data="chartData" :show="true" ref="chartPaymentFooter"/>-->
        </div>
        <div style="text-align: right; margin-top: 30px;">
          <el-button @click="getPaymentScheduleForPrint">На печать</el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import MySelect from "@/components/UI/MySelect.vue";
import MyButton from "@/components/UI/MyButton.vue";
import ChartPaymentHeader from "@/components/chartPayment/ChartPaymentHeader.vue";
import ChartPaymentByMonth from "@/components/chartPayment/ChartPaymentByMonth.vue";

export default {
  name: "help-calculator",
  components: {ChartPaymentByMonth, ChartPaymentHeader, MyButton, MySelect},
  data() {
    return {
      chartLoading: false,
      chartDialogIsOpen: false,
      loading: false,
      chartData: {},
      fields: {
        leasing_term: null,
        calculation_options: null
      },
      data: {
        full_price: null,
        leasing_term: null,
        calculation_option: 2,
      }
    }
  },
  mounted() {
    this.loading = true
    this.$api.fields.getFields({
      id: 1
    })
        .then(data => {
          this.fields.leasing_term = data.find(item => item.field.name === 'leasing_term')
          this.fields.calculation_options = data.find(item => item.field.name === 'calculation_options')
          this.loading = false
        })
  },
  methods: {
    newChangeValue(event, min = 0, max = 99999999999999999) {

      let value = event.target.value;
      value = value.replace(/[^0-9.,]/g, '');
      value = value.replace(/,/g, '.');
      const parts = value.split('.');
      if (parts.length > 1) {
        // Ограничиваем количество знаков после точки до 2
        parts[1] = parts[1].slice(0, 2);
        value = parts[0] + '.' + parts[1];
      }
      // Преобразуем строковое значение в число для проверки ограничений
      let numericValue = parseFloat(value);

      // Установка минимального и максимального значения
      const minValue = Number(min); // Ваше минимальное значение
      const maxValue = Number(max); // Ваше максимальное значение

      if (!isNaN(numericValue)) {
        if (numericValue < minValue) {
          numericValue = minValue;
        } else if (numericValue > maxValue) {
          numericValue = maxValue;
        }
        value = numericValue.toFixed(2); // Ограничиваем число до 2 знаков после запятой
      }
      event.target.value = value;
      return  value

    },
    getPaymentScheduleForPrint() {
      delete this.data.only_data
      this.$api.leasingApplication.getFreePaymentSchedule(
          this.data,
          {responseType: 'blob'}
      ).then(({data, headers}) => {
        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
        window.open(url, '_blank')

      })
          .finally(() => {
            this.chartLoading = false;
          })
    },
    getPaymentSchedule() {
      if(!this.checkBeforeSend()) {
        return false
      }

      this.data.only_data = 1;

      this.$api.leasingApplication.getFreePaymentSchedule(this.data)
          .then(data =>  {
            this.chartData = data;
            this.chartDialogIsOpen = true;
          })

    },
    checkBeforeSend() {
      if(!this.data.full_price) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Введите интересующую сумму',
        })
        return false;
      }
      if(!this.data.leasing_term) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Выберите кол-во месяцев',
        })
        return false;
      }
      if(!this.data.calculation_option) {
        this.$notify.error( {
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Выберите варианты расчета',
        })
        return false;
      }

      return true
    }
  },

}
</script>

<style lang="scss" scoped>
@import "../../assets/css/project-variables";
h4 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.variables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  * {
    width: 100%;
    margin-bottom: 5px;
  }
}
.newInputNumber{
  max-width: 315px;
  margin-bottom: 15px;
  color: #1A2122;
  font-weight: 500;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border:solid 1px #DCDFE6;
  &:focus{
    border-color: #ef3123;
    outline: none;
  }
  &:disabled{
    background: #ddd;
  }
}
.newInputNumber::-webkit-outer-spin-button,
.newInputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
