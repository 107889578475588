import Vue from 'vue'
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
import 'element-ui/lib/theme-chalk/display.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import App from './App.vue'
import vueDebounce from 'vue-debounce'
import ApiPlugin from './plugins/api'
import RublesPlugin from './plugins/rubles'
import ConstantsPlugin from './plugins/constants'
import acl from './acl';
import router from './router'
import store from './store'
import jwtDecode from 'vue-jwt-decode'
import tinymce from 'vue-tinymce-editor'

Vue.component('tinymce', tinymce)

Vue.config.productionTip = false

Vue.use(ApiPlugin);
Vue.use(ConstantsPlugin);
Vue.use(RublesPlugin);
Vue.use(ElementUI, {locale});
Vue.use(vueDebounce);
Vue.use(acl);
Vue.use(jwtDecode);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


HTMLElement.prototype.getElementsByInnerText = function (text, escape) {
  let nodes  = this.querySelectorAll("*");
  let matches = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].innerText === text || nodes[i].innerText.includes(text)) {
      matches.push(nodes[i]);
    }
  }
  if (escape) {
    return matches;
  }
  let result = [];
  for (let i = 0; i < matches.length; i++) {
    let filter = matches[i].getElementsByInnerText(text, true);
    if (filter.length === 0) {
      result.push(matches[i]);
    }
  }
  return result;
};

