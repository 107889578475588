<template>
  <div>
      handbook
  </div>
</template>

<script>
export default {
    name: "handbooks"
}
</script>

<style scoped>

</style>