import rubles from '../rubles/index'
// const Rubles = {
//     words: [
//         [
//             '', 'один', 'два', 'три', 'четыре', 'пять', 'шесть',
//             'семь', 'восемь', 'девять', 'десять', 'одиннадцать',
//             'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать',
//             'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'
//         ],
//         [
//             '', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят',
//             'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'
//         ],
//         [
//             '', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот',
//             'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'
//         ]
//     ],
//
//     toFloat: function(number) {
//         let test = this.parseFloat(number);
//         debugger
//         return this.parseFloat(number);
//     },
//     plural: (count, options) => {
//         if (options.length !== 3) {
//             return false;
//         }
//
//         count = Math.abs(count) % 100;
//         let rest = count % 10;
//
//         if (count > 10 && count < 20) {
//             return options[2];
//         }
//
//         if (rest > 1 && rest < 5) {
//             return options[1];
//         }
//
//         if (rest === 1) {
//             return options[0];
//         }
//
//         return options[2];
//     },
//     parseNumber: (number, count) => {
//         let first, second, numeral;
//         if (number.length === 3) {
//             first = number.substr(0, 1);
//             number = number.substr(1, 3);
//             numeral = '' + this.words[2][first] + ' ';
//         }
//
//         if (number < 20) {
//             numeral = numeral + this.words[0][this.toFloat(number)] + ' ';
//         } else {
//             first = number.substr(0, 1);
//             second = number.substr(1, 2);
//             numeral = numeral + this.words[1][first] + ' ' + this.words[0][second] + ' ';
//         }
//
//         if (count === 0) {
//             numeral = numeral + this.plural(number, ['рубль', 'рубля', 'рублей']);
//         } else if (count === 1) {
//             if (numeral !== '  ') {
//                 numeral = numeral + this.plural(number, ['тысяча ', 'тысячи ', 'тысяч ']);
//                 numeral = numeral.replace('один ', 'одна ').replace('два ', 'две ');
//             }
//         } else if (count === 2) {
//             if (numeral !== '  ') {
//                 numeral = numeral + this.plural(number, ['миллион ', 'миллиона ', 'миллионов ']);
//             }
//         } else if (count === 3) {
//             numeral = numeral + this.plural(number, ['миллиард ', 'миллиарда ', 'миллиардов ']);
//         }
//
//         return numeral;
//     },
//     parseDecimals: (number) => {
//         number = '' + number;
//         let text = this.plural(number, ['копейка', 'копейки', 'копеек']);
//
//         let first;
//         let second;
//         let numeral = '';
//
//         if (number === 0) {
//             number = 'ноль';
//         } else if (number < 20) {
//             number = numeral + this.words[0][this.toFloat(number)] + ' ';
//         } else {
//             first = number.substr(0, 1);
//             second = number.substr(1, 2);
//             number = numeral + this.words[1][first] + ' ' + this.words[0][second] + ' ';
//         }
//
//         return ' ' + number + ' ' + text;
//     },
//     rubles: (number) => {
//         if (!number) {
//             return false;
//         }
//
//         let type = typeof number;
//         if (type !== 'number' && type !== 'string') {
//             return false;
//         }
//
//         if (type === 'string') {
//             number = this.toFloat(number.replace(',', '.'));
//
//             if (isNaN(number)) {
//                 return false;
//             }
//         }
//
//         if (number <= 0) {
//             return false;
//         }
//
//         let splt;
//         let decimals;
//
//         number = number.toFixed(2);
//         if (number.indexOf('.') !== -1) {
//             splt = number.split('.');
//             number = splt[0];
//             decimals = splt[1];
//         }
//
//         let numeral = '';
//         let length = number.length - 1;
//         let parts = '';
//         let count = 0;
//         let digit;
//
//         while (length >= 0) {
//             digit = number.substr(length, 1);
//             parts = digit + parts;
//             let test = this.toFloat(number)
//             if ((parts.length === 3 || length === 0) && !isNaN(this.toFloat(parts))) {
//                 numeral = this.parseNumber(parts, count) + numeral;
//                 parts = '';
//                 count++;
//             }
//
//             length--;
//         }
//
//         numeral = numeral.replace(/\s+/g, ' ');
//
//         if (decimals) {
//             numeral = numeral + this.parseDecimals(this.toFloat(decimals));
//         }
//
//         return numeral;
//     }
//
//
// }
export default {
    install(Vue) {
        Vue.prototype.$rubles = rubles;
    }
}




