<template>
  <div class="phone_number_matches" v-if="appList.length">

    <h5>Сведения по номеру телефона</h5>
    <ul>
      <li>
        <div>№</div>
        <div>Номер заявки</div>
        <div>Дата заявки</div>
        <div>Статус</div>
        <div>ФИО</div>
      </li>
      <li v-for="(item, index) in this.appList">
        <div>{{index+1}}</div>
        <div @click="openApp(item.id)">{{item.app_number}}</div>
        <div>{{returnCurrentFormatDate(item.created_at)}}</div>
        <div>{{item.status.name}}</div>
        <div>{{item.client_chart_informations[0].fullname}}</div>
      </li>
    </ul>
  </div>

</template>

<script>

export default {
  name: "PhoneNumberMatches",
  components: {

  },
  props: {
    propsChangeMenuState: {
      type: Function
    }
  },
  data() {
    return {

      appList:[],

    }
  },

  watch: {},
  mounted() {
    this.getAppList()
  },
  methods: {
    getAppList(){
      let payload = {
        leasing_application_id: this.$store.getters["newApplication/leasing_application_id"]
      }
      console.log(payload)
      this.$api.leasingApplication.getPhoneNumberMatches(payload).then((data)=>{
        console.log(data)
        this.appList = data
      })
    },
    returnCurrentFormatDate(dateString){
      const [date] = dateString.split(' ');
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`
    },
    openApp(id) {
      const app = this.appList.find(item => item.id === id);
      const routeData = this.$router.resolve({name :'NewApplicationsView'})
      localStorage.removeItem('rightClickApp');
      localStorage.setItem('rightClickApp', JSON.stringify(app));
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
.phone_number_matches {
  max-width: 840px;
  margin-bottom: 20px;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;

  ul {
    overflow: auto;
    max-height: 300px;
    li {
      padding: 2px 5px;
      border-radius: 5px;
      display: grid;
      gap: 5px;
      grid-template-columns: 50px 100px 100px 140px 1fr;

      &:nth-child(odd) {
        background: #ededed;
      }

      div {
        &:nth-child(2) {
          color: #ef3123;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
      }

      &:nth-child(1) {
        background: none;
        border-bottom: solid 1px #ddd;
        border-radius: 0;
        div {
          &:nth-child(2) {
            color: inherit;
          }
        }
      }
    }
  }
}

</style>