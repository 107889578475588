import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from "@/views/404";
import Home from "@/views/Home.vue";
import Login from "@/views/auth/Login.vue";
import NewApplicationView from "@/views/NewApplicationView.vue";
import ListOfApplicationsView from "@/views/ListOfApplicationsView.vue";
import Administration from "@/views/Administration.vue";
import HelpView from "@/views/HelpView.vue";
import Calculator from "@/views/Calculator.vue";
import ListOfApplicationsArchiveView from "@/views/ListOfApplicationsArchiveView.vue";
import DocumentStatuses from "@/views/DocumentStatuses.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {title: 'Главная страница', requiresAuth: true}
    },
    {
        path: '/new-applications',
        name: 'NewApplicationsView',
        component: NewApplicationView,
        meta: {title: 'Новая заявка', requiresAuth: true}
    },

    {
        path: '/list-of-applications',
        name: 'ListOfApplicationsView',
        component: ListOfApplicationsView,
        meta: {title: 'Список заявок', requiresAuth: true}
    },
    {
        path: '/list-of-applications-archive',
        name: 'ListOfApplicationsArchiveView',
        component: ListOfApplicationsArchiveView,
        meta: {title: 'Архив списка заявок', requiresAuth: true}
    },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
        meta: {title: 'Администрирование', requiresAuth: true}
    },
    {
        path: '/help',
        name: 'HelpView',
        component: HelpView,
        meta: {title: 'Калькулятор', requiresAuth: true}
    },
    {
        path: '/calculator',
        name: 'Calculator',
        component: Calculator,
        meta: {title: 'Калькулятор', requiresAuth: true}
    },
    {
        path: '/document-statuses',
        name: 'DocumentStatuses',
        component: DocumentStatuses,
        meta: {title: 'Статусы документов', requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Войти', layout: 'empty-layout'}
    },

    { path: '/404', name: '404', component: NotFound },
    { path: '*', redirect: '/' },

]


const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: process.env.NODE_ENV === 'production'
        // ? '/performance/'
        ? '/'
        : '/',
    routes
})



router.beforeEach((to, from, next) => {

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(to.matched.some(r => r.meta.requiresAuth)) {

        if (localStorage.getItem('access_token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
    }

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));


    next();
});

export default router
