<template>
  <div class="container">
    <div class="content">
      <div class="left-block">

        <div>
          <h1><span>Лайт</span> <span>Лизинг</span></h1>
        </div>

        <div>
          <div>Для получения уведомления в телеграмм</div>
          <div>
            <span>о статусе заявки, пройдите </span>
            <a href="https://t.me/LLSagent_bot" target="_blank">по ссылке</a>
          </div>
          <div>или отсканируйте QR код</div>
        </div>

        <div>
          <img src="../../src/assets/img/qr-code-2.jpg" alt="QR" style="width: 150px;">
        </div>
        <div>
          <el-divider/>
        </div>

        <div class="contacts">
          <div>
            <div>Техническая поддержка</div>
            <div><a href="https://t.me/liteleasing" target="_blank">Перейти</a></div>
          </div>
          <div>
            <div>+375 44 589 60 60</div>
            <div>+375 29 589 60 60</div>
          </div>

        </div>

      </div>
      <div class="right-block">
        <img src="../../src/assets/img/branding.svg" alt="Branding">
      </div>


      <my-skeleton/>
    </div>

  </div>
</template>

<script>
import MySkeleton from "@/components/UI/MySkeleton.vue";

export default {
  name: "GeneralView",
  components: {MySkeleton},
  data() {
    return {
      num: 1
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  max-width: 1360px;
  width: 100%;
  padding: 15px 20px;
  margin: 0 auto;
  @media(max-width: 1024px){
    padding: 10px 15px;
  }
}
.content {
  width: 100%;
  height: 88vh;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0px #00000040;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  .left-block {
    text-align: right;
    > div {
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 66px;
      line-height: 1;
      span {
        &:first-child {

        }
        &:last-child {
          color: #ef3123;
        }
      }
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .el-divider {
      width: 83%;
      margin-left: 17%;
      background: #ef3123;
    }
  }
  .right-block {
    display: flex;
    justify-content: center;
    transform: translateY(-0.9rem);
    img {
     width: 76%;
    }
  }

}
</style>
