<template>
  <div
      v-if="$store.getters.user.role.name === 'admin'"
      class="documents"
      v-loading="loadingContent"
  >
    <div class="documents-header">
      <h4>Редактирование документов</h4>
      <div>
        <i class="el-icon-circle-plus" @click="showNewDocumentTemplateDialog"></i>
        <el-select
            v-model="selectedTemplateId"
            filterable
            @change="getDocumentTemplateFields"
            placeholder="Выберите документ">
          <el-option
              v-for="item in templates"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="selectedTemplateId">
      <div class="documents-content">
        <div class="documents-content__fields">
          <h5>Поля для вставки
            <i
                class="el-icon-edit-outline"
                @click="showDialog"
            ></i>
            <i style="font-weight: normal">Вставить текст на англ. в фигурных кавычках. Пример: {{ exemplar }}</i>
          </h5>
          <div>
            <div class="field" v-for="field in selectedTemplateFields">
              <div>{{ field.name_for_web }}</div>
              <div>{{ field.field_name }}</div>
            </div>
          </div>

        </div>
        <div class="documents-content__document">
          <h5>
            Документ: "{{ selectedTemplate.name }}"
            <i class="el-icon-edit-outline" @click="documentTemplateDialog = true"></i>
          </h5>
<!--          <tinymce-->
<!--              v-model="selectedTemplate.text"-->
<!--              id="docs"-->
<!--              :init="init"-->
<!--          ></tinymce>-->
          <Editor
              id="docs"
              v-model="selectedTemplate.text"
              :init="init"
          />
        </div>
      </div>
      <div class="buttons">
        <el-button @click="print">Просмотр для печати</el-button>
        <el-button @click="revertChanges">Сбросить изменения</el-button>
        <el-button type="primary" @click="saveDocumentTemplate">Сохранить</el-button>
      </div>
    </div>

    <div class="for-print">
      <div v-html="selectedTemplate.text"></div>
    </div>

    <el-dialog
        title="Редактирование доступности полей для шаблона"
        :visible.sync="dialog"
        width="60%"
        center
    >

      <div class="header">Шаблон: {{ selectedTemplate.name }}</div>
      <div class="body">

        <div class="body-header">
          <div>Название</div>
          <div>Для вставки в шаблон</div>
          <div>
            <el-input
                v-model="search"
                placeholder="Введите 'Название' для поиска"
                @input="searchFields"></el-input></div>
        </div>
        <div class="body-content" v-model="switchValues">
          <div
              class="field"
              v-for="field in searchedFields"
              :key="field.id"
          >
            <div>{{ field.name_for_web }}</div>
            <div>{{ field.name }}</div>
            <div>
              <el-switch
                  v-model="switchValues[field.name+field.field_location]"
                  @change="editTemplateField(field)"
                  :inactive-value="false"
                  active-color="#ef3123"
                  inactive-color="#a7a7a7">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="footer"></div>

    </el-dialog>

    <el-dialog
        title="Добавление/редактирование шаблона документов"
        :visible.sync="documentTemplateDialog"
        width="60%"
        center
    >
      <div>
        <div class="form">
          <div class="form-header">
            <div>Код</div>
            <div>Название</div>
            <div>Порядок печати</div>
            <div></div>
          </div>
          <div class="form-body">
            <div>
              <el-input v-model="selectedTemplate.code"/>
            </div>
            <div>
              <el-input v-model="selectedTemplate.name"/>
            </div>
            <div>
              <el-input-number v-model="selectedTemplate.order"/>
            </div>
            <div>
              <el-switch
                  active-text="В общем пакете"
                  v-model="selectedTemplate.in_all_package"
                  :active-value="1"
                  :inactive-value="0"
              />
              <el-switch
                  style="margin-top: 20px"
                  active-text="Отложенная отгрузка"
                  v-model="selectedTemplate.in_delayed_loading_package"
                  :active-value="1"
                  :inactive-value="0"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <el-popconfirm
              style="margin-right: 10px;"
              v-if="selectedTemplate.id"
              @confirm="deleteSelectedDocumentTemplate"
              title="Вы уверены, что хотите удалить документ?"
          >
            <el-button slot="reference">Удалить</el-button>
          </el-popconfirm>


          <el-button @click="documentTemplateDialog = false">Отмена</el-button>
          <el-button @click="saveDocumentTemplate">Сохранить</el-button>
        </div>
      </div>


      <div class="footer"></div>

    </el-dialog>
  </div>
  <div v-else> Данный раздел вам не доступен</div>


</template>

<script>



import Editor from '@tinymce/tinymce-vue'
export default {
  name: "Documents",
  components: {Editor},
  data() {
    return {
      init:{
        selector: '#articletextarea',
        plugins: [
          'advlist autolink lists link image charmap preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars code',
          'insertdatetime media nonbreaking table contextmenu directionality',
          ' template paste textcolor colorpicker textpattern imagetools codesample table'
        ],
        toolbar: 'bold italic sizeselect fontselect fontsizeselect forecolor backcolor emoticons| hr alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image table | insertfile undo redo  | code',
        fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt " +
            "13pt 14pt 15pt 16pt 17pt 18pt " +
            "19pt 20pt 21pt 22pt 23pt 24pt " +
            "25pt 26pt 27pt 28pt 29pt 30pt 31pt 32pt " +
            "33pt 34pt 35pt 36pt 37pt 38pt " +
            "39pt 40pt 41pt 42pt 43pt 44pt 45pt 46pt 47pt 48pt",
        // height: "350",
        // menubar: false

      },



      search: '',
      searchedFields: [],
      templates: null,
      selectedTemplateId: null,
      selectedTemplate: {
        id: null,
        code: '',
        name: '',
        order: null,
        text: null,
        in_all_package: 1,
        in_delayed_loading_package: 0
      },
      selectedTemplateFields: [],
      switchValues: {},
      defaultContent: "",
      fields: [],
      dialog: false,
      documentTemplateDialog: false,
      loadingContent: false,
      exemplar: '{{first_name}}',
      tinyOptions: {
        default: 'removeformat'
      }

    }
  },
  mounted() {
    this.getDocuments()
  },
  watch: {
    'selectedTemplate.in_delayed_loading_package'() {
      if(this.selectedTemplate.in_delayed_loading_package) {
        this.selectedTemplate.in_all_package = 0
      }
    },
    'selectedTemplate.in_all_package'() {
      if(this.selectedTemplate.in_all_package) {
        this.selectedTemplate.in_delayed_loading_package = 0
      }
    },
  },
  methods: {
    editSelectedDocument() {

    },
    searchFields() {
      this.searchedFields = this.fields.filter(data => !this.search || data.name_for_web.toLowerCase().includes(this.search.toLowerCase()))
    },
    getDocuments() {
      this.loadingContent = true;
      this.$api.field.getDocumentTemplates()
          .then((data) => {
            this.templates = data
            this.loadingContent = false;
          })
    },
    getDocumentTemplateFields() {
      this.loadingContent = true
      this.$api.field.getFieldsByDocumentTemplateId({id: this.selectedTemplateId})
          .then((data) => {
            this.selectedTemplate = this.templates.find(item => item.id === this.selectedTemplateId);
            this.defaultContent = this.selectedTemplate.text
            this.selectedTemplateFields = data
            this.loadingContent = false
            this.setSelectedTemplateFieldsForSwitch();
          })
    },
    setSelectedTemplateFieldsForSwitch() {
      this.switchValues = {}
      this.selectedTemplateFields.forEach(item => {
        this.switchValues[item.field_name+item.field_location] = true
      })
    },
    deleteSelectedDocumentTemplate() {
      this.loadingContent = true
      this.$api.field.deleteDocumentTemplate({id: this.selectedTemplateId})
          .then((data) => {
            if(!data.errors) {
              this.templates = this.templates.filter(item => item.id !== this.selectedTemplateId);
              this.selectedTemplateId = null;
              this.selectedTemplate = {id: null, code: '', name: '', text: null}
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Документ удален',
              });
              this.documentTemplateDialog = false;
              this.loadingContent = false
            }
          })
    },
    showNewDocumentTemplateDialog() {

      this.selectedTemplate.id = null;
      this.selectedTemplate.name = "";
      this.selectedTemplate.code = "";
      this.selectedTemplate.order = null;
      this.selectedTemplate.text = "";
      this.selectedTemplate.in_all_package = 1;
      this.documentTemplateDialog = true;

    },
    saveDocumentTemplate() {

      if (!this.selectedTemplate.text && this.selectedTemplate.id) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Шаблон документа не может быть пустым',
        });
        return
      }
      this.loadingContent = true
      this.$api.field.saveDocumentTemplate({
        id: this.selectedTemplate.id,
        name: this.selectedTemplate.name,
        code: this.selectedTemplate.code,
        order: this.selectedTemplate.order,
        text: this.selectedTemplate.text,
        in_all_package: this.selectedTemplate.in_all_package,
        in_delayed_loading_package: this.selectedTemplate.in_delayed_loading_package,
      })
          .then((data) => {
            if (!data.errors) {
              this.selectedTemplate = data;
              this.selectedTemplateId = this.selectedTemplate.id
              this.defaultContent = this.selectedTemplate.text ? this.selectedTemplate.text : '';
              this.documentTemplateDialog = false;
              this.getDocuments()
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }
          })
    },
    showDialog() {
      this.$api.field.getFieldsForTemplates()
          .then((data) => {
            this.fields = data.filter(item => item.name_for_web)
            this.searchFields();
          })
      this.dialog = true;
    },

    editTemplateField(field) {
      if (this.switchValues[field.name+field.field_location]) {
        this.addFieldForDocumentTemplate(field)
      } else {
        this.deleteFieldForDocumentTemplate(field)
      }

    },
    addFieldForDocumentTemplate(field) {
      this.$api.field.addFieldForDocumentTemplate({
        field_name: field.name,
        field_location: field.field_location,
        document_template_id: this.selectedTemplate.id
      })
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
              this.getDocumentTemplateFields()
            }
          })
    },
    deleteFieldForDocumentTemplate(field) {
      this.$api.field.deleteFieldForDocumentTemplate({
        field_name: field.name,
        field_location: field.field_location,
        document_template_id: this.selectedTemplate.id
      })
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
              this.getDocumentTemplateFields()
            }
          })
    },
    revertChanges() {
      this.selectedTemplate.text = this.defaultContent
    },

    print() {
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(this.selectedTemplate.text);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  }
}
</script>

<style lang="scss" scoped>

.for-print {
  display: none;
}

.documents {
  i {
    cursor: pointer;
  }

  &-header, &-content {
    display: grid;
  }

  &-header {
    grid-template-columns: 2fr 1fr;
    position: relative;

    > div {
      &:last-child {
        position: relative;

        i {
          position: absolute;
          top: 3px;
          left: -50px;
          color: #ef3123;
          font-size: 36px;
          cursor: pointer;
        }
      }
    }

  }

  &-content {
    height: 70vh;
    overflow: hidden;
    margin-top: 10px;
    grid-template-columns: 1fr 4fr;
    padding-left: 10px;

    h5 {
      margin-bottom: 10px;
    }

    &__fields {
      > div {
        height: 59vh;
        overflow-y: auto;
      }

      .field {
        border: 1px solid #ddd;
        padding: 5px;
        border-bottom: none;

        > div {
          &:last-child {
            font-weight: bold;
          }
        }

        &:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }

    &__document {
      padding-left: 10px;
    }

    ::v-deep #docs {
      height: 55vh;
      overflow-y: auto;


      .mce-container-body.mce-flow-layout {
        display: none;
      }
      code {
        background: yellow;
      }
    }
  }

  ::v-deep .el-dialog {
    .header {
      font-size: 14px;
      margin-bottom: 10px;
      box-shadow: none;
      font-weight: bold;
    }

    .body {
      height: 59vh;
      overflow-y: auto;
      padding-bottom: 40px;
      .body-header, .body-content > div {
        display: grid;
        grid-template-columns: 2fr 3fr 2fr;
        column-gap: 10px;

      }

      .body-header {
        padding-bottom: 15px;

        align-items: center;
        .el-input {
          width: 90%;
        }
      }

      .body-content > div {
        margin-bottom: 5px;

        > div {
          padding: 5px 0;
          border-bottom: 1px solid #eaeaea;
          &:nth-child(2) {
            font-weight: bold;
          }
        }
      }
    }

    .form {
      margin-bottom: 20px;

      &-header, &-body {
        display: grid;
        grid-template-columns: 150px 1fr 150px 250px;
        column-gap: 40px;
        align-items: center;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    text-align: right;
    > * {
      &:last-child {
        background: #ef3123;
        color: #fff;
      };
    }
  }
}
</style>