<template>
  <button @click="handleClick" :disabled="disabled" :class="'my-button'+' '+propsVariant" type="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "my-button",
  props: {
    propsVariant: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  },
}
</script>

<style lang="scss" scoped>
.my-button {
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  background: inherit;
  color: #ef3123;
  padding: 10px 13px;
  border: solid 1px #ef3123;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  min-width: 100px;
  min-height: 40px;

  &.fill {
    background: #ef3123;
    color: #fff;
  }
  &.empty {
    background: #fff;
    color: #ef3123;
  }
  &:hover {
    transition: .3s;
    color: #c20e00;
    border: solid 1px #c20e00;

    &.fill {
      background: #c20e00;
      color: #fff;
    }
  }
  &:disabled{
    border: solid 1px gray;
    background: gray;
    pointer-events: none;
    color: #fff;
  }
}
</style>