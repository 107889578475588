

<template>

  <div>
    <h2>История по заявке</h2>
    <div v-if="$store.getters[`newApplication/leasing_application_id`]">
      <el-input
          v-model="search"
          size="mini"
          placeholder="Поиск..."/>
      <el-table
          class="logs-table"
          :data="logs.filter(data => !search  || data.leasing_application_id.toString().includes(search))"
          style="width: 100%">
        <el-table-column
            label="Дата создания"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <span> {{convertDate(scope.row.created_at).date}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Время создания"
            width="100"
            align="center"
        >
          <template slot-scope="scope">
            <span> {{convertDate(scope.row.created_at).time}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Событие"
            prop="action.name"
            width="300"
        >
        </el-table-column>
        <el-table-column
            label="Тип события"
            prop="action.type"
            width="140"
        >
        </el-table-column>
        <el-table-column
            label="Кто выполнил"
            width="200"

        >
          <template slot-scope="scope">
            <span>{{scope.row.who.first_name}} {{scope.row.who.last_name}} {{scope.row.who.patronym}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Роль"
            prop="who.role.description">
        </el-table-column>
        <el-table-column
            label="Комментарий"
        >
          <template slot-scope="scope">
            <span>{{scope.row.comment ? scope.row.comment.message : null}}</span>
          </template>
        </el-table-column>


      </el-table>
      <paginator
          v-if="totalCount"
          :paginator.sync="paginator"
          @page-changed="getAll"
      ></paginator>
    </div>
    <div v-else>Заявка не выбрана</div>

  </div>
</template>
<script>
import Paginator from "@/components/UI/Paginator.vue";
import {paginator} from "@/mixins/paginator";

export default {
  name: "application-logs",
  components: {Paginator},
  mixins: [paginator],
  data() {
    return {
      totalCount: 0,
      logs: [],
      search: '',
    }
  },
  mounted() {
    this.paginator.page = 1
    this.paginator.perPage = 100
    this.getAll()
  },
  methods: {
    getAll() {
      this.$api.administration.getLogs({
        leasing_application_id: this.$store.getters[`newApplication/leasing_application_id`],
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator}) => {

            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount
            this.logs = data
          })
    },
    convertDate(date) {
      return {
        date: new Date(date).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(date).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .logs-table {
  font-size: 9px;
  .el-table__row {
    td.el-table__cell{
      font-size: 10px;
      padding: 2px 0;
    }

  }
}
</style>