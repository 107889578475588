<template>
  <div>
    <h3>{{field['name_for_web'] }}</h3>
    <div class="checkbox-block field-group">
      <edit-field
          v-if="$store.getters.user.role.name === 'admin'"
          :field="field"
      />
      <el-checkbox-group
              v-model="state"
              :name="field.name"
              :disabled="isDisabled"
      >
        <my-input-checkbox
            v-for="checkbox in field.data"
            :key="checkbox.id"
            :label="checkbox.id"
        >
          {{checkbox.name}}
        </my-input-checkbox>
      </el-checkbox-group>

    </div>
  </div>
</template>

<script>
import EditField from "@/components/general/EditField.vue";
import MyInputCheckbox from "@/components/UI/MyInputCheckbox.vue";

export default {
  name: "my-input-checkbox-group",
  components: {MyInputCheckbox, EditField},
  props: {
    field: {}
  },
  watch:{
    state(){
      this.$store.commit(`newApplication/set_${this.field.name}`,this.state)
    }
  },
  computed: {
    isDisabled() {
        return this.$store.getters['newApplication/is_disabled'];
      // let status = this.$store.state.newApplication.leasing_application_status_id
      // return status && status !== this.$constants.statuses.created
    }
  },
  data() {
    return {
      state: []
    }
  },
  mounted() {
    this.setDefaultState()
  },
  methods: {
    setDefaultState(){
      if(!this.$store.state.newApplication[this.field.name]) {

        this.state = this.field.data.filter(item => item.default_selected)
        this.state = this.state.map(({id}) => id)

      }else {
        this.state = this.$store.state.newApplication[this.field.name]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox-group {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  @media (max-width: 1370px) {
    grid-template-columns: 1fr;
  }
}
</style>