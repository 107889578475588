<template>
  <div style="height: 300px;">
    <el-steps :active="active" direction="vertical" finish-status="success">
      <el-step title="Шаг первый 1"  />
      <el-step title="Step 2" />
      <el-step title="Step 3" />
    </el-steps>

    <el-button style="margin-top: 12px" @click="next">Next step</el-button>
  </div>

</template>

<script>
export default {
  name: "my-steps",
  data(){
    return{
      active:1
    }
  },
  methods:{
    next(){
      if (this.active++ > 2) this.active = 1
    },
    test(){
      console.log(1111)
    }
  }
}
</script>

<style scoped>

</style>
