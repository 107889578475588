<template>
  <div>
    <div>

      <h4>Партнеры</h4>
    </div>
      <div class="search">
        <div @keyup.enter="getAll">
          <el-input
              placeholder="Продавец, УНП, № договора"
              v-model="search"
          >
            <my-button slot="append" icon="el-icon-search" @click="getAll">Поиск</my-button>
          </el-input>
        </div>
      </div>
    <div class="command-buttons">
      <el-button
          class="add"
          icon="el-icon-circle-plus"
          circle
          @click="add"
      />
    </div>

    <el-table
        :data="partners"
        height="calc(86vh - 200px)"
        style="overflow: auto;"
        @row-click="edit"
    >

      <el-table-column
          prop="id"
          label="ID"
          width="40px"
      >
      </el-table-column>

      <el-table-column
          label="Продавец"
          prop="name"
          sortable
          width="150px"
      >
      </el-table-column>

      <el-table-column
          label="Код"
          prop="code"
          width="80px"

      >
      </el-table-column>

      <el-table-column
          label="УНП"
          prop="unp"
          width="80px"
      >
      </el-table-column>

      <el-table-column
          label="Юридический адрес"
          prop="address"
          width="200px"
      >
      </el-table-column>
      <el-table-column
          label="№ договора"
          prop="contract_number"
          sortable
          width="150px"
      >
      </el-table-column>
      <el-table-column
          label="Дата договора"
          prop="contract_date"
          sortable
          width="130px"
      >
      </el-table-column>

      <el-table-column
          label="Банковские реквизиты"
          prop="bank_details"

      >

      </el-table-column>

      <el-table-column
          label="Статус"
          width="100px"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.is_active ? 'Активен' : 'Деактивирован' }}</div>
        </template>
      </el-table-column>
    </el-table>

    <paginator
        v-if="totalCount"
        :paginator.sync="paginator"
        @page-changed="getAll"
    ></paginator>


    <el-drawer
        :visible.sync="partnerDrawer"
        direction="rtl"
        :before-close="clear"
        size="50%"
    >
      <div class="content">
        <el-form
            :data="selectedPartner"
            label-position="left"
            label-width="150px"
            size="small"
            width="90%"
        >
          <el-form-item label="Наименование">
            <el-input v-model="selectedPartner.name"></el-input>
          </el-form-item>
          <el-form-item label="код">
            <el-input v-model="selectedPartner.code"></el-input>
          </el-form-item>
          <el-form-item label="УНП">
            <el-input v-model="selectedPartner.unp"></el-input>
          </el-form-item>
          <el-form-item label="№ договора">
            <el-input v-model="selectedPartner.contract_number"></el-input>
          </el-form-item>
          <el-form-item label="Дата договора">
            <el-date-picker
                v-model="selectedPartner.contract_date"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="Юридический адрес">
            <el-input type="textarea" v-model="selectedPartner.address"></el-input>
          </el-form-item>
          <el-form-item label="Банковские реквизиты">
            <el-input type="textarea" v-model="selectedPartner.bank_details"></el-input>
          </el-form-item>
          <el-form-item label="Активен">
            <el-switch
                v-model="selectedPartner.is_active"
                active-color="#ef3123"
                inactive-color="#eeeeee"
                :active-value="1"
                :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Работают с отложенной отгрузкой">
            <el-switch
                v-model="selectedPartner.delayed_loading"
                active-color="#ef3123"
                inactive-color="#eeeeee"
                :active-value="1"
                :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Подпись по СМС">
            <el-switch
                v-model="selectedPartner.sms_signature"
                active-color="#ef3123"
                inactive-color="#eeeeee"
                :active-value="1"
                :inactive-value="0"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="">
            <div style="color: #ef3123; font-size: 11px; line-height: 1.3;">При <b
                style="font-size: 13px;">декактивации</b> партнера, все торговые точки и пользователи, принадлежащие
              данным подразделениям так же деактивируются. При <b style="font-size: 13px;">активации</b> - активация
              пользователей не применяется
            </div>
          </el-form-item>

          <div class="controls">
            <my-button props-variant="empty" @click="clear">Отмена</my-button>
            <my-button props-variant="fill" @click="save">Сохранить</my-button>
          </div>

        </el-form>
        <div v-if="selectedPartner.id">
          <span>Доступные продукты <i class="el-icon-s-operation"
                                      @click="showOffersDrawer(selectedPartner.id)"></i></span>
        </div>
      </div>

      <el-drawer
          title="Доступные продукты:"
          :append-to-body="true"
          size="45%"
          :visible.sync="partnerInnerDrawer">
        <div class="inner-content">
          <div>
            <div>
              Уменьшение/Увеличение процентной ставкии по партнеру в %
            </div>
            <div style="display: flex; gap: 30px; align-items: center; margin-top: 10px;">
              <el-input-number
                  :controls="false"
                  v-model="selectedPartner.rate_adjustment"
              >
              </el-input-number>
              <el-popconfirm
                  confirm-button-text='Применить'
                  cancel-button-text='Отмена'
                  @confirm="changeRateAdjustment"
              >
                <my-button  slot="reference">Изменить</my-button>
              </el-popconfirm>
            </div>
          </div>
          <el-table
              :data="partnerLeasingOffers"
              style="width: 100%;"
              class="leasing-offers-variants"
          >
            <el-table-column
                label="Продукт"

                width="250px"
            >
              <template slot-scope="props">
                <div style="font-size: 10px; color: #706969; text-align: left">Название продукта</div>
                <div class="offer-name">{{ props.row.name }}</div>
                <div style="font-size: 10px; color: #706969; text-align: left">Вид продукта</div>
                <div class="offer-name" style="font-size: 10px; color: #706969;">{{ props.row.offer_type.name }}</div>
              </template>
            </el-table-column>

            <el-table-column
                label="Количество месяцев"
                width="100px"
            >
              >
              <template slot-scope="props">
                <div
                    style="display: flex; justify-content: center;"
                    v-for="term in props.row.offers_terms"
                    :key="props.row.name + '-'+ term.term.name"
                >
                  <div class="variant-name">{{ term.term.name }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
                label="Компенсация поставщика в %"
                width="180px"
            >
              >

              <template slot-scope="props">
                <div v-for="term in props.row.offers_terms" :key="props.row.name + '---'+ term.id">
                  <el-input-number
                      v-if="props.row.leasing_offer_type_id !== 1"
                      :controls="false"
                      :value="term.partner_offers_terms.length ? term.partner_offers_terms[0].dealer_compensation_percent : 0"
                      placeholder="введите значение"
                      :disabled="!term.partner_offers_terms.length"
                      @change="changeDealerCompensationPercent($event, term)"
                  ></el-input-number>
                </div>

              </template>
            </el-table-column>

            <el-table-column
                label="Активность">
              >
              <template slot-scope="props">
                <div
                    v-for="term in props.row.offers_terms"
                    :key="props.row.name + '-'+ term.id + term.leasing_offer_id"
                >
                  <el-switch
                      @change="changePartnerOfferTerm(term.id)"
                      :value="term.partner_offers_terms.length"
                      active-color="#ef3123"
                      :active-value="1"
                      :inactive-value="0"
                  >
                  </el-switch>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-drawer>

    </el-drawer>

  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import Paginator from "@/components/UI/Paginator.vue";
import {paginator} from "@/mixins/paginator";

export default {
  name: "partners",
  components: {Paginator, MyButton},
  mixins: [paginator],
  data() {
    return {
      partnerDrawer: false,
      partnerInnerDrawer: false,
      partners: [],
      totalCount: 0,
      search: '',
      partnerRateAdjustment: null,
      selectedPartner: {
        id: null,
        name: null,
        code: null,
        unp: null,
        address: null,
        bank_details: null,
        delayed_loading: 0,
        sms_signature: 0,
        contract_number: null,
        contract_date: null,
        genera_router_id: null,
        general_rp_id: null,
        is_active: 1
      },
      partnerLeasingOffers: [],
      generalRouters: [],
      generalRps: [],
    }
  },
  mounted() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.$api.administration.getPartners({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        search: this.search,
      })
          .then(({data, paginator}) => {
            this.paginatorFill(paginator);
            this.totalCount = paginator.totalCount
            this.partners = data
          })
    },
    changeRateAdjustment() {
      this.$api.administration.changeRateAdjustment({
        partner_id: this.selectedPartner.id,
        rate_adjustment: this.selectedPartner.rate_adjustment,
      })
          .then((data) => {
              this.selectedPartner = data;
          })
    },
    showOffersDrawer(id) {
      this.partnerInnerDrawer = true;
      this.getLeasingOffersForPartner(id)
    },
    getLeasingOffersForPartner(id) {
      this.$api.administration.getLeasingOffersForPartner({
        partner_id: id
      })
          .then((data) => {
            this.partnerLeasingOffers = data.map(item => {
              item.offers_terms = item.offers_terms.map(offersTerm => {
                offersTerm.term = item.terms.find(term => term.id === offersTerm.leasing_term_id)
                return offersTerm;
              })
              item.offers_terms = item.offers_terms.sort((a, b) => +a.term.name > +b.term.name ? 1 : -1)
              return item;
            })



          })
    },
    changePartnerOfferTerm(id) {

      this.$api.administration.changePartnerOfferTermStatus({
        leasing_offers_term_id: id,
        partner_id: this.selectedPartner.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationError,
              title: 'Успешно',
              message: 'Данные сохранены',
            });
            this.getLeasingOffersForPartner(this.selectedPartner.id)
          })
    },
    changeDealerCompensationPercent(value, data) {
      this.$api.administration.changePartnerOfferTermDealerCompensationPercent({
        id: data.partner_offers_terms[0].id,
        dealer_compensation_percent: value
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationError,
              title: 'Успешно',
              message: 'Данные сохранены',
            });
            this.getLeasingOffersForPartner(this.selectedPartner.id)
          })
    },
    add() {
      this.selectedPartner = {
        id: null,
        name: null,
        code: null,
        unp: null,
        address: null,
        bank_details: null,
        is_active: 1
      }
      this.partnerDrawer = true
    },
    edit(partner) {
      this.selectedPartner = partner;
      this.partnerDrawer = true
    },
    save() {
      this.$api.administration.savePartner(this.selectedPartner)
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }
            this.partnerDrawer = false
            this.getAll()
          })
    },
    clear() {
      this.selectedPartner = {
        id: null,
        name: null,
        code: null,
        unp: null,
        address: null,
        bank_details: null,
        is_active: 1
      }
      this.partnerDrawer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.command-buttons {
  text-align: right;

  .add, .remove {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 24px;

    &:hover {
      opacity: .8;
      scale: 1.1;
    }
  }
}

.search {
  margin: 0.5rem 0;
  .el-input-group__append {
    background-color: transparent;
    color: transparent;
  }
  ::v-deep .el-input {
    margin-top: 5px;
    &-group {
      &__append {
        background-color: transparent !important;
        border: none;
        padding: 0 5px;
      }
    }
  }
}


::v-deep .el-table {
  &__header {
    thead {
      th {
        background: #eeeeee;
        color: #555;
        font-weight: 600;
      }
    }

    &-wrapper {

    }
  }

  tr {

  }

  &__cell {
    width: 10px !important;
    padding: 0.2rem 0;
    font-size: 10px;


    .status {
      border-radius: 5px;
      text-align: center;
      font-size: 10px;
    }

  }
}

::v-deep.el-pagination {
  margin-top: .5rem;
  text-align: center;
}

::v-deep .el-drawer {
  .content {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 30px;
    padding: 0 2rem 0 3rem;

    .el-form {

      .el-select {
        width: 100%;
      }

      .controls {
        text-align: right;

        > button {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }

    > div {
      &:last-child {
        > span {
          display: flex;
          align-items: center;
          gap: 10px;

          i {
            font-size: 24px;
            margin-top: -2px;
            cursor: pointer;
            //color: #ef3123;
          }
        }

      }
    }
  }

  .inner-content {
    padding: 0 1rem;

    > div {
      &:first-child {
        margin-bottom: 1rem;
        font-weight: bold;

        .el-input {
          margin-left: 1rem;

          &__inner {
            height: 32px;
          }
        }
      }
    }

    .leasing-offers-variants {
      .el-table {
        &__header {
          thead {
            th {
              text-align: center;
              font-size: 12px;
            }
          }
        }

        &__body {
          &-wrapper {

          }
        }

        &__row {
          td {
            text-align: center;
            padding: 1rem 0;
            font-size: 16px;

            .el-switch, .variant-name {
              margin: .5rem 0;
            }

            .offer-name {
              text-transform: uppercase;
              font-weight: bold;
              text-align: left;
              font-size: 12px;
            }
          }

          .el-input {
            width: 96%;
            &__inner {
              width: 95%;
              margin-left: -2px;
              height: 30px;
              line-height: 30px;
            }

          }
        }
      }
    }
  }
}
</style>