<template>
  <aside class="page-menu">
    <button
        @click="selectPageMenu(1)"
        :class="{ active: state === 1 }"
    >
      <span></span>
      Документы
    </button>
    <button
        :disabled="true"
        @click="selectPageMenu(2)"
        :class="{ active: state === 2 }"
    >
      Поля форм
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(3)"
        :class="{ active: state === 3 }"
    >
      <span></span>
      Лизинговые предложения
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(4)"
        :class="{ active: state === 4 }"
    >
      <span></span>
      Партнеры
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(5)"
        :class="{ active: state === 5 }"
    >
      <span></span>
      Торговые точки
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(6)"
        :class="{ active: state === 6 }"
    >
      <span></span>
      Сотрудники
    </button>
    <button
        :disabled="true"
        @click="selectPageMenu(7)"
        :class="{ active: state === 7 }"
    >
      <span></span>
      Справочник
    </button>
    <button
        :disabled="true"
        @click="selectPageMenu(8)"
        :class="{ active: state === 8 }"
    >
      <span></span>
      Роли
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(9)"
        :class="{ active: state === 9 }"
    >
      <span></span>
      Принадлежность
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(10)"
        :class="{ active: state === 10 }"
    >
      <span></span>
      СМС документы
    </button>
    <button
        :disabled="false"
        @click="selectPageMenu(11)"
        :class="{ active: state === 11 }"
    >
      <span></span>
      Логирование
    </button>
  </aside>
</template>

<script>
export default {
  name: "administration-menu",
  props: {
    state: {}
  },
  methods: {
    selectPageMenu(id) {
      this.$emit('update', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-menu {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 2px 4px 0 #00000040;
  display: grid;
  overflow: hidden;

  button {
    cursor: pointer;
    padding: 20px 15px;
    border: none;
    background: #fff;
    border-bottom: solid 1px #bababa;
    transition: .3s;
    position: relative;
    @media(max-width: 1024px) {
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    span{
      background: #fff;
      width: 5%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

    }

    &.active {
      position: relative;
      span{
        background: #ef3123;
        width: 5%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      //background: #ef3123;
      //color: #fff;
    }
  }
}
</style>