<template>
    <div
            class="chart-payment-header"
            :style="{display:show ? 'block' : 'none'}"
    >
        <div style="width:210mm;" v-if="Object.keys(data).length !== 0">
            <div style="
                     display: grid;
                     grid-template-columns: 1fr 1fr;
                    "
            >
                <div style="text-align: left; max-width: 100px; height: 25px; margin-left:20px;">
                    <img src="../../assets/img/logo-black.png" alt="" style="max-width: 100%">
                </div>
                <div style="text-align: right; margin-right: 20px;">

                </div>
            </div>

            <h3 style="text-align: center; margin-bottom: 5px;">ГРАФИК ЛИЗИНГОВЫХ ПЛАТЕЖЕЙ</h3>
            <div style="padding: 5px 10px; border-bottom: 1px solid #ddd;border-top: 1px solid #ddd">
                <div style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          margin-bottom: 10px;
                         "
                >

                    <div>Клиент</div>
                    <div style="text-align: right">{{ withoutFullName ? '' : fullName }}</div>
                </div>

                <div style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          margin-bottom: 5px;
                         "
                >

                    <div>Стоимость предмета лизинга, с НДС</div>
                    <div style="text-align: right">{{
                            (data.general.full_price).toFixed(2)
                        }} BYN
                    </div>
                </div>

                <div style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          margin-bottom: 5px;
                         "
                >
                    <div>Аванс, %</div>
                    <div style="text-align: right">{{ (data.general.first_payment_percent * 100).toFixed(2) }}%</div>
                </div>

                <div style="
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  margin-bottom: 5px;
                 "
                >

                    <div>Сумма Аванса</div>
                    <div style="text-align: right">{{ (data.general.first_payment).toFixed(2) }} BYN</div>
                </div>

                <div style="
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  margin-bottom: 5px;
                 "
                >

                    <div>Срок лизинга</div>
                    <div style="text-align: right">{{ data.general.leasing_term + ' мес.' }}</div>
                </div>

                <div style="
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  margin-bottom: 5px;
                 "
                >

                    <div>Схема погашения КС</div>

                    <div style="text-align: right">{{
                        data.general.calculate_option === 1 ? 'Убывающий' : 'Аннуитет'
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "chart-payment-header",
    props: {
        data: {},
        withoutFullName: {
          type: Boolean,
          default: false
        },
        show: {
            Type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {
        fullName() {
            return this.$store.getters["newApplication/last_name"] + ' ' +
                this.$store.getters["newApplication/first_name"] + ' ' +
                this.$store.getters["newApplication/patronymic"]
        },
        currentDate() {
            return this.convertDate(new Date)
        }
    },
    methods: {
        convertDate(data) {
            return new Date(data).toLocaleDateString('ru-Ru',
                {
                    'month': "2-digit",
                    'day': "2-digit",
                    'year': 'numeric'
                })
        },
    }
}
</script>

<style scoped>

</style>