<template>
  <div class="menu">
    <div class="menu__top">
      <img src="../../assets/img/logo-black.png" alt="logo">
      <button @click="closeDrawer" class="close-cross">
        <i class="el-icon-close"></i>
      </button>
    </div>

    <div class="menu__list">
      <nav>
        <div @click="closeDrawer" :class="propsActiveMenu === 1?'active':''" data-menu="1">
          <router-link to="/">Главная</router-link>
        </div>
        <div @click="closeDrawer" :class="propsActiveMenu === 2?'active':''" data-menu="2">
          <router-link @click="closeDrawer" to="/new-applications">Новая заявка</router-link>
        </div>
        <div @click="closeDrawer" :class="propsActiveMenu === 3?'active':''" data-menu="3">
          <router-link @click="closeDrawer" to="/list-of-applications">Список заявок</router-link>
        </div>
        <div @click="closeDrawer" :class="propsActiveMenu === 6?'active':''" data-menu="6">
          <router-link @click="closeDrawer" to="/list-of-applications-archive">Архив заявок</router-link>
        </div>
        <!--        <div @click="closeDrawer" :class="propsActiveMenu === 4?'active':''" data-menu="4">-->
        <!--          <router-link @click="closeDrawer" to="/help">Справка</router-link>-->
        <!--        </div>-->
        <div @click="closeDrawer" :class="propsActiveMenu === 4?'active':''" data-menu="4">
          <router-link @click="closeDrawer" to="/calculator">Калькулятор</router-link>
        </div>
        <div @click="closeDrawer" :class="propsActiveMenu === 5?'active':''" data-menu="5">
          <router-link @click="closeDrawer" to="/administration">Администрирование</router-link>
        </div>
        <div
            v-if="showDocumentStatuses"
            @click="closeDrawer" :class="propsActiveMenu === 7?'active':''" data-menu="7">
          <router-link @click="closeDrawer" to="/document-statuses">Статус документов</router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobile-header-menu",
  props: {
    closeProps: {
      type: Function
    },
    propsActiveMenu: {
      type: Number
    },
    propsGetActualMenu: {
      type: Function
    }
  },
  computed: {
    showDocumentStatuses() {
      const roles = ['admin', 'head_of_rp', 'general_rp', 'general_router']
      return !!(roles.includes(this.$store.getters.user.role.name) && this.$store.getters.user.id)
    }
  },
  methods: {
    closeDrawer(event) {
      this.propsGetActualMenu(event)
      this.closeProps()
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {

}

.menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 3px 0px #00000040;
  padding: 10px;

  img {
    max-width: 90px;
    width: 100%;
  }

  button {
    border: none;
    background: inherit;
    cursor: pointer;

    i {
      font-size: 30px;
    }
  }
}

.menu__list {
  padding: 10px;

  nav {
    display: grid;

    align-items: center;
    width: 100%;

    div {
      border-bottom: solid 1px #bababa;
      width: 100%;
      display: flex;

      &.active {
        a {
          color: #ef3123 !important;
        }
      }

      a {
        padding: 10px;
        font-size: 16px;
        color: #1A2122 !important;
        width: 100%;


      }
    }

  }
}
</style>
