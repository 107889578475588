var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.renderObject)?_c('div',{staticClass:"analisys"},[_c('field-group',{attrs:{"fields":[
              _vm.renderObject['visual_assessment_types'].field
              ]}}),_c('el-divider'),_c('field-group',{attrs:{"fields":[

              _vm.renderObject['document_types'].field,
              _vm.renderObject['series_and_number'].field,
              _vm.renderObject['who_issued_types'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['date_of_issue'].field,
              _vm.renderObject['expiration_date'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['basic_income_types'].field,
              _vm.renderObject['basic_income_size'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['additional_income_types'].field,
              _vm.renderObject['additional_income_size'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['used_loans_types'].field,
              _vm.renderObject['monthly_payment'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['experience_on_last_place_types'].field,
              {},
              _vm.renderObject['number_of_children_under_18'].field ]}}),_c('field-group',{attrs:{"fields":[
              _vm.renderObject['criminal_liability'].field,
              {},
              _vm.renderObject['debt_collection'].field ]}}),_c('div',[_c('div',{staticClass:"btn-block"},[_vm._m(0),_c('my-button',{staticClass:"next",attrs:{"props-variant":"fill","disabled":_vm.saveIsDisabled},on:{"click":_vm.goNextStep}},[_vm._v("Сохранить")])],1)])],1):_vm._e(),(!_vm.renderObject)?_c('my-skeleton',{attrs:{"variant":'analisys'}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-block__wrapper"},[_c('div')])}]

export { render, staticRenderFns }