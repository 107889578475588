<template>
  <div>

    <div style="display: flex; justify-content: space-between">
      <div>
        <phone-number-matches v-if="
              $store.state.user.role.name === 'admin' &&
              $store.getters['newApplication/leasing_application_id'] &&
              $store.getters['newApplication/leasing_application_status_id'] >= $constants.statuses.appInProcessing"
        />
      </div>


      <div class="at_work" v-if="$store.state.user !== 'undefined' && $store.state.user.role.name === 'admin'">
        <div>
          <my-button
              :disabled="!!$store.getters['newApplication/who_took_the_job']"
              @click="setWhoTookTheJob"
          >
            Взять в работу
          </my-button>
          <div v-if="$store.getters[`newApplication/who_took_the_job`]">
            {{ $store.getters[`newApplication/who_took_the_job`].full_name }}: {{ getTookTheJobDate }}
          </div>
        </div>
        <div v-if="isShowWhoTookDocuments">
          <my-button
              style="margin-top: 15px;"
              :disabled="isDisabledWhoTookDocuments"
              @click="setWhoTookDocuments"
          >
            Взять документы
          </my-button>
          <div v-if="$store.getters[`newApplication/who_took_documents`]">
            {{ $store.getters[`newApplication/who_took_documents`].full_name }}: {{ getTookDocumentsDate }}
          </div>
        </div>


      </div>
    </div>


    <div class="chart" v-if="renderObject">

      <div
          v-if="$store.state.newApplication.leasing_application_status_name"
          class="leasing-application-status"
      >
        <div>
          <span>Заявка № </span>
          <span>{{ $store.getters['newApplication/app_number'] }}</span>
        </div>
        <div>
          <span>Статус заявки: </span>
          <span>{{ $store.getters['newApplication/leasing_application_status_name'] }}</span>
        </div>
      </div>

      <div class="subject-group">
        <div class="fields">

          <leasing-subject-input-group
              :max-price="maxPrice"
              :field="renderObject['leasing_subjects'].field"
          >
            <p
                v-if="renderObject['leasing_subjects'].field.name_for_web"
                style=" color: #1A2122; font-weight: 500; margin-bottom: 15px;"
            >
              {{ renderObject['leasing_subjects'].field.name_for_web }}
            </p>
            <p v-else style="color: #fff;">__</p>
          </leasing-subject-input-group>
        </div>
      </div>
      <div v-if="$store.getters['newApplication/get_leasing_offer_id']">
        <h6 style="font-size: .7em;">Максимальная сумма:<span
            style="color: #ef3123"> {{ maxPrice }}</span><span> BYN</span></h6>
      </div>
      <field-group
          :fields="[
            {},
            renderObject['leasing_subject_all_price'].field,
          ]"
      />

      <div>

        <p
            style="color: #1A2122;
            font-weight: 500;
            margin-bottom: 5px;"
        >
          Лизинговые предложения
        </p>
        <el-select
            style="margin-bottom: 10px;"
            :value="$store.getters['newApplication/get_leasing_offer_id']"
            @change="setSelectedOffer"
        >
          <el-option
              v-for="offer in partnerOffers"
              :key="offer.id"
              :label="offer.name"
              :value="offer.id"
          >
          </el-option>
        </el-select>

        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id']"
            :class="'one-row'"
            :fields="[selectedOffer]"
        />

        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id']"
            :class="'one-row'"
            :fields="[selectedOfferTerms]"
        />

        <field-group
            v-if="$store.getters['newApplication/get_leasing_offer_id'] && $store.getters['newApplication/get_leasing_offer_type_id'] !== $constants.offers.installment"
            :fields="[
             renderObject['calculation_options'].field,
             {},
             {}
        ]"
        />
      </div>

      <field-group
          :fields="[
             renderObject['last_name'].field,
             renderObject['first_name'].field,
             renderObject['patronymic'].field,
        ]"
      />
      <field-group
          :fields="[

              renderObject['gender_types'].field,
              renderObject['nationality_types'].field,
              ]"
      />
      <field-group
          :fields="[
             renderObject['personal_number'].field,
             renderObject['date_of_birth'].field,
             renderObject['phone_number'].field,
        ]"
      />

      <div class="btn-block-2">
        <div class="btn-block-2__wrapper" style="justify-content: start; gap: 30px">
          <my-button
              v-loading="wait"
              @click="saveData(getDocumentObtainingConsent)"
              :disabled="smsDisabled"
          >
            Распечатать согласия
          </my-button>
          <my-button
              v-loading="wait"
              @click="setAssentDate"
              :disabled="smsDisabled"
          >
            Согласия подписаны
          </my-button>
          <my-button
              class="sms"
              v-if="$store.getters.user.sms_signature || $store.getters.user.role.name === 'admin'"
              v-loading="wait"
              @click="saveData(showSMSPopup)"
              :style="{backgroundColor: smsButtonBg}"
              :disabled="smsDisabled"
          >{{ smsButtonText }}
          </my-button>
        </div>
      </div>


      <div style="display: grid; grid-template-columns: 2fr 1fr;">
        <div>
          <div v-if="oldApps && $store.getters.user.role.name === 'admin'" class="old-apps">
            <h5>Предыдущие заявки по клиенту:</h5>
            <el-table
                :data="oldApps"
                style="width: 100%"
            >
              <el-table-column
                  type="index"
                  label="№"
                  width="50">
              </el-table-column>
              <el-table-column
                  label="Номер заявки"
                  width="120">
                <template slot-scope="scope">
                  <a href="#" @click="openOldApp(scope.row.id)">{{ scope.row.app_number }}</a>
                </template>
              </el-table-column>
              <el-table-column
                  label="Дата заявки"
              >
                <template slot-scope="scope">
                  <span>{{ convertOldAppsDate(scope.row.created_at).date }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="Дата договора"
              >
                <template slot-scope="scope">
                  <span>{{ convertOldAppsDate(scope.row.app_date).date }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="status.name"
                  label="Статус"
              >
              </el-table-column>
              <el-table-column
                  label="Сумма"
              >
                <template slot-scope="scope">
                  <span>{{
                      scope.row.leasing_subjects.length ? getFullSubjectsPrice(scope.row.leasing_subjects) : 'Не определено'
                    }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div>
          <div class="btn-block">
            <div>
              <my-button @click="openChart" :disabled="disabledChart">Показать график</my-button>
              <my-button :disabled="saveIsDisabled" :props-variant="'fill'" @click="saveChartData">Сохранить</my-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        width="220mm"
        v-loading="chartLoading"
        :visible.sync="chartDialogIsOpen"
    >
      <div v-if="chartData">
        <div ref="chartData" class="chart-data">
          <chart-payment-header :data="chartData" :show="true" ref="chartPaymentHeader"/>
          <chart-payment-by-month :data="chartData" :show="true" ref="chartPaymentByMonth"/>
          <!--                    <chart-payment-footer :data="chartData" :show="true" ref="chartPaymentFooter"/>-->
        </div>
        <div style="text-align: right; margin-top: 30px;">
          <el-button @click="forPrint">На печать</el-button>
        </div>
      </div>

    </el-dialog>

    <el-dialog
        :fullscreen="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="smsPopupVisible"
        class="sms"
    >
      <div class="first-stage-sms-content" :class="{active: smsStage === 1}">
        <div>
          <h5 style="margin-bottom: 10px;">
            <span style="margin-right: 16%;">Введите код проверки номера телефона</span>
            <span style="color:red">{{ countDownMinutesWithSeconds }}</span>
          </h5>
          <el-input v-model="firstStageSmsCode"/>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px;">
          <el-button @click="smsPopupVisible = !smsPopupVisible">Отмена</el-button>
          <el-button @click="sendFirstStageSmsCodeToCheck">Отправить на проверку</el-button>
        </div>
      </div>
      <div class="second-stage-sms-content" :class="{active: smsStage === 2}">
        <div>
          <h5 style="margin-bottom: 10px;">
            <span style="margin-right: 24%;">Введите код подписания документа</span>
            <span style="color:red">{{ countDownMinutesWithSeconds }}</span>
          </h5>
          <el-input v-model="secondStageSmsCode"/>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px;">
          <el-button @click="smsPopupVisible = !smsPopupVisible">Отмена</el-button>
          <el-button @click="sendSecondStageSmsCodeToCheck">Отправить на проверку</el-button>
        </div>
      </div>

    </el-dialog>
    <my-skeleton v-if="!renderObject" :variant="'chart'"/>
  </div>

</template>

<script>
import MyInputText from "@/components/UI/MyInputText.vue";
import MyInputSwitch from "@/components/UI/MyInputSwitch.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MyInputNumber from "@/components/UI/MyInputNumber.vue";
import MyInputPhone from "@/components/UI/MyInputPhone.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySkeleton from "@/components/UI/MySkeleton.vue";
import FieldGroup from "@/components/general/fieldGroup.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import LeasingSubjectInputGroup from "@/components/UI/LeasingSubjectInputGroup.vue";
import ChartPaymentHeader from "@/components/chartPayment/ChartPaymentHeader.vue";
import ChartPaymentByMonth from "@/components/chartPayment/ChartPaymentByMonth.vue";
import ChartPaymentFooter from "@/components/chartPayment/ChartPaymentFooter.vue";
import PhoneNumberMatches from "@/components/NewApplicationView/PhoneNumberMatches/PhoneNumberMatches.vue";

export default {
  name: "chart-formation",
  components: {
    PhoneNumberMatches,
    ChartPaymentFooter, ChartPaymentByMonth, ChartPaymentHeader,
    LeasingSubjectInputGroup,
    FieldGroup, MySkeleton, MyButton, MyInputPhone, MyInputNumber, MySelect, MyInputSwitch, MyInputText
  },
  props: {
    propsChangeMenuState: {
      type: Function
    }
  },
  data() {
    return {
      oldApps: null,
      selectedOfferTerms: {},
      selectedOffer: {
        id: null,
      },
      partnerOffers: [],
      chartLoading: false,
      savedData: false,
      loadData: false,
      approved: false,
      fieldsData: null,
      renderObject: null,
      chartData: null,
      chartDialogIsOpen: false,
      statusName: '',
      appNumber: '',
      wait: false,
      smsPopupVisible: false,
      smsCode: null,
      firstStageSmsCode: null,
      secondStageSmsCode: null,
      countDown: 0,
      countDownMinutesWithSeconds: null,
      timeout: null,
      smsButtonText: 'Подписать по СМС',
      smsButtonBg: 'auto',
      smsStage: 1,
    }
  },
  computed: {
    smsDisabled() {

      let docs = [];

      // console.log('sms disabled is work')
      if (this.$store.getters[`newApplication/sms_documents`].length) {
        docs = this.$store.getters[`newApplication/sms_documents`].filter(item => item.sms_status === 'completed')

      }
      if (this.$store.getters.fields) {

        let requiredFirstStepFields = this.$store.getters.fields.filter(field => field.approval_step === 1 && field.required === 1);
        // console.log(requiredFirstStepFields)

        let fieldsStatus = this.getRequiredFieldsStatus(requiredFirstStepFields)

        return !(this.$store.getters['newApplication/leasing_application_status_id'] &&
                fieldsStatus) ||

            (this.$store.getters['newApplication/leasing_application_status_id'] &&
                fieldsStatus &&
                !!docs.length)
      }

      return true;

      // let requiredFirstStepFields = this.$store.getters.fields.filter(field => field.approval_step === 1);
      // console.log(requiredFirstStepFields)
      //
      // let fieldsStatus = this.getRequiredFieldsStatus(requiredFirstStepFields)
      //
      // return !(this.$store.getters['newApplication/leasing_application_status_id'] &&
      //         fieldsStatus) ||
      //
      //     (this.$store.getters['newApplication/leasing_application_status_id'] &&
      //         fieldsStatus &&
      //         !!docs.length)

    },
    getTookTheJobDate() {
      return this.convertDate(this.$store.getters[`newApplication/who_took_the_job_date`])
    },
    getTookDocumentsDate() {
      return this.convertDate(this.$store.getters[`newApplication/who_took_documents_date`])
    },
    maxPrice() {
      if (this.$store.getters['newApplication/get_leasing_offer_id'] && this.partnerOffers.length) {
        let partnerOffer = this.partnerOffers.find(item => item.id === this.$store.getters['newApplication/get_leasing_offer_id']);
        if (partnerOffer) {
          return parseFloat(partnerOffer.max_cost_with_vat)
        }
      }
      return 30000;
    },

    offer() {
      return offer
    },
    fullName() {
      return this.$store.getters["newApplication/first_name"] + ' ' +
          this.$store.getters["newApplication/last_name"] + ' ' +
          this.$store.getters["newApplication/patronymic"]
    },
    currentDate() {
      return this.convertDate(new Date)
    },
    disabledChart() {
      return [
            this.$constants.statuses.documentControl,
            this.$constants.statuses.appInDeal,
            this.$constants.statuses.paidUp,
            this.$constants.statuses.appCanceled,
            this.$constants.statuses.appRejection,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) ||
          !this.$store.getters['newApplication/leasing_application_id']
    },
    saveIsDisabled() {
      return [
            this.$constants.statuses.documentControl,
            this.$constants.statuses.appInDeal,
            this.$constants.statuses.paidUp,
            this.$constants.statuses.appCanceled,
            this.$constants.statuses.appRejection,
          ].includes(this.$store.getters['newApplication/leasing_application_status_id']) &&
          this.$store.getters.user.role.name !== 'admin'
    },

    isDisabledWhoTookDocuments() {
      return !!this.$store.getters['newApplication/who_took_documents']
    },
    isShowWhoTookDocuments() {

      if (!!this.$store.getters['newApplication/who_took_documents']) {
        return true;
      }

      if (this.$store.getters['newApplication/leasing_application_status_id'] && this.$store.getters['newApplication/leasing_application_status_id'] === this.$constants.statuses.documentControl) {
        return true
      }

      return false;
    }
  },
  watch: {},
  mounted() {

    //this.$store.commit('newApplication/updateState', app)
    this.getFields(this.$constants.navigations.newApplication.sub.chartFormation)
    this.getLeasingOffersByPartner()

    if (localStorage.getItem('rightClickApp')) {
      this.$store.commit('newApplication/updateState', JSON.parse(localStorage.getItem('rightClickApp')))
      localStorage.removeItem('rightClickApp');
    }

    if (this.$store.getters[`newApplication/get_sms_check`]) {
      this.smsPopupVisible = true;
    }

    this.getSmsButtonText()
    this.getAllOldClientApps()

  },
  methods: {
    getFullSubjectsPrice(subjects) {
      let price = 0;
      subjects.forEach(item => {
        price += +item.price
      })
      return (price).toFixed(2);
    },
    openOldApp(id) {
      console.log(id)
      const app = this.oldApps.find(item => item.id === id);
      const routeData = this.$router.resolve({name: 'NewApplicationsView'})
      localStorage.removeItem('rightClickApp');
      localStorage.setItem('rightClickApp', JSON.stringify(app));
      window.open(routeData.href, '_blank');
    },
    convertOldAppsDate(data) {
      if (!data) {
        return {
          date: null,
          time: null
        };
      }
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
    getAllOldClientApps() {

      if (!this.$store.getters["newApplication/leasing_application_id"] || this.$store.getters["newApplication/leasing_application_status_id"] < this.$constants.statuses.appInProcessing) {
        return;
      }

      this.$api.leasingApplication.getAllOldClientApps({
        leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
        user_id: this.$store.getters.user.id
      })
          .then(data => {
            this.oldApps = data;
          })
    },
    getRequiredFieldsStatus(fields) {
      if (!fields.length) {
        return true
      }

      let status = true;

      for (let i = 0; i < fields.length; i++) {
        if (!this.$store.getters[`newApplication/${fields[i].front_app_key}`]) {
          status = false;
          break;
        }
      }

      return status;
    },
    getSmsButtonText() {
      if (this.$store.getters[`newApplication/sms_documents`].length) {
        const doc = this.$store.getters[`newApplication/sms_documents`].find(item => item.sms_status === 'completed')
        this.smsButtonBg = '#d6ffd9'
        return this.smsButtonText = !doc ? 'Подписать по СМС' : 'Согласия подписаны'
      }
      this.smsButtonBg = 'auto'
      return this.smsButtonText = 'Подписать по СМС';
    },
    ...mapActions({saveData: 'newApplication/saveData'}),

    setWhoTookTheJob() {
      this.$api.administration.setWhoTookTheJob({
        leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
        user_id: this.$store.getters.user.id
      })
          .then(data => {
            if (data.errors) {
              return this.$notify.error({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Ошибка',
                message: 'Ошибка. Не получилось взять в работу',
              });
            }

            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Вы взяли заявку в работу',
            });

            this.$store.commit('newApplication/set_who_took_the_job', data.user)
            this.$store.commit('newApplication/set_who_took_the_job_date', data.date)

          })
    },
    setWhoTookDocuments() {
      this.$api.administration.setWhoTookDocuments({
        leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
        user_id: this.$store.getters.user.id
      })
          .then(data => {
            if (data.errors) {
              return this.$notify.error({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Ошибка',
                message: 'Ошибка. Не получилось взять в работу документы',
              });
            }

            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Вы взяли заявку в работу',
            });

            this.$store.commit('newApplication/set_who_took_documents', data.user)
            this.$store.commit('newApplication/set_who_took_documents_date', data.date)

          })
    },
    setSelectedOffer(id, reload = true) {
      this.selectedOffer = this.partnerOffers.find(item => item.id === id)
      if (!this.selectedOffer) {
        return this.$notify.error({
          duration: this.$constants.global.notifyDurationSuccess,
          title: 'Ошибка',
          message: 'Лизинговое предложение не найдено. Перезагрузите страницу, или обратитесь к администратору.',
        });
      }

      this.$store.commit(`newApplication/set_leasing_offer_id`, this.selectedOffer.id)

      this.$store.commit(`newApplication/set_leasing_offer_type_id`, this.selectedOffer.offer_type.id)

      this.$store.commit(`newApplication/set_down_payment_price`, this.selectedOffer.down_payment ? +this.selectedOffer.down_payment : 0)
      this.selectedOfferTerms.data = this.selectedOffer.available_terms.sort((a, b) => +a.name > +b.name ? 1 : -1)
      this.selectedOfferTerms.name_for_web = "Срок лизинга (месяцев)"
      this.selectedOfferTerms.name = "leasing_term"
      this.selectedOfferTerms.type = "radio"

      if (this.renderObject) {
        this.renderObject['leasing_term'].field = this.selectedOfferTerms
      }
      if (reload) {
        this.$store.commit(`newApplication/set_leasing_term`, this.selectedOffer.available_terms[0].id)
      }

    },
    setDefaultSelectedOffer() {
      let defaultOffer = this.partnerOffers.find(item => item.name.toLowerCase() === 'лизинг стандарт')
      if (defaultOffer) {
        this.setSelectedOffer(defaultOffer.id, true)
      } else {
        this.setSelectedOffer(this.partnerOffers[0].id, true)
      }

    },
    getLeasingOffersByPartner() {
      this.$api.leasingApplication.getLeasingOffersByUser()
          .then(data => {
            this.partnerOffers = data
            if (this.$store.getters['newApplication/get_leasing_offer_id']) {
              this.setSelectedOffer(this.$store.getters['newApplication/get_leasing_offer_id'], false)
            } else {
              this.setDefaultSelectedOffer()
            }
          })
    },
    showSMSPopup() {
      clearTimeout(this.timeout);
      if (!this.$store.getters[`newApplication/leasing_application_id`]) {
        return this.$notify.warning({
          duration: 2500,
          title: 'Ошибка',
          message: 'Перед отправкой документов на подписание сохраните заявку.',
        });
      }
      this.smsPopupVisible = true;
      this.sendDataForFirstStageSmsCode()
    },
    sendDataForFirstStageSmsCode() {
      this.$api.leasingApplication.sendDataForFirstStageSmsCode({
        'leasing_application_id': this.$store.getters[`newApplication/leasing_application_id`]
      })
          .then((data) => {
            if (!data.errors) {

              this.countDown = 1200;
              this.countDownTimer();

              return this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Код отправлен на телефон клиента. Ожидайте обратной связи',
              });
            }
          })
    },
    sendFirstStageSmsCodeToCheck() {
      this.$api.leasingApplication.sendFirstStageSmsCodeToCheck({
        'leasing_application_id': this.$store.getters[`newApplication/leasing_application_id`],
        'sms_code': this.firstStageSmsCode
      })
          .then((data) => {
            if (!data.errors) {
              clearTimeout(this.timeout);
              this.countDown = 1200;
              this.countDownTimer();

              this.firstStageSmsCode = null;
              this.smsStage = 2;

              return this.$notify.success({
                duration: 5000,
                title: 'Успешно',
                message: 'Код отправлен на телефон клиента. Ожидайте обратной связи',
              });
            }
          })
    },
    sendSecondStageSmsCodeToCheck() {
      this.$api.leasingApplication.sendSecondStageSmsCodeToCheck({
        'leasing_application_id': this.$store.getters[`newApplication/leasing_application_id`],
        'sms_code': this.secondStageSmsCode
      })
          .then((data) => {
            if (!data.errors) {
              this.smsPopupVisible = false;
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Заявка сохранена. Документы сформированы',
              });

              this.$store.commit('newApplication/set_sms_documents', data)
              this.smsStage = 1;
              this.getSmsButtonText()
              return
            }

            return this.$notify.error({
              duration: this.$constants.global.notifyDurationError,
              title: 'Ошибка',
              message: 'Произошла ошибка. Обратитесь к администратору',
            });
          })
    },

    prepareDocumentsForClient() {
      this.$api.leasingApplication.prepareDocumentsForClient({
        'leasing_application_id': this.$store.getters[`newApplication/leasing_application_id`],
      })
          .then((data) => {
            //TODO: придумать, как реализовать этапы получения смс.
            // Первый этап - только код,
            // 2-й этап - код в документы.


            // Object.assign(document.createElement('a'), {
            //   target: '_blank',
            //   rel: 'noopener noreferrer',
            //   href: data,
            // }).click();
          })
    },
    setStatus(status) {
      this.$api.leasingApplication.setStatus(
          {
            id: this.$store.state.newApplication.leasing_application_id,
            status_id: status,
            reason_for_rejection_ids: this.$store.getters['newApplication/reason_for_rejection_types']
          }
      ).then(data => {

        if (!data.errors) {
          this.$store.commit('newApplication/set_leasing_application_status_id', status)
          this.$store.commit('newApplication/set_sms_check', 0)
          this.smsPopupVisible = false;
          return this.$notify.success({
            duration: this.$constants.global.notifyDurationError,
            title: 'Успешно',
            message: 'Заявка сохранена',
          });
        }
      })
    },
    sendSmsCodeToCheck() {
      this.$api.leasingApplication.checkSmsCode({
        'leasing_application_id': this.$store.getters[`newApplication/leasing_application_id`],
        'sms_code': this.smsCode
      })
          .then((data) => {
            if (!data.errors) {
              this.smsPopupVisible = false;
              return this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Заявка сохранена. Документы сформированы',
              });
            }

            this.$store.commit('newApplication/set_sms_documents', data)

            this.getSmsButtonText()

            return this.$notify.success({
              duration: this.$constants.global.notifyDurationError,
              title: 'Успешно',
              message: 'Произошла ошибка. Обратитесь к администратору',
            });
          })
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.timeout = setTimeout(() => {
          this.countDown -= 1

          let minutes = parseInt(this.countDown / 60, 10);
          let seconds = parseInt(this.countDown % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.countDownMinutesWithSeconds = minutes + ":" + seconds

          this.countDownTimer()
        }, 1000)
      }
    },
    setAssentDate() {
      this.$api.leasingApplication.setAssentDate({
            leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
          }
      ).then((data) => {
        this.$store.commit('newApplication/set_assent_date', data)
        return this.$notify.success({
          duration: 2500,
          title: 'Успешно',
          message: 'Согласия подписаны',
        });
      }).catch(error => {
        console.error(error);
      })
          .finally(() => {
            this.wait = false;
          })
    },
    getDocumentObtainingConsent() {
      if (!this.$store.getters["newApplication/leasing_application_id"]) {
        return this.$notify.error({
          duration: 2500,
          title: 'Ошибка',
          message: 'Печать документа возможна только после сохранения заявки.',
        });
      }

      this.wait = true;
      this.$api.leasingApplication.renderDocs({
            leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
            code: '000'
          },
          {responseType: 'blob'}
      ).then(({data, headers}) => {

        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
        window.open(url, '_blank')
        console.log(navigator)
        //printJS({printable:url, type:'pdf'})
      }).catch(error => {
        console.error(error);
      })
          .finally(() => {
            this.wait = false;
          })
    },

    getDocumentTemplatesForPrint() {
      if (!this.$store.state.newApplication.leasing_application_id) {
        return this.$notify.error({
          duration: 2500,
          title: 'Ошибка',
          message: 'Печать документов возможна только после сохранения заявки.',
        });
      }
      this.$api.field.getDocumentTemplatesForPrint({
        leasing_application_id: this.$store.state.newApplication.leasing_application_id,
      })
          .then(data => {
            this.documentTemplates = data;
            let interestDocument = this.documentTemplates.filter(item => item.code === '0000')[0]
            var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(interestDocument.text);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
          })


    },
    saveChartData() {
      this.saveData()
    },
    changeCountLeasingSubject(type) {
      if (type === 'add') {
        if (this.$store.state.newApplication.count_visible_subjects < 5) {
          this.$store.commit('newApplication/set_count_visible_subjects', ++this.$store.state.newApplication.count_visible_subjects)
          this.$store.commit(`newApplication/set_leasing_subject_price${this.$store.state.newApplication.count_visible_subjects}`, 0)
          return
        }
      }
      if (type === 'delete') {
        if (this.$store.state.newApplication.count_visible_subjects > 1) {
          this.$store.commit(`newApplication/set_leasing_subject${this.$store.state.newApplication.count_visible_subjects}`, '')
          this.$store.commit(`newApplication/set_leasing_subject_price${this.$store.state.newApplication.count_visible_subjects}`, '')
          this.$store.commit('newApplication/set_count_visible_subjects', --this.$store.state.newApplication.count_visible_subjects)

        }
      }
    },
    applicationApproval() {
      this.loadData = true
      setTimeout(() => {
        this.loadData = false
        this.savedData = true
        this.approved = true
      }, [2000])
    },
    goNextStep() {
      this.propsChangeMenuState(this.$constants.navigations.newApplication.sub.customerAnalysis)
    },
    getFields(id) {
      this.$api.fields.getFields({id: id}).then((data) => {
        this.fieldsData = data
        this.formingObjectForRendering(data)

      })
    },
    formingObjectForRendering(data) {
      let object = {}
      data.forEach(el => {
        if (el.field['name'] in object) {
          object[el.field['name']] = el
        } else {
          object[el.field['name']] = [el][0]
        }
      })
      this.renderObject = object
    },
    openChart() {
      this.saveData(this.getPaymentScheduleOnlyData);
    },
    forPrint() {
      this.saveData(this.getPaymentSchedule);
    },
    getPaymentScheduleOnlyData() {
      this.chartLoading = true;
      this.chartDialogIsOpen = true;
      this.$api.leasingApplication.getPaymentSchedule({
            leasing_application_id: this.$store.getters["newApplication/leasing_application_id"],
            only_data: 1
          }
      ).then((data) => {
        this.chartData = data;
      })
          .finally(() => {
            this.chartLoading = false;
          })
    },
    getPaymentSchedule() {

      this.$api.leasingApplication.getPaymentSchedule({
            leasing_application_id: this.$store.getters["newApplication/leasing_application_id"]
          },
          {responseType: 'blob'}
      ).then(({data, headers}) => {
        const url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
        window.open(url, '_blank')

      })
          .finally(() => {
            this.chartLoading = false;
          })
    },
    convertDate(data) {
      return new Date(data).toLocaleDateString('ru-Ru',
          {
            'month': "2-digit",
            'day': "2-digit",
            'year': 'numeric'
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.at_work {
  margin-bottom: 1rem;
  text-align: right;

  .my-button {
    margin-bottom: 0.5rem;
  }
}

.old-apps {
  max-height: 200px;
  overflow-y: auto;

  ::v-deep .el-table {
    &__header {
      .cell {
        font-size: 8px;
        color: #121212;
      }
    }

    &__body {
      .cell {
        font-size: 10px;
        color: #121212;

        a {
          font-size: 10px;
        }
      }
    }

    .el-button {
      padding: 2px 6px;
    }

    &__cell {
      padding: 2px 0;
    }
  }
}

.chart {
  .leasing-application-status {
    margin-bottom: 10px;

    span {
      font-weight: bold;

      &:last-child {
        color: #ef3123;
      }
    }
  }

  .disabled {
    opacity: 0.5 !important;
    pointer-events: none;
    transition: .3s;
  }

  .subject-group {
    position: relative;

    i {
      position: absolute;
      top: 25px;
      right: 20%;
      color: #ef3123;
      font-size: 36px;
      cursor: pointer;

      &.x-minus {
        right: 15%;
        @media (max-width: 850px) {
          top: -14px;
          right: 5%;
        }
      }

      @media (max-width: 850px) {
        top: -14px;
        right: -3%;
      }
    }
  }

  .price-block {
    margin-bottom: 15px;
  }

  .prepay-block {
    margin-bottom: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    @media(max-width: 670px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  .client-info {
    margin-bottom: 15px;

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      @media(max-width: 850px) {
        gap: 0;
        grid-template-columns: 1fr;
      }
    }
  }

  .sms {
    &:disabled {
      color: #1A2122;
    }
  }

  .btn-block {
    div {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      @media(max-width: 850px) {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
}

.btn-block-2 {
  display: grid;

  button {
    @media(max-width: 850px) {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    @media(max-width: 850px) {
      display: block;
      width: 100%;
    }

    div {
      display: grid;
    }

    button {
      min-width: 240px;
      @media(max-width: 850px) {
        width: 100% !important;
        margin-bottom: 20px;
      }

      &.btn-accept {
        @media(max-width: 850px) {
          width: 100%;
          order: -1;
          margin-bottom: 5px;
        }

        i {
          font-size: 20px;
          line-height: 1;
        }
      }
    }

    p {
      padding: 5px 5px;
      text-align: start;
      max-width: 240px;
      font-size: 9px !important;
      color: #555;
      @media(max-width: 850px) {
        max-width: unset;
        width: 100%;

      }
    }
  }

  .next {
    margin-left: auto;
  }

}

::v-deep .el-dialog__wrapper.sms {
  .el-dialog {
    background: rgba(255, 255, 255, 0.8);

    &__body {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;

      .first-stage-sms-content,
      .second-stage-sms-content {
        display: none;
        opacity: 0;
        position: absolute;
        width: 420px;
        height: 130px;
        top: calc(50% - 65px);
        left: calc(50% - 210px);
        transition: all 300ms linear;

        &.active {
          display: block;
          opacity: 1;
        }
      }
    }
  }

}


</style>